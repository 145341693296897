import OwlCarousel from 'react-owl-carousel3';
import { Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import ImgmStar from '../../assets/img/star.png';
import ImgmFavorite from '../../assets/img/favorite.png';

import apiPath from "../../constants/apiPath";
import Http from "../../services/Http";
import toast, { Toaster } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Currency from './Currency';
import ImgNew from '../../assets/img/new.png';
import ImgSale from '../../assets/img/sale.png';
import LocalizedStrings from 'react-localization';
import localizations from "../../localization";
let strings = new LocalizedStrings(localizations);

const ProductSearch = (Props) => {


  const [comman, setComman] = useState({
    setfavorite: ImgmFavorite,
    type: 'add'
  });


  const [currentLang, setCurrentLang] = useState(
    localStorage.getItem("current_lang")
      ? localStorage.getItem("current_lang")
      : "en"
  );
  strings.setLanguage(currentLang);


  const handleFavourite = async (e, id, type, index) => {

    let path = apiPath.AddRemoveWishlist;
    var data = new FormData()
    data.append('product_id', id);
    data.append('type', type);
    let options = {
      method: 'POST',
      data: data
    }

    let result = await Http(path, options);
    if (result.status === 200) {
      if (result.data.status) {
        //  var is_wish = product.data[index].is_wishlist;
        var is_wish_val = 0;

        if (type == 'add') {
          //  setProduct({ ...product, [product.data[index].is_wishlist]: 0 });
        }
        else {
          // setProduct({ ...product, [product.data[index].is_wishlist]: 1 });
        }

        toast.success(result.data.message);
      } else {
        toast.error(result.data.message);
      }
    } else {
      if(result.statusText != null) {
        toast.error(result.statusText);
      }
    }


  }

  return (
    <>
      <OwlCarousel className="search-product-carousel owl-carousel owl-theme" {...Props.options} >
        {Props.data.map((item, index) =>
          item.banner_type == 'video' ?
            <video width="100%" height={487} controls>
              <source src={item.file} type="video/mp4" />
              <source src={item.file} type="video/ogg" />
              <source src={item.file} type="video/webm" />
            </video> : <div className="item">
              <div className="arrival-prd">
                {item.is_new == 1 ?
                    <div className="new_badge">
                        <img src={ImgNew} alt="New Badge" />
                    </div> : ''}
                {item.is_sale == 1 ?
                    <div className="sale_badge">
                        <img src={ImgSale} alt="Sale Badge" />
                    </div> : ''}
                <div className="arrival-prd-img">
                  <Link to={'/product-detail/' + item.slug} target="_blank">
                    <img src={item.image} alt="Arrival Badge" />
                  </Link>
                </div>
                <div className="arrival-cont arrival-search-cont">
                  <div className="arrival-title">
                    <h3>{item.name}</h3>
                    <Link to={item.brands ? "/product/brand/" + item.brands.slug : '#'} style={{ textDecoration: 'none'}}>
                      <p>{item.brands ? item.brands.name : ''}</p>
                    </Link>
                  </div>
                  <div className="arrival-price">
                      <p>
                        <Currency data={item.discount_price != 0 ? item.discount_price : item.original_price} />
                      </p>
                      {parseInt(item.discount_price) == 0 ? '' : <p className="discount-price"><span><Currency data={item.original_price} /> </span> ({(Math.round(((item.original_price - item.discount_price) * 100) / item.original_price).toFixed(2))}% {strings.off})</p>}

                    </div>
                </div>

              </div>
            </div>
        )}
      </OwlCarousel>

    </>

  )
}
export default ProductSearch;