import React, { useState, useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel3';
import Accordion from 'react-bootstrap/Accordion';

import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'

import { useParams } from 'react-router-dom';
import { useStateWithCallbackLazy } from 'use-state-with-callback';


import Header from '../layout/Header';
import Footer from '../layout/Footer';
import apiPath from "../constants/apiPath";
import Http from "../services/Http";
import toast, { Toaster } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import ImgBannerPrd from '../assets/img/banner-prd.png';
import ImgMBannerPrd from '../assets/img/m-banner-prd.png';
import newsImg from '../assets/img/news-img.png';
import ImgSortIc from '../assets/img/sort_ic.png';
import ImgFilterIc from '../assets/img/filter_ic.png';



const NewsTickerDetail = (Props) => {

   const params = useParams()
   const [newsTickerDetails, setNewsTickerDetails] = useState([]);
   const getNewsTickerDetails = async (id) => {
      let path = apiPath.newsTickerDetails + '/' + id;
      var data = new FormData();
      let options = {
         method: 'GET',
      }
      let result = await Http(path, options);
      if (result.status == 200) {
         if (result.data.status) {
            await setNewsTickerDetails({ data: result.data.data })
         }
         else {
            toast.error(result.data.message);
         }
      }

   }
   useEffect(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      getNewsTickerDetails(params.id);
   }, [params]);
   return (
      <>
         <Header />
         <section className='news-ticker-detail-page space-cls'>
            <div className='container'>
               <div className='news_box'>
                  <div className='news_img'>
                     <img alt='New Ticket' src={newsTickerDetails.data ? newsTickerDetails.data.image : ''} />
                  </div>
                  <div className='news_content'>
                     {/* <h3>Lorem Ipsum is simply dummy text of the printing </h3> */}
                     <p dangerouslySetInnerHTML={{ __html: newsTickerDetails.data ? newsTickerDetails.data.description : '' }}></p>
                  </div>
               </div>
            </div>
         </section>
         <Footer />
      </>
   )
}

export default NewsTickerDetail;