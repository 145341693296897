import React, { useState, useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel3';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import apiPath from "../constants/apiPath";
import Http from "../services/Http";
import { Link } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import Banner from '../view/Component/Banner';
import TopTranding from '../view/Component/TopTranding';
import Flower from '../view/Component/Flower';
import Product from '../view/Component/Product';
import HomeFragrance from '../view/Component/HomeFragrance';
import { internalIpV6, internalIpV4 } from "../services/getIp";
import { userLogin } from "../actions/index";
import { connect } from "react-redux";
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import Modal from 'react-bootstrap/Modal';
import Imgcross from '../assets/img/cross.png';
import { Button, Form } from 'react-bootstrap';
import { SelectGroup, ValidationForm } from "react-bootstrap4-form-validation";
import ImgComboPack from '../assets/img/combo-pack.png';
import ImgDiscount1 from '../assets/img/discount-1.png';
import ImgDiscount2 from '../assets/img/discount-2.png';
import ImgDiscount3 from '../assets/img/discount-3.png';
import ImgGift1 from '../assets/img/gift-1.png';
import ImgGift2 from '../assets/img/gift-2.png';
import ImgfragrancesBanner from '../assets/img/fragrances-banner.png';
import ImgmfragrancesBanner from '../assets/img/m-fragrances-banner.png';
import ImgBestSeller from '../assets/img/best-seller.png';
import ImgmBestSeller from '../assets/img/m-best-seller.png';
import ImgmSale1 from '../assets/img/sale-1.png';
import ImgmSale2 from '../assets/img/sale-2.png';
import ImgmSale3 from '../assets/img/sale-3.png';
import ImgmSale4 from '../assets/img/sale-4.png';
import ImgTryBuyBanner from '../assets/img/try-buy-banner.png';
import ImgmTryBuyBanner from '../assets/img/m-try-buy-banner.png';
import logo from "../assets/img/logo.png";
import LocalizedStrings from 'react-localization';
import localization from '../localization';
import axios from 'axios';
import Currency from '../view/Component/Currency';

let strings = new LocalizedStrings(localization);

const mapStateToProps = (state) => {
	return { user: state.user };
};

const mapDispatchToProps = (dispatch) => {
	return {
		userLogin: (user) => dispatch(userLogin(user)),
	};
};

const options = {
	rtl: true,
	margin: 0,
	nav: false,
	dots: true,
	loop: true,
	autoplay: true,
	autoplayTimeout: 3000,
	autoplayHoverPause: true,
	responsive: {
		0: {
			items: 1,
			margin: 7.5
		},
		420: {
			items: 1
		},
		577: {
			items: 1
		},
		992: {
			items: 1
		}
	}
};

const categoryOptions = {
	margin: 15,
	nav: true,
	dots: false,
	loop: true,
	center: false,
	responsive: {
		0: {
			items: 3,
			margin: 7.5
		},
		577: {
			items: 4
		},
		992: {
			items: 9
		},
		1199: {
			items: 10
		},
		1351: {
			items: 13
		}
	}
};

const categoryInnerOptions = {
	margin: 15,
	nav: true,
	dots: false,
	loop: true,
	responsive: {
		0: {
			items: 2,
			margin: 7.5
		},
		577: {
			items: 3
		},
		992: {
			items: 5
		},
		1366: {
			items: 7
		}
	}
};

const brandOption = {
	margin: 15,
	nav: true,
	dots: false,
	loop: true,
	responsive: {
		0: {
			items: 2,
			margin: 7.5
		},
		577: {
			items: 3
		},
		992: {
			items: 4
		},
		1366: {
			items: 5
		}
	}
};

const trandingOption = {
	margin: 15,
	nav: true,
	dots: false,
	loop: true,
	responsive: {
		0: {
			items: 2,
			margin: 7.5
		},
		577: {
			items: 3
		},
		992: {
			items: 6
		}
	}
};

const comboOption = {
	margin: 15,
	nav: true,
	dots: false,
	loop: true,
	responsive: {
		0: {
			items: 1,
			margin: 7.5
		},
		420: {
			items: 2
		},
		577: {
			items: 2
		},
		992: {
			items: 2
		},
		1366: {
			items: 3
		}
	}
};

const DiscountOption = {
	margin: 15,
	nav: true,
	dots: false,
	loop: false,
	responsive: {
		0: {
			items: 1,
			margin: 7.5
		},
		420: {
			items: 2
		},
		577: {
			items: 2
		},
		992: {
			items: 3
		},
		1366: {
			items: 4
		}
	}
};

const flowersOption = {
	margin: 15,
	nav: true,
	dots: false,
	loop: true,
	autoplay: true,
	autoplayTimeout: 3000,
	autoplayHoverPause: true,
	responsive: {
		0: {
			items: 1,
			margin: 7.5
		},
		577: {
			items: 1
		},
		992: {
			items: 2
		},
		1466: {
			items: 2
		}
	}
};

const giftOption = {
	margin: 15,
	nav: true,
	dots: false,
	loop: true,
	responsive: {
		0: {
			items: 1,
			margin: 7.5
		},
		577: {
			items: 3
		},
		992: {
			items: 4
		},
		1366: {
			items: 5
		}
	}
};

const newAriveOption = {
	margin: 15,
	nav: true,
	dots: false,
	loop: false,
	rewind: true,
	responsive: {
		0: {
			items: 2,
			margin: 7.5
		},
		420: {
			items: 2.5,
			margin: 7.5
		},
		577: {
			items: 3,
			margin: 7.5
		},
		767.98: {
			items: 4
		},
		992: {
			items: 5
		},
		1366: {
			items: 6
		},
		1466: {
			items: 7
		}
	}
};

const essentialsOption = {
	margin: 15,
	nav: true,
	dots: false,
	loop: true,
	responsive: {
		0: {
			items: 2,
			margin: 7.5
		},
		577: {
			items: 3,
			margin: 7.5
		},
		767.98: {
			items: 4
		},
		992: {
			items: 6
		},
		1366: {
			items: 6
		},
		1466: {
			items: 7
		}
	}
};

const accessoriesOption = {
	margin: 15,
	nav: true,
	dots: false,
	loop: true,
	responsive: {
		0: {
			items: 1,
			margin: 7.5
		},
		577: {
			items: 3
		},
		992: {
			items: 4
		},
		1366: {
			items: 5
		}
	}
};

const Home = (Props) => {
	const formRef = React.createRef();
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [modalIsNotifyReviewOpen, setModalIsNotifyReviewOpen] = useState(false);

	const setModalIsOpenToTrue = (item, attributes) => {
		setComman({ ...comman, product_id: item.id, attributes: attributes, product: item });
		setModalIsOpen(true);
	};

	const setModalIsOpenToFalse = () => {
		setModalIsOpen(false);
	};

	const setModalIsNotifyReviewOpenToFalse = () => {
		setModalIsNotifyReviewOpen(false);
	};

	const showAddtoCart = async (e, item, attributes) => {
		e.preventDefault();
		if (attributes.length > 0) {
			setModalIsOpenToTrue(item, attributes);
		} else {
			setComman({ ...comman, product_id: item.id, attribute_value_id: null, product: item });
			addtoCart(item.id, null);
		}
	};

	const handleAttributeChange = async (productId, attributeValueId) => {
		try {
			let path = apiPath.ProductAvailability + '/' + productId;
			var data = new FormData();
			data.append('attribute_value_id', attributeValueId);
			let options = {
				method: 'POST',
				data: data
			}
			let result = await Http(path, options);
			if (result.status == 200) {
				if (result.data.status) {
					setComman({
						...comman,
						product: {
						  ...comman.product,
						  discount_price: result.data.data.product.discount_price,
						  original_price: result.data.data.product.original_price,
						},
					  });
				}
				else {
					toast.error(result.data.message);
				}
			}
		} catch (error) {
		  console.error('Error updating price:', error);
		  toast.error('An error occurred while updating price');
		}
	};

	const addtoCart = async (id, type) => {
		let path = apiPath.AddtoCart;
		var data = new FormData();
		data.append('product_id', id);
		data.append('qty', 1);
		if (type == null && comman.attribute_value_id != null) {
			data.append('product_attribute_values_id', comman.attribute_value_id);
		} else if (type == 'form') {
			const attributeValueId = document.querySelector('input[name="attribute_value_id"]:checked');
            if (attributeValueId != null && attributeValueId != "") {
                data.append('product_attribute_values_id', attributeValueId.value);
            }
		}

		let options = {
			method: 'POST',
			data: data
		}

		let result = await Http(path, options);
		if (result.status == 200) {
			if (result.data.status) {
				setComman({ ...comman, addCart: true });
				toast.success(result.data.message);
			} else {
				toast.error(result.data.message);
			}
			setModalIsOpenToFalse();
		}
	};

	const [comman, setComman] = useStateWithCallbackLazy({
		product_id: 0,
		attributes: [],
		attribute_value_id: null,
		addCart: false,
		product: null
	});

	const [dashboard, setDashboard] = useState([]);
	const [gtIp, setIP] = useState();

	const [currentCurrency, setCurrentCurrency] = useState(
		localStorage.getItem("current_currency")
			? JSON.parse(localStorage.getItem("current_currency"))
			: { currency: 1, country_code: "KWD" }
	);

	const getIP = () => {
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: 'https://api.ipify.org/?format=json',
			headers: {}
		};

		axios.request(config)
			.then((response) => {
				setIP((response.data.ip));
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const guestUser = async () => {
		if (typeof Props.user === 'object' && Object.keys(Props.user).length < 1) {
			let path = apiPath.guestUser;
			var data = new FormData();
			data.append("country_id", "91");
			data.append("language", "en");
			data.append("device_type", "Website");
			data.append("device_token", await internalIpV4());
			let options = {
				method: "POST",
				data: data,
			};

			let result = await Http(path, options);
			if (result.status === 200) {
				if (result.data) {
					Props.userLogin(result.data.data);
					getDashboard();
				} else {
					toast.error(result.data.message);
				}
			} else {
				if (result.statusText != null) {
					toast.error(result.statusText);
				}
			}
		} else {
			getDashboard();
		}
	};

	const getDashboard = async () => {
		let path = apiPath.dashboard + '?type=web';
		let options = {
			method: 'GET',
		};
		let result = await Http(path, options);
		if (result.status == 200) {
			setDashboard({ data: result.data.data });
			if (result.data.data.is_notify_review == 1) {
				setModalIsNotifyReviewOpen(true);
			}
		} else {
			if (result.statusText != null) {
				toast.error(result.statusText);
			}
		}
	};

	const [currentLang, setCurrentLang] = useState(
		localStorage.getItem("current_lang")
			? localStorage.getItem("current_lang")
			: "en"
	);
	strings.setLanguage(currentLang);

	const handleFavourite = async (e, id, type, index) => {
		let path = apiPath.AddRemoveWishlist;
		var data = new FormData();
		data.append('product_id', id);
		data.append('type', type);
		let options = {
			method: 'POST',
			data: data
		};

		let result = await Http(path, options);
		if (result.status === 200) {
			if (result.data.status) {
				getDashboard();
				toast.success(result.data.message);
			} else {
				toast.error(result.data.message);
			}
		} else {
			if (result.statusText != null) {
				toast.error(result.statusText);
			}
		}
	};

	useEffect(() => {
		guestUser();
		getIP();
	}, []);

	return (
		<>
			<Header data={comman.addCart} />
			<main>
				<section className="space-cls cart-page mobile-button">
					<div className="container">
						<div className="cart_row">
							<div className="cart_left">
								<div className="cart_title try_sample_title">
									<Link to="/try-samples" className="nav-link">
										<span className="try_samples_badge">
											{strings.trySamples}
										</span>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</section>

				{dashboard.data ? <>
					{dashboard.data ? dashboard.data.banners.length > 0 ?
						<section className="banner-sec">{dashboard.data ? <Banner data={dashboard.data.banners} options={options} type="Banner" /> : <Skeleton height={487} count={1} />}</section> : null : null}
					{dashboard.data ? dashboard.data.sale_banner.length > 0 ?
						<section className="space-cls offer-banenr-sec"><div className="container">{dashboard.data ? <Banner data={dashboard.data.sale_banner} type="sale" options={options} /> : <Skeleton height={100} count={3} />}</div></section> : null : null}
					{dashboard.data ? dashboard.data.brands.length > 0 ?
						<section className="space-cls category-sec"><div className="container">{dashboard.data ? <Banner data={dashboard.data.brands} type="brand" options={categoryOptions} /> : null}</div></section> : null : null}
					{/* {dashboard.data ? dashboard.data.top_brand.length > 0 ?
						<section className="space-cls brand-sec">
							<div className="container">
								<div className="title-main">
									<h2 className="heading-type1">{strings.top_brands}</h2>
								</div>
								<div className="brand-listing">{dashboard.data ? <Banner data={dashboard.data.top_brand} type="top_brand" options={brandOption} /> : null}</div>
							</div>
						</section>
						: null : null} */}
					{dashboard.data ? dashboard.data.trending_product.length > 0 ?
						<section className="space-cls trending-product">
							<div className="container">
								<div className="bg-1">
									<div className="title-center">
										<div className="title-bg">
											<h3 className="heading-type2">{strings.top_tranding}</h3>
										</div>
									</div>
									{dashboard.data ? <TopTranding data={dashboard.data.trending_product} options={trandingOption} /> : null}
								</div>
							</div>
						</section>
						: null : null}
					<section className="space-cls price-sec">
						<div className="container">
							<div className="row">
								<div className="col-3 col-sm-2 col-md-2 col-lg-2 col-xl-1">
									<Link to="/product/price/10" className="price_bg price_bg1">
										<p>
											<span className="under">{strings.under}</span>
											<span className="price-dtl">10</span>
											<span className="price-desc">{currentCurrency.country_code}</span>
										</p>
									</Link>
								</div>
								<div className="col-3 col-sm-2 col-md-2 col-lg-2 col-xl-1">
									<Link to="/product/price/15" className="price_bg price_bg2">
										<p>
											<span className="under">{strings.under}</span>
											<span className="price-dtl">15</span>
											<span className="price-desc">{currentCurrency.country_code}</span>
										</p>
									</Link>
								</div>
								<div className="col-3 col-sm-2 col-md-2 col-lg-2 col-xl-1">
									<Link to="/product/price/20" className="price_bg price_bg3">
										<p>
											<span className="under">{strings.under}</span>
											<span className="price-dtl">20</span>
											<span className="price-desc">{currentCurrency.country_code}</span>
										</p>
									</Link>
								</div>
								<div className="col-3 col-sm-2 col-md-2 col-lg-2 col-xl-1">
									<Link to="/product/price/25" className="price_bg price_bg4">
										<p>
											<span className="under">{strings.under}</span>
											<span className="price-dtl">25</span>
											<span className="price-desc">{currentCurrency.country_code}</span>
										</p>
									</Link>
								</div>
								<div className="col-3 col-sm-2 col-md-2 col-lg-2 col-xl-1">
									<Link to="/product/price/30" className="price_bg price_bg5">
										<p>
											<span className="under">{strings.under}</span>
											<span className="price-dtl">30</span>
											<span className="price-desc">{currentCurrency.country_code}</span>
										</p>
									</Link>
								</div>
								<div className="col-3 col-sm-2 col-md-2 col-lg-2 col-xl-1">
									<Link to="/product/price/35" className="price_bg price_bg1">
										<p>
											<span className="under">{strings.under}</span>
											<span className="price-dtl">35</span>
											<span className="price-desc">{currentCurrency.country_code}</span>
										</p>
									</Link>
								</div>
								<div className="col-3 col-sm-2 col-md-2 col-lg-2 col-xl-1">
									<Link to="/product/price/40" className="price_bg price_bg2">
										<p>
											<span className="under">{strings.under}</span>
											<span className="price-dtl">40</span>
											<span className="price-desc">{currentCurrency.country_code}</span>
										</p>
									</Link>
								</div>
								<div className="col-3 col-sm-2 col-md-2 col-lg-2 col-xl-1">
									<Link to="/product/price/45" className="price_bg price_bg3">
										<p>
											<span className="under">{strings.under}</span>
											<span className="price-dtl">45</span>
											<span className="price-desc">{currentCurrency.country_code}</span>
										</p>
									</Link>
								</div>
								<div className="col-3 col-sm-2 col-md-2 col-lg-2 col-xl-1">
									<Link to="/product/price/50" className="price_bg price_bg4">
										<p>
											<span className="under">{strings.under}</span>
											<span className="price-dtl">50</span>
											<span className="price-desc">{currentCurrency.country_code}</span>
										</p>
									</Link>
								</div>
								<div className="col-3 col-sm-2 col-md-2 col-lg-2 col-xl-1">
									<Link to="/product/price/55" className="price_bg price_bg5">
										<p>
											<span className="under">{strings.under}</span>
											<span className="price-dtl">55</span>
											<span className="price-desc">{currentCurrency.country_code}</span>
										</p>
									</Link>
								</div>
								<div className="col-3 col-sm-2 col-md-2 col-lg-2 col-xl-1">
									<Link to="/product/price/60" className="price_bg price_bg1">
										<p>
											<span className="under">{strings.under}</span>
											<span className="price-dtl">60</span>
											<span className="price-desc">{currentCurrency.country_code}</span>
										</p>
									</Link>
								</div>
								<div className="col-3 col-sm-2 col-md-2 col-lg-2 col-xl-1">
									<Link to="/product/price/65" className="price_bg price_bg2">
										<p>
											<span className="under">{strings.under}</span>
											<span className="price-dtl">65</span>
											<span className="price-desc">{currentCurrency.country_code}</span>
										</p>
									</Link>
								</div>
							</div>
						</div>
					</section>
					{dashboard.data ? dashboard.data.other_discount.length > 0 ?
						<section className="space-cls discount-sec">
							<div className="container">
								<div className="discount-listing">
									{dashboard.data ?
										<OwlCarousel className="owl-carousel  owl-theme" {...DiscountOption}  >
											{dashboard.data.other_discount.map((item) =>
												<Link to="/offer">
													<div className="item">
														<div className="brand-cls">
															<div className="brand-img">
																<img src={item.image} alt={item.name} />
															</div>
															<div className="brand-cont">
																<p className="brand-tag">( {strings.Promocode} : {item.code})</p>

															</div>
														</div>
													</div>
												</Link>
											)}
											{/* <div className="item">
										<div className="brand-cls">
											<div className="brand-img">
												<img src={ImgDiscount2} alt="Brand" />
											</div>
											<div className="brand-cont">
												<p className="brand-tag">For Limited Items</p>

											</div>
										</div>
									</div>
									<div className="item">
										<div className="brand-cls">
											<div className="brand-img">
												<img src={ImgDiscount3} alt="Brand" />
											</div>
											<div className="brand-cont">
												<p className="brand-tag">For Limited Items</p>

											</div>
										</div>
									</div>
									<div className="item">
										<div className="brand-cls">
											<div className="brand-img">
												<img src={ImgDiscount1} alt="Brand" />
											</div>
											<div className="brand-cont">
												<p className="brand-tag">For Limited Items</p>

											</div>
										</div>
									</div>
									<div className="item">
										<div className="brand-cls">
											<div className="brand-img">
												<img src={ImgDiscount2} alt="Brand" />
											</div>
											<div className="brand-cont">
												<p className="brand-tag">For Limited Items</p>

											</div>
										</div>
									</div> */}

										</OwlCarousel>
										: null}
								</div>
							</div>
						</section>
						: null : null}
					{dashboard.data ? dashboard.data.flowers.length > 0 ?
						<section className="space-cls flower-sec">
							<div className="container">
								<div className="bg-2">
									<div className="title-center">
										<div className="title-bg">
											<h3 className="heading-type2">{strings.flowers}</h3>
										</div>
									</div>
									<div className="flower-listing">
										{dashboard.data ? <Flower data={dashboard.data.flowers} options={flowersOption} /> : null}
									</div>
								</div>
							</div>
						</section>
						: null : null}

					{/*<section className="space-cls gift-sec">
					<div className="container">
						<div className="title-main">
							<h2 className="heading-type1">Gift Set</h2>
						</div>
						<OwlCarousel className="owl-carousel  owl-theme" {...giftOption}  >

							<div className="item">
								<div className="gift-banner">
									<a href="#">
										<img src={ImgGift1} alt="Gift" />
									</a>
									<div className="gift-title">
										<h5>Davidoff Cool</h5>
										<h6>Starting from: <span>KWD 15</span></h6>
									</div>
								</div>
							</div>
							<div className="item">
								<div className="gift-banner">
									<a href="#">
										<img src={ImgGift2} alt="Gift" />
									</a>
									<div className="gift-title">
										<h5>Davidoff Cool</h5>
										<h6>Starting from: <span>KWD 15</span></h6>
									</div>
								</div>
							</div>
							<div className="item">
								<div className="gift-banner">
									<a href="#">
										<img src={ImgGift1} alt="Gift" />
									</a>
									<div className="gift-title">
										<h5>Davidoff Cool</h5>
										<h6>Starting from: <span>KWD 15</span></h6>
									</div>
								</div>
							</div>
							<div className="item">
								<div className="gift-banner">
									<a href="#">
										<img src={ImgGift2} alt="Gift" />
									</a>
									<div className="gift-title">
										<h5>Davidoff Cool</h5>
										<h6>Starting from: <span>KWD 15</span></h6>
									</div>
								</div>
							</div>
							<div className="item">
								<div className="gift-banner">
									<a href="#">
										<img src={ImgGift2} alt="Gift" />
									</a>
									<div className="gift-title">
										<h5>Davidoff Cool</h5>
										<h6>Starting from: <span>KWD 15</span></h6>
									</div>
								</div>
							</div>


						</OwlCarousel>
					</div>
				</section>*/}
					{dashboard.data ? dashboard.data.category_banner.length > 0 ?
						<section className="space-cls fragrances-sec">
							<div className="container">
								{dashboard.data ?
									<OwlCarousel className="owl-carousel  owl-theme" {...options}  >
										{dashboard.data.category_banner.map((data) =>
											<>
												<div className="item">
													<div className="fragrances-banner">
														<a href={"/product" + "/category" + "/" + data.banner_for_id}>
															<picture>
																<source media="(min-width:575.98px)" srcSet={data.file} />
																<img src={data.file} alt={data.file} />
															</picture>
														</a>
													</div>
												</div>
												{/* <div className="item">
											<div className="fragrances-banner">
												<a href="#">
													<picture>
														<source media="(min-width:575.98px)" srcSet={ImgfragrancesBanner} />
														<img src={ImgmfragrancesBanner} alt="" />
													</picture>
												</a>
											</div>
										</div> */}
											</>
										)}

									</OwlCarousel>
									: null}
							</div>
						</section>
						: null : null}
					{dashboard.data ? dashboard.data.new_arrival.length > 0 ?
						<section className="space-cls new-arrival-sec">
							<div className="container">
								<div className="arrival-bg">
									<div className="title-center">
										<div className="title-bg">
											<h1 className="heading-type2">{strings.new_arrival} Perfume</h1>
										</div>
										<div className="view_all">
											<Link to={"/product/new-arrival"} className="btn btn_primary">{strings.view_all}</Link>
										</div>
									</div>

									{dashboard.data ? <Product showAddtoCart={showAddtoCart} data={dashboard.data.new_arrival} handleFavourite={handleFavourite} options={newAriveOption} /> : null}

								</div>
							</div>
						</section>
						: null : null}

					{dashboard.data ? dashboard.data.nice_collection_products.length > 0 ?
						<section className="space-cls new-arrival-sec">
							<div className="container">
								<div className="arrival-bg">
									<div className="title-center">
										<div className="title-bg">
											<h3 className="heading-type2">{strings.nice_collection}</h3>
										</div>
										<div className="view_all">
											<Link to={"/product/nice-collection"} className="btn btn_primary">{strings.view_all}</Link>
										</div>
									</div>

									{dashboard.data ? <Product showAddtoCart={showAddtoCart} data={dashboard.data.nice_collection_products} handleFavourite={handleFavourite} options={newAriveOption} /> : null}

								</div>
							</div>
						</section>
						: null : null}

					{dashboard.data ? dashboard.data.product_banner.length > 0 ?
						<section className="space-cls best-seller-sec">
							<div className="container">
								{dashboard.data ?
									<OwlCarousel className="owl-carousel  owl-theme" {...options}  >
										{dashboard.data.product_banner.map((data) =>
											<div className="item">
												<div className="banner-seller">
													<a href={"/product-detail/" + data.banner_for_id} target="_blank">
														<picture>
															<source media="(min-width:575.98px)" srcSet={data.file} />
															<img src={data.file} alt={data.file} />
														</picture>
													</a>
													<div className="brand-cont">
														<p className="brand-tag">{strings.best_saller}</p>
														<p>{strings.you_can_takes}</p>
													</div>
												</div>
											</div>
										)}
										{/* <div className="item">
									<div className="banner-seller">
										<a href="#">
											<picture>
												<source media="(min-width:575.98px)" srcSet={ImgBestSeller} />
												<img src={ImgmBestSeller} alt="" />
											</picture>
										</a>
										<div className="brand-cont">
											<p className="brand-tag">Best Seller Fragrance</p>
											<p>You Can Take Maximum Discount Here</p>
										</div>
									</div>
								</div> */}
									</OwlCarousel>
									: null}
							</div>
						</section>
						: null : null}
					{dashboard.data ? dashboard.data.discount_bannner.length > 0 ?
						<section className="space-cls sale-sec sale_you_can_miss">
							<div className="container">
								<div className="title-main">
									<h2 className="heading-type1">{strings.sale_you_can_miss}</h2>
								</div>

								<OwlCarousel className="owl-carousel  owl-theme" {...flowersOption}  >
									{/*dashboard.data.brand_discount.map((data) =>
									<div className="item">
										<div className="sale-prd">
											<a href={"/product/brand/" + data.brand_id}>
												<img src={data.image} alt="" />
											</a>
										</div>
									</div>
							)*/}

									{dashboard.data.discount_bannner.map((item) =>
										<div className="item">
											<div className="sale-prd">
												<Link to={'/product/discount/' + item.discount}>
													<img src={item.image} alt={item.image} />
													<div className='sale-prd-cont'>
														<span className='upto'>UP TO</span>
														<h4>{item.discount}%</h4>
														<span className='off'>OFF</span>
													</div>
												</Link>
											</div>
										</div>
									)}

									{/* <div className="item">
								<div className="sale-prd">
									<Link to="/product/discount/40">
										<img src={ImgmSale3} alt="" />
										<div className='sale-prd-cont'>
											<span className='upto'>UP TO</span>
											<h4>40%</h4>
											<span className='off'>OFF</span>
										</div>
									</Link>
								</div>
							</div>
							<div className="item">
								<div className="sale-prd">
									<Link to="/product/discount/50">
										<img src={ImgmSale2} alt="" />
										<div className='sale-prd-cont'>
											<span className='upto'>UP TO</span>
											<h4>50%</h4>
											<span className='off'>OFF</span>
										</div>
									</Link>
								</div>
							</div>

							<div className="item">
								<div className="sale-prd">
									<Link to="/product/discount/60">
										<img src={ImgmSale1} alt="" />
										<div className='sale-prd-cont'>
											<span className='upto'>UP TO</span>
											<h4>60%</h4>
											<span className='off'>OFF</span>
										</div>
									</Link>
								</div>
							</div> */}

								</OwlCarousel>

							</div>
						</section>
						: null : null}

					{/*<section className="space-cls essentials-sec">
	          	<div className="container">
		            <div className="bg-1">
			            <div className="title-center">
			                <div className="title-bg">
			            	    <h3 className="heading-type2">Beauty Essentials</h3>
			                </div>
			            </div>
						<OwlCarousel className="owl-carousel  owl-theme" {...essentialsOption}  > 
			                <div className="item">
			                    <div className="essentials-box">
			                        <div className="prd-img">
			                          <a href="#">
			                            <img src={ImgPrd5} alt="Product"/>
			                          </a>
			                        </div>
			                        <h4>HAIR</h4>
			                    </div>
			                </div>
			                <div className="item">
			                    <div className="essentials-box">
			                      <div className="prd-img">
			                        <a href="#">
			                          <img src={ImgPrd6} alt="Product"/>
			                        </a>
			                      </div>
			                        <h4>SKINCARE</h4>
			                    </div>
			                </div>
			                <div className="item">
			                    <div className="essentials-box">
			                      <div className="prd-img">
			                        <a href="#">
			                          <img src={ImgPrd7} alt="Product"/>
			                        </a>
			                      </div>
			                        <h4>MAKEUP</h4>
			                    </div>
			                </div>	
						</OwlCarousel>
		            </div>
	            </div>
	</section>*/}


					{dashboard.data ? dashboard.data.categories_product.length > 0 ? dashboard.data.categories_product.map((item, index) =>
						<>
							{item.products ? item.products.length > 0 ?
								<section className="space-cls new-arrival-sec">
									<div className="container">
										<div className="arrival-bg">
											<div className="title-center">
												<div className="title-bg">
													<h3 className="heading-type2">{item.name} {strings.fragrance}</h3>
												</div>
												<div className="view_all">
													<Link to={"/product/" + item.slug} className="btn btn_primary">{strings.view_all}</Link>
												</div>
											</div>
											{item.products ? <Product showAddtoCart={showAddtoCart} data={item.products} handleFavourite={handleFavourite} options={newAriveOption} key={index} /> : null}
										</div>
									</div>
								</section>
								: null : null}
							{/* {item ? item.sub_categories.length > 0 ?
								<section className="space-cls home-fragrance-sec">
									<div className="container">
										<div className="title-main">
											<h2 className="heading-type1">{item.name}  {strings.fragrance}</h2>
										</div>

										<HomeFragrance data={item.sub_categories} options={categoryInnerOptions} key={index} d={'item'} />
									</div>
								</section>
								: '' : null} */}
						</>
					)
						:
						null : null}
					{dashboard.data ? dashboard.data.brand_banner.length > 0 ?
						<section className="space-cls try-buy-sec">
							<div className="container">
								{dashboard.data ?
									<OwlCarousel className="owl-carousel  owl-theme" {...options}  >
										{dashboard.data.brand_banner.map((data) =>
											<div className="item">
												<div className="banner-seller">
													<a href={"/product/brand/" + data.banner_for_id}>
														<picture>
															<source media="(min-width:575.98px)" srcSet={data.file} />
															<img src={data.file} alt={data.file} />
														</picture>
													</a>
												</div>
											</div>
										)}
										{/* <div className="item">
									<div className="banner-seller">
										<a href="#">
											<picture>
												<source media="(min-width:575.98px)" srcSet={ImgTryBuyBanner} />
												<img src={{ ImgmTryBuyBanner }} alt="" />
											</picture>
										</a>
									</div>
								</div> */}
									</OwlCarousel>
									: null}
							</div>
						</section>
						: null : null}
					{dashboard.data ? dashboard.data.combo.length > 0 ?
						<section className="space-cls combo-sec">
							<div className="container">

								{dashboard.data ?

									<OwlCarousel className="owl-carousel  owl-theme" {...comboOption}  >
										{dashboard.data.combo.map((item, index) =>
											<div className="item">
												<div className="combo-img">
													<Link to={"/product-combo-detail/" + item.id}>
														<img src={item.image} alt={item.name} />
													</Link>
												</div>
											</div>
										)}
									</OwlCarousel>
									: null}
							</div>
						</section>
						: null : null}

					{dashboard.data ? dashboard.data.vinatge_collection.length > 0 ?
						<section className="space-cls home-fragrance-prd-sec">
							<div className="container">
								<div className="title-main">
									<h2 className="heading-type1">{strings.vintage_collection}</h2>
								</div>
								{dashboard.data ? <Product showAddtoCart={showAddtoCart} data={dashboard.data.vinatge_collection} handleFavourite={handleFavourite} options={newAriveOption} /> : null}
							</div>
						</section>
						: null : null}

					{dashboard.data ? dashboard.data.sub_category_banner.length > 0 ?
						<section className="space-cls best-seller-sec">
							<div className="container">
								{dashboard.data ?
									<OwlCarousel className="owl-carousel  owl-theme" {...options}  >
										{dashboard.data.sub_category_banner.map((data) =>
											<div className="item">
												<div className="banner-seller">
													<a href={"/product/sub/" + data.banner_for_id}>
														<picture>
															<source media="(min-width:575.98px)" srcSet={data.file} />
															<img src={data.file} alt={data.file} />
														</picture>
													</a>
													<div className="brand-cont">
														<p className="brand-tag">{strings.explore_cotalogue}</p>

													</div>
												</div>
											</div>
										)}
										{/* <div className="item">
									<div className="banner-seller">
										<a href="#">
											<picture>
												<source media="(min-width:575.98px)" srcSet={ImgBestSeller} />
												<img src={ImgmBestSeller} alt="" />
											</picture>
										</a>
										<div className="brand-cont">
											<p className="brand-tag">Explore Catalogue</p>

										</div>
									</div>
								</div> */}

									</OwlCarousel>
									: null}
							</div>
						</section> : null : null}
					{/* {dashboard.data ? dashboard.data.catalogue_collection.length > 0 ?
						<section className="space-cls home-fragrance-prd-sec">
							<div className="container">
								<div className="title-main">
									<h2 className="heading-type1">{strings.catalogue_product}</h2>
								</div>
								{dashboard.data ? <Product showAddtoCart={showAddtoCart} data={dashboard.data.catalogue_collection} handleFavourite={handleFavourite} options={newAriveOption} /> : null}
							</div>
						</section>
						: null : null} */}

					{dashboard.data ? dashboard.data.bundle_products.length > 0 ?
						<section className="space-cls new-arrival-sec">
							<div className="container">
								<div className="arrival-bg">
									<div className="title-center">
										<div className="title-bg">
											<h3 className="heading-type2">{strings.bundle}</h3>
										</div>
									</div>

									{dashboard.data ? <Product showAddtoCart={showAddtoCart} data={dashboard.data.bundle_products} handleFavourite={handleFavourite} options={newAriveOption} /> : null}

								</div>
							</div>
						</section>
						: null : null}

					{dashboard.data ? dashboard.data.combo_banner.length > 0 ?
						<section className="space-cls best-seller-sec combo_banner">
							<div className="container">
								{dashboard.data ?
									<OwlCarousel className="owl-carousel  owl-theme" {...options}  >
										{dashboard.data.combo_banner.map((data) =>
											<div className="item">
												<div className="banner-seller">
													<a href={'/product/' + (data.type != "product" ? data.type : "product-detail") + "/" + data.banner_for_id}>
														<picture>
															<source media="(min-width:575.98px)" srcSet={data.file} />
															<img src={data.file} alt={data.file} />
														</picture>

														<div className="brand-cont">
															<p className="brand-tag">{strings.explore_cotalogue}</p>

														</div>
													</a>
												</div>
											</div>
										)}
										{/* <div className="item">
									<div className="banner-seller">
										<a href="#">
											<picture>
												<source media="(min-width:575.98px)" srcSet={ImgBestSeller} />
												<img src={ImgmBestSeller} alt="" />
											</picture>
										</a>
										<div className="brand-cont">
											<p className="brand-tag">Explore Catalogue</p>

										</div>
									</div>
								</div> */}

									</OwlCarousel>
									: null}
							</div>
						</section> : null : null}



					{/*<section className="space-cls gift-card-sec">
	          <div className="container">
	            <div className="title-main">
	              <h2 className="heading-type1">Gift Card</h2>
	            </div>
				{ dashboard.data ? <Banner data={dashboard.data.gift_card} type="gift" options={giftOption}/> : <Skeleton height={100} count={5} col={3} />}

	          </div>
		</section>*/}
				</>
					: <Skeleton height={100} count={5} col={3} />}
			</main>
			<Footer />
            {modalIsOpen ? <>
				<Modal id="selectionModal" show={true} animation={true} size="lg" backdrop="static" keyboard={false} centered>
					<Modal.Header className="modal-header-custom">
						{/* <h5 className="modal-title">{strings.pickItemSize}</h5> */}
						<button
						type="button"
						className="btn-close"
						aria-label="Close"
						onClick={setModalIsOpenToFalse}
						>
						<img src={Imgcross} alt="Close" />
						</button>
					</Modal.Header>
					<Modal.Body className="modal-body-custom">
						<ValidationForm ref={formRef}>
						<div className="product-details">
							<img src={comman.product.image} alt={comman.product.name} className="product-image" />
							<div className="product-info">
								<h6 className="product-name">{comman.product.name}</h6>
								{/* Size Options */}
								<Form.Group className="form-group prd-size-group" controlId="form-group">
									<div className="prd_size_label">
									{comman.attributes[0]?.attribute_values &&
										comman.attributes[0].attribute_values.map((option, i) => (
										<label className="custom_radio_b" key={option.id}>
											<input
											type="radio"
											name="attribute_value_id"
											value={option.id}
											defaultChecked={i === 0}
											onChange={(e) => handleAttributeChange(comman.product.id, option.id)}
											/>
											<span className="checkmark">{option.value}</span>
										</label>
										))}
									</div>
								</Form.Group>
							</div>
							<div className="product-price">
								<Currency data={comman.product.discount_price != 0 ? comman.product.discount_price : comman.product.original_price} />
							</div>
						</div>

						{/* Submit Button */}
						<div className="login-btn">
							<Button
							onClick={(e) => addtoCart(comman.product_id, 'form')}
							type="button"
							className="btn btn-primary w-100"
							>
							{strings.pickThisSize}
							</Button>
						</div>
						</ValidationForm>
					</Modal.Body>
				</Modal></> : ""}
			{modalIsNotifyReviewOpen ? <>
            <Modal show={true} animation={true} size="lg" backdrop="static" keyboard={false} centered >
               <Modal.Header>
			   		<h5 className="modal-title" id="exampleModalLabe111l">{strings.modalTitleIsReview}</h5>
					<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
						<img src={Imgcross} alt='Close' onClick={setModalIsNotifyReviewOpenToFalse} />
					</button>
               </Modal.Header>
               <Modal.Body className='modal-body'>
                  <div className="continue-btn review_modal">
                        <div className="popup-cont">
							<div className="logo text-center mb-3">
								<img src={logo} alt="Logo" />
							</div>
							<div className='mb-2'>{strings.modalContentIsReview}</div>
							<div className="login-btn justify-content-center">
								<Link to="/order?type=Complete" className="btn btn_primary">
									{strings.review}
								</Link>
                           </div>
                        </div>
                  </div>
               </Modal.Body>
            </Modal></> : ""}
		</>
	)
}
export default connect(mapStateToProps, mapDispatchToProps)(Home);