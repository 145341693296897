import React, { useState, useEffect } from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { Link, Navigate } from "react-router-dom";

import { userLogin } from "../actions/index";
import { connect, createSelectorHook } from "react-redux";
import ShowSignBox from '../view/Modal/ShowSignBox';
import Login from '../view/Modal/Login';

import ImgCart1 from '../assets/img/cart_1.svg';
import ImgDeliveryTruck from '../assets/img/delivery-truck.png';
import ImgLocationIcon from '../assets/img/location_icon.png';

import ImgDelete from '../assets/img/delete.png';
import ImgGiftW1 from '../assets/img/gift-w1.png';
import ImgGiftW2 from '../assets/img/gift-w2.png';
import ImgGiftW3 from '../assets/img/gift-w3.png';
import ImgGiftW4 from '../assets/img/gift-w4.png';

import ImgGiftIcon from '../assets/img/gift_icon.png';

import apiPath from "../constants/apiPath";
import Http from "../services/Http";

import toast, { Toaster } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Accordion, Button, Form } from 'react-bootstrap';
import cartEmpty from '../assets/img/cart-empty.png';
import CommanList from '../view/Component/CommanList';
import { SelectGroup, TextInput, ValidationForm } from 'react-bootstrap4-form-validation';
import Modal from 'react-bootstrap/Modal';
import Imgcross from '../assets/img/cross.png';
import Currency from './Component/Currency';
import { parseInt } from 'lodash';
import LoadingOverlay from 'react-loading-overlay-ts';

import LocalizedStrings from 'react-localization';
import localizations from "../localization";
import useScript from '../constants/useScript';
import { debounce } from 'lodash';

let strings = new LocalizedStrings(localizations);

const mapStateToProps = state => {
	return { user: state.user };
};

const mapDispatchToProps = dispatch => {
	return {
		userLogin: user => dispatch(userLogin(user))
	};
}
const Cart = (Props) => {
	useScript('https://checkout.tabby.ai/tabby-promo.js');

	const formRef = React.createRef();
	const [user, setUser] = useState(Object.keys(Props.user).length > 0 ? Props.user : null)
	const [comman, setComman] = useState({
		loginModel: false,
		shippingType: 'delivery',
		latitude: 26.8587263,
		longitude: 75.7720215,
		prodcessToCheckout: false,
		addOns: [],
		addOnDataId: [],
		giftCardId: 0,
		pickup_store: '',
		message: ''

	});
	const [cartList, setCartList] = useState([]);
	const [pickupStore, setPickupStore] = useState([]);
	const [giftCard, setGiftCard] = useState([])
	const [modalIsOpen, setModalIsOpen] = useState(false);
	
	const [showSignBoxModal, setShowSignBoxModal] = useState(false);
	const [showLoginModal, setShowLoginModal] = useState(false);

	const [showDeliveryModal, setShowDeliveryModal] = useState(false);

	const [selectedShippingType, setSelectedShippingType] = useState(comman.shippingType);
	const [selectedPickupStore, setSelectedPickupStore] = useState(comman.pickup_store);

	const [isLoading, setIsLoading] = useState(false);

	const handleShippingTypeChange = (newType) => {
		setSelectedShippingType(newType);
		setComman(prev => ({ ...prev, shippingType: newType }));
	};
	
	const handlePickupStoreChange = (e) => {
		setSelectedPickupStore(e.target.value);
		setComman(prev => ({ ...prev, pickup_store: e.target.value }));
	};

	const debouncedGetList = debounce(() => {
		getList(comman.latitude, comman.longitude, selectedShippingType, selectedPickupStore);
	}, 500);

	useEffect(() => {
		if (showSignBoxModal === false && showLoginModal === true) {
			setTimeout(() => setShowLoginModal(true), 10);
		}
	}, [showSignBoxModal, showLoginModal]);

	const handleShowSignBox = () => {		
		setShowSignBoxModal(true);
	};

	const handleOpenLogin = () => {
		setShowSignBoxModal(false);
		setShowLoginModal(true);
	};

	const handleCloseLogin = () => {
		setShowLoginModal(false);
	};

	const [currentCurrency, setCurrentCurrency] = useState(
		localStorage.getItem("current_currency")
			? JSON.parse(localStorage.getItem("current_currency"))
			: { currency: 1, country_code: "KWD" }
	)


	const [currentLang, setCurrentLang] = useState(
		localStorage.getItem("current_lang")
			? localStorage.getItem("current_lang")
			: "en"
	);
	strings.setLanguage(currentLang);


	const setModalIsOpenToTrue = (e) => {
		setComman({ ...comman, message: '' })
		setModalIsOpen(true)
		addOnValue()
	}

	const setModalIsOpenToFalse = () => {
		setModalIsOpen(false)
		setComman({ ...comman, message: '' })
	}

	// const position = async () => {
	// 	// await navigator.geolocation.getCurrentPosition(
	// 	// 	async position => {
	// 	await setComman({ ...comman, latitude: position.coords.latitude, longitude: position.coords.longitude });
	// 	getList(position.coords.latitude, position.coords.longitude, comman.shippingType);
	// 	getPickupStore(position.coords.latitude, position.coords.longitude, comman.shippingType)
	// 	// 	},
	// 	// 	err => console.log(err)
	// 	// );
	// }

	const changeShippingTrype = async (shippingType) => {
		setComman(prev => ({
			...prev,
			shippingType: shippingType,
			pickup_store: shippingType === 'pickup' ? prev.pickup_store : '',
		}));
		// getList(comman.latitude, comman.longitude, shippingType)

		//console.log(comman, "command")
	}
	const messaeset = async (e) => {
		setComman({ ...comman, message: e.target.value })
	}
	const getList = async (latitude, longitude, shippingType, pickup_id = null, isShowMessage = true) => {
		// setCartList([]);
		let path = apiPath.getCart;
		var data = new FormData();
		data.append('delivery_type', shippingType);
		data.append('latitude', latitude);
		data.append('longitude', longitude);
		data.append('pickup_id', pickup_id ? pickup_id : comman.pickup_store);
		data.append('currency', currentCurrency.country_code);
		let options = {
			method: 'POST',
			data: data
		}
		let result = await Http(path, options);
		if (result.status == 200) {
			if (result.data.status) {
				await setCartList({ data: result.data.data });
				setComman({ ...comman, delivery_type: result.data.data.pickup_option })
				// console.log(result);
				if(result.data.data.gift_addon_id != null) {
					(result.data.data.gift_addon_id.split(",").map((elemet) => {

						comman.addOnDataId.push(parseInt(elemet))

					}))
				}

				setComman({ ...comman, addOnDataId: [...new Set(comman.addOnDataId)] });
				
				if (!isShowMessage) {
					new window.TabbyPromo({
						selector: '#tabbyCard',
						lang: currentLang,
						currency: currentCurrency.country_code,
						price: ((parseFloat(currentCurrency.currency) * result.data.data.amount).toFixed(2)),
						productType: 'installments',
					});

					toast.success(result.data.message);
				}
			}
			else {
				setCartList([]);
				toast.error(result.data.message);
			}
		}
	}
	const getPickupStore = async (latitude, longitude, shippingType) => {
		let path = apiPath.pickupStore;
		var data = new FormData();
		data.append('latitude', latitude);
		data.append('longitude', longitude);
		let options = {
			method: 'POST',
			data: data
		}

		let result = await Http(path, options);
		if (result.status == 200) {
			if (result.data.status) {

				setPickupStore({ data: result.data.data });
				setComman({ ...comman, delivery_type: shippingType })


				// (result.data.data.gift_addon_id.split(",").map((elemet) => {

				// 	comman.addOnDataId.push(parseInt(elemet))
				// }))

				// setComman({ ...comman, addOnDataId: [...new Set(comman.addOnDataId)] });
				
				// Hidden message
				console.log(result.data);
				// toast.success(result.data.message);
			}
			else {
				setPickupStore([]);
				toast.error(result.data.message);
			}
		}
	}
	const deleteItem = async (id) => {
		if (window.confirm(strings.are_you_sure_clear_cart) == true) {
			let path = apiPath.cartDestroy;
			var data = new FormData();

			if (id != 0) {
				data.append('cart_id', id);
			}

			let options = {
				method: 'POST',
				data: data
			}

			let result = await Http(path, options);
			if (result.status == 200) {
				if (result.data.status) {
					if (result.data.data.length <= 0) {
						window.location.assign('/');
					}
					getList(comman.latitude, comman.longitude, comman.shippingType);
					toast.success(result.data.message);
				}
				else {
					toast.error(result.data.message);
				}
			}
		}
	}
	const addtoCart = async (type, qty, attr, product_id, addtoCart, index) => {
		if (qty > 0) {
			let path = '';
			if (addtoCart == 'Product') {
				path = apiPath.AddtoCart;
			}
			else {
				path = apiPath.addToCombo;
			}

			var data = new FormData();
			data.append('product_id', product_id);
			data.append('qty', qty);
			if (attr) {
				data.append('product_attribute_values_id', attr);
			}

			let options = {
				method: 'POST',
				data: data
			}
			let result = await Http(path, options);
			if (result.status == 200) {
				if (result.data.status) {

					cartList.data.items[index].qty = qty;
					setCartList({ data: cartList.data });
					getList(comman.latitude, comman.longitude, comman.shippingType);
					toast.success(result.data.message);
				}
				else {
					toast.error(result.data.message);
				}
			}
		}
		else {
			toast.error(strings.at_last_one_qty);
		}
	}
	const addRemove = (e, index, type, qty, itemtype) => {

		if (type == 'add') {

			addtoCart(type, qty + 1, cartList.data.items[index].products.attribute_value_id, cartList.data.items[index].products.id, itemtype, index);

		}
		else {
			//cartList.data.items[index].qty = qty - 1;
			//setCartList({ data: cartList.data });
			//setCartList(cartList);
			addtoCart(type, qty - 1, cartList.data.items[index].products.attribute_value_id, cartList.data.items[index].products.id, itemtype, index);
		}


	}
	const addCard = async (e, element) => {

		setModalIsOpenToTrue()
		await setComman({ ...comman, addOns: [], giftCardId: element.id })
		if (element.add_ons.length > 0) {
			await setComman({ ...comman, addOns: element.add_ons, giftCardId: element.id })

		}

	}
	const processToCheckout = async () => {
		if (comman.shippingType === 'pickup' && !comman.pickup_store) {
			toast.error(strings.select_pickup_address);
			return;
		}

		setIsLoading(true);
		try {
			await getList(comman.latitude, comman.longitude, comman.shippingType, comman.pickup_store, false);
			setComman({ ...comman, prodcessToCheckout: true })
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	}
	const getGiftCardList = async () => {
		let path = apiPath.giftCard;
		var data = new FormData();
		let options = {
			method: 'Get',
			data: data
		}
		let result = await Http(path, options);
		if (result.status == 200) {
			if (result.data.status) {
				setGiftCard({ data: result.data.data });
				// toast.success(result.data.message);
			}
			else {
				setGiftCard([]);
				// toast.error(result.data.message);
			}
		}
	}
	const changeStore = async (e) => {
		await setComman({ ...comman, pickup_store: e.target.value })
		getList(comman.latitude, comman.longitude, comman.shippingType, e.target.value)

	}
	const addOnValue = async (e, id) => {

		if (id) {


			if (e.target.checked) {
				await comman.addOnDataId.push(id);
				setComman({ ...comman, addOnDataId: [...new Set(comman.addOnDataId)] });
			}
			else {
				const array = comman.addOnDataId
				var index = array.indexOf(id)
				if (index !== -1) {
					array.splice(index, 1);
					setComman({ ...comman, addOnDataId: array });
				}
				// await comman.addOnDataId.pop(id);
			}
		}
		else {
			setComman({ ...comman, addOnDataId: [] });
		}
	}
	const wrappingAddonSubmit = async (e, formData, inputs) => {
		e.preventDefault();
		let path = apiPath.submitGiftCard;

		if (comman.addOnDataId.length) {
			formData.gift_addon_id = comman ? comman.addOnDataId.toString() : null
			formData.gift_card_id = comman ? comman.giftCardId : 0
		}
		if (comman.message) {
			formData.message = comman.message ? comman.message : ''
			formData.gift_card_id = comman ? comman.giftCardId : 0
		}

		// formData.cart_parent_id = comman ? comman.parent_cart_id : 0


		let options = {
			method: 'POST',
			data: formData
		}
		let result = await Http(path, options);
		if (result.status === 200) {
			if (result.data.status) {
				// toast.success(result.data.message);
				setModalIsOpenToFalse()
				getList(comman.latitude, comman.longitude, comman.shippingType, comman.pickup_store)
				// window.location.reload()
			} else {
				toast.error(result.data.message);
			}
		} else {
			if(result.statusText != null) {
				toast.error(result.statusText);
			}
		}
	}
	const wrappingAddonSubmitErrorSubmit = (e, formData, inputs) => {
		e.preventDefault();
	}

	useEffect(() => {
		debouncedGetList();
		getPickupStore(comman.latitude, comman.longitude, comman.shippingType)
		getGiftCardList()
	}, [selectedShippingType, selectedPickupStore]);

	useEffect(() => {
		window.scrollTo(0, 0);
		document.body.classList.add('cart_page');
		return () => {
			document.body.classList.remove('cart_page');
		};
	}, []);


	return (
		<>
			<Header data={cartList} />
			{showSignBoxModal && (
                <ShowSignBox 
                    onLoginClicked={handleOpenLogin}
                />
            )}
            {showLoginModal && (
                <Login data={showLoginModal}
                    onClose={handleCloseLogin}
                />
            )}


			{comman.prodcessToCheckout ? <Navigate to={'/checkout/' + comman.shippingType} data={comman} replace={true} /> : ''}

			<LoadingOverlay active={isLoading} spinner>
			<main>
				{cartList.data ?
					<section className="space-cls cart-page">
						<div className="container">
							<div className="cart_row">
								<div className="cart_left">
									<div className="cart_title">
										<h3>
											<span>
												<img src={ImgCart1} alt='Cart' />
											</span>
											<span className="bag_cont">{strings.beg} ({cartList.data ? Object.keys(cartList.data.items).length : 0})</span>
											<span className="bag_count">{cartList.data ? Object.keys(cartList.data.items).length : 0}</span></h3>
										<Link to="#" className="clear_filter" onClick={() => deleteItem(0)}>
											{strings.clearBag}

										</Link>
									</div>
									<Accordion defaultActiveKey={['0']}>
										<Accordion.Item eventKey="0">
											<Accordion className='p-3'>Product List</Accordion>
											<Accordion.Body>
												<div className="cart_product_list">
													{cartList.data ?
														cartList.data.items.map((item, index) =>
															<div className="cart-product">
																{
																	item.products ? (
																		<>
																		<div className="cart-prd-img">
																			<Link to={item.type == "Combo" ? '/product-combo-detail/' + item.products.id : item.sale_id == 0 ? '/product-detail/' + item.products.id : '/product-detail/' + item.products.id + '/sale'} target="_blank">
																			<img src={item.products.image} alt={item.products.name} />
																			</Link>
																		</div>
																		<div className="card-prd-cont">
																			<div className="arrival-title">
																			<h3>
																				{item.products.name}
																				{item.is_sample && <span className="sample-tag">{strings.sample}</span>}
																				{item.products.left === 0 && <span className="out-of-stock-tag">Out of Stock</span>}
																			</h3>
																			<p>{item.type == 'Product' ? item.products.brands.name : strings.combo}</p>
																			</div>
																			<div className="prd-quantity">
																			<span>{strings.qty} x {item.qty}</span>
																			{!item.is_sample && (
																				<div className="q_btn_group">
																				<button type="button" id="sub" className="sub" onClick={(e) => addRemove(e, index, 'sub', item.qty, item.type)}>-</button>
																				<input type="number" id="1" value={item.qty} min="1" max="3" />
																				<button type="button" id="add" className="add" onClick={(e) => addRemove(e, index, 'add', item.qty, item.type)}>+</button>
																				</div>
																			)}
																			</div>
																		</div>
																		</>
																	) : (
																		<>
																		<div className="cart-prd-img"></div>
																		<div className="card-prd-cont">
																			<div className="arrival-title">
																			<h3>{strings.product_not_exist}</h3>
																			</div>
																		</div>
																		</>
																	)
																}
																<div className="cart_prd_right cart_price_box">
																	<div className="item_total_price">
																		<div className="item-remove">
																			<Link to="#" onClick={() => deleteItem(item.id)}>
																				<img src={ImgDelete} alt="Delete" />
																			</Link>
																		</div>
																		<div className='card_price_main'>
																			<h3><Currency data={item.amount > 0 ? item.amount : item.product_price} /> </h3>
																			{item.amount > 0 ?
																				item.product_price != item.amount ?
																					<div className="arrival-price">
																						<p className="discount-price"><span><Currency data={item.product_price} /></span>

																							({item.amount > 0 ? Math.round(((item.product_price - item.amount) * 100) / item.product_price).toFixed(2) : 0} {strings.off})</p>
																					</div> : ''

																				:
																				<div>
																					<p className="discount-price"><span><Currency data={item.product_price} /></span> </p>
																				</div>}
																		</div>
																	</div>
																</div>
															</div>
														) : strings.no_item_found}

												</div>
												<div id="tabbyCard"></div>
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>
								</div>
								<div className="cart_right">
									<div className="cart_right_title">
										<h3>{strings.price_detail}</h3>
									</div>
									<div className="cart_price">
										<ul>
											<li>
												<span className="price_left">{strings.price}</span>
												<span className="price_right"><Currency data={cartList.data ? cartList.data.amount : 0} /></span>
											</li>

											{cartList.data && cartList.data.gift_total_price > 0 ?
												<li>
													<span className="price_left">{strings.AddOns}</span>
													<span className="price_right"><Currency data={cartList.data ? cartList.data.gift_total_price : 0} /></span>
												</li> : ''}


											{cartList.data && cartList.data.discount_code ?
												<li>
													<span className="price_left">{strings.discount}</span>
													<span className="price_right">- <Currency data={cartList.data.discount_amount} />
														({cartList.data.discount_code})
													</span>
												</li> : ''}
											{cartList.data && cartList.data.shipping_charges ?
												<li>
													<span className="price_left">{strings.shipping_fee}</span>
													<span className="price_right"> <Currency data={cartList.data.shipping_charges} /></span>
												</li> : ''}

											{cartList.data && cartList.data.tax_amount > 0 ?
												<li>
													<span className="price_left">{strings.tax}</span>
													<span className="price_right"><Currency data={cartList.data.tax_amount} /></span>
												</li> : ''}



										</ul>
									</div>
									<div className="gift-code">
										{/*}
									<div className="form-group">
										<div className="form-wrap">
											<input type="text" name="" placeholder="Discount Code" className="form-control" />
											<div className="subs-btn">
												<button type="button" className="btn btn_primary">Apply</button>
											</div>
										</div>
									</div>*/}



										<div className="gift-wrapping">
											<h3>
												<span>
													<img src={ImgGiftIcon} alt='Gift' />
												</span>
												{strings.giftWrapping}
												</h3>
											<div className="select-gift-card">
												{giftCard ? giftCard.data ? giftCard.data.length > 0 ? giftCard.data.map((element) =>
													<label className="custom_radio_b">
														<input type="radio" name="radio" value={element} checked={element.id == cartList.data.gift_card_id} onClick={((e) => addCard(e, element))} />
														<span className="checkmark">
															<span className="gift-card-img">
																<img src={element.image} alt={element.image} />
															</span>
														</span>
													</label>
												) : null : null : null}

											</div>


										</div>

									</div>

									<div className="g-total">
										<p>{strings.grand_total} :  <Currency data={cartList.data ? cartList.data.totalAmount : 0} /></p>
										{/* {user && user.details.temp_user == 1 ?
											<Link to="#" onClick={showSignBox} className="btn btn_primary">{strings.sign_in}</Link>

											: */}
										<Button
											type="button"
											className="btn btn_primary"
											onClick={() => setShowDeliveryModal(true)}
										>
											{strings.proceed}
										</Button>
										{/* } */}
										{user && user.details.temp_user == 1 ?
										<div className="cart-address-wrap">
											<div className="cart_address_bg">
												<div className="cart_login">
													<p>{strings.login_or_register_first}</p>
												</div>
												<div className="cart_login_btn">
													<Link to="#" onClick={handleShowSignBox} className="btn btn_primary">{strings.sign_in}</Link>
												</div>
											</div>
										</div>
										: ''}
									</div>
								</div>
							</div>
						</div>
					</section > : null
				}
				{/* <CommanList /> */}
			</main >
			</LoadingOverlay>
			<Footer />
			{
				modalIsOpen ? <>
					<Modal show={true} animation={true} size="lg" backdrop="static" keyboard={false} centered >
						<Modal.Header>
							<h5 className="modal-title" id="exampleModalLabe111l">{strings.giftWrappingAddon}						</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
								<img alt='Close' src={Imgcross} onClick={setModalIsOpenToFalse} />
							</button>
						</Modal.Header>
						<Modal.Body>
							<div className="filter_cont_s">
								<ValidationForm onSubmit={wrappingAddonSubmit} onErrorSubmit={wrappingAddonSubmit} ref={formRef}>
									<div className="select_cls">
										{comman ? comman.addOns.length > 0 ?
											<Form.Group className="form-group" controlId="form-group">

												<><Form.Label className="custom_checkbox">{strings.AddOns}</Form.Label>

													<div className='custom_react_slt'>
														{comman.addOns.length > 0 ? comman.addOns.map((element) =>
															<>
																<div className='custom_react_inner_select'>
																	<input type="checkbox" value={element.id} checked={comman.addOnDataId.includes(element.id) ? true : false} onClick={(e) => addOnValue(e, element.id)} />
																	<span className="checkmark"></span>
																	{element.name}
																	< span className="filter_meta" > <Currency data={element.price} /></span>
																</div>
															</>) : null}
													</div></>
											</Form.Group> : null : null}
										<Form.Group className="form-group" controlId="form-group">
											<Form.Label>{strings.message}</Form.Label>
											<textarea className='form-control'
												name="message" id="message"
												onChange={messaeset}
												placeholder={strings.enter_message}
											/>
										</Form.Group>
										<div className="login-btn">
											<Button type="submit" className="btn btn_primary">{strings.submit}</Button>
										</div>

									</div>
								</ValidationForm>
							</div>
							{/* </div> */}
						</Modal.Body>

					</Modal></> : ""
			}
			<Modal show={showDeliveryModal} onHide={() => setShowDeliveryModal(false)} centered size="lg" backdrop="static" id="deliveryModal">
				<Modal.Header closeButton>
					<Modal.Title>{strings.delivery_method}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
				<div className="cart_address">
					<div className="custom_slt">
						<label className="custom_radio_b">
							<input
								type="radio"
								name="shipping_type"
								onClick={() => changeShippingTrype('delivery')}
								checked={comman.shippingType === 'delivery'}
							/>
							<span className="checkmark"></span>
							<div>
								<span className="address_ic">
									<img src={ImgDeliveryTruck} alt="Delivery Truck" />
								</span>
								<div>
									<h6>{strings.home_delivery}</h6>
									<p className="description">{strings.home_delivery_summary}</p>
								</div>
							</div>
						</label>
						<label className="custom_radio_b">
							<input
								type="radio"
								name="shipping_type"
								onClick={() => changeShippingTrype('pickup')}
								checked={comman.shippingType === 'pickup'}
							/>
							<span className="checkmark"></span>
							<div>
								<span className="address_ic">
									<img src={ImgLocationIcon} alt="Location Icon" />
								</span>
								<div>
									<h6>{strings.click_and_collect}</h6>
									<p className="description">{strings.pickup_delivery_summary}</p>
								</div>
							</div>
						</label>
						{cartList.data ? (
								<div className="cart_address_dtl">
									<div className="address_left_side">
										<div className="address-dtl">
											{comman.shippingType === 'pickup' ? (
												pickupStore.data && pickupStore.data.length > 0 ? (
													<select onChange={handlePickupStoreChange} className="form-control" required>
														<option>{strings.select_pickup_address}</option>
														{pickupStore.data.map((data) => (
															<option
																key={data.id}
																value={data.id}
																selected={
																	cartList.data &&
																	cartList.data.pickup_address &&
																	cartList.data.pickup_address.id === data.id
																}
															>
																{data.name}
															</option>
														))}
													</select>
												) : cartList.data.pickup_address ? (
													<span>{cartList.data.pickup_address.address}</span>
												) : (
													<span>{strings.pickup_strore_not_found}</span>
												)
											) : null}
										</div>
									</div>
								</div>
							) : null}
					</div>
				</div>
				</Modal.Body>
				<Modal.Footer>
					<Button
						className='btn_primary btn btn-primary'
						variant="primary"
						onClick={() => {
							setShowDeliveryModal(false);
							processToCheckout();
						}}
					>
						{strings.proceed}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}
export default connect(mapStateToProps, mapDispatchToProps)(Cart);