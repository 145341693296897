import React, { useEffect, useState } from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import LocalizedStrings from "react-localization";
import localization from "../localization";
import "../assets/css/blog.css";
import apiPath from "../constants/apiPath";
import Http from "../services/Http";

let strings = new LocalizedStrings(localization);

const Blog = () => {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);

    const [currentLang, setCurrentLang] = useState(
    localStorage.getItem("current_lang")
        ? localStorage.getItem("current_lang")
        : "en"
    );
    strings.setLanguage(currentLang);

    // Function to fetch blogs
    const fetchBlogs = async () => {
        try {
            let path = apiPath.blogs;
            let options = {
                method: "GET",
            };

            let result = await Http(path, options);
            if (result.status === 200) {
                setBlogs(result.data.data);
                setLoading(false);
            } else {
                console.error("Error fetching blogs:", result.message);
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching blogs:", error);
            setLoading(false);
        }
    };

    // Format date with text
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.toLocaleDateString("en-US", {
            weekday: "long",
            month: "long",
            day: "numeric",
            year: "numeric",
        })}`;
    };

    useEffect(() => {
        fetchBlogs();
    }, []);

    return (
        <>
            <Header />
            <main className="blog-page">
                <div className="blog-banner">
                    <h1 className="banner-title">{strings.blogs_title}</h1>
                    <p className="banner-description">{strings.blogs_summary}</p>
                </div>
                <div className="container blog-content">
                    {loading ? (
                        <div className="loading-spinner">Loading blogs...</div>
                    ) : blogs.length > 0 ? (
                        <div className="row">
                            {blogs.map((blog, index) => (
                                <div key={index} className="col-md-4 blog-card">
                                    <a href={`/blog/${blog.slug}`} className="blog-link">
                                        <div className="card">
                                            <img src={blog.image} alt={blog.title} className="card-img-top" />
                                            <div className="card-body">
                                                <h3 className="card-title blog-title">{blog.title}</h3>
                                                <p className="card-meta">{strings.published_on} {formatDate(blog.created_at)}</p>
                                                <p className="card-text text-truncate-3">{blog.summary}</p>
                                                <p className="read-more-btn">
                                                    {strings.read_more} →
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p>{strings.no_blogs}</p>
                    )}
                </div>
            </main>
            <Footer />
        </>
    );
};

export default Blog;
