import React, { useState, useEffect } from 'react';
import { Link, Navigate } from "react-router-dom";

import { useParams } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel3';
import ReactImageZoom from 'react-image-zoom';
import { Tab, Tabs, Dropdown, Button, Row, Col, Container } from 'react-bootstrap';

import Header from '../layout/Header';
import Footer from '../layout/Footer';
import CommanList from './Component/CommanList';

import apiPath from "../constants/apiPath";
import Http from "../services/Http";

import toast, { Toaster } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


import ImgPerfume1 from '../assets/img/perfume-1.jpg';
import ImgPerfume2 from '../assets/img/perfume-2.jpg';
import ImgPerfume3 from '../assets/img/perfume-3.jpg';
import ImgShare from '../assets/img/share.png';
import ImgmStar from '../assets/img/star.png';
import ImgmStar1 from '../assets/img/star-1.png';

import ImgGucciLogo from '../assets/img/gucci_logo.png';
import ImgCheckout1 from '../assets/img/checkout-1.png';
import ImgCheckout2 from '../assets/img/checkout-2.png';
import ImgCheckout3 from '../assets/img/checkout-3.png';
import ImgPn1 from '../assets/img/pn-1.png';
import ImgPn3 from '../assets/img/pn-3.png';

import ImgBestSeller from '../assets/img/best-seller.png';
import ImgmBestSeller from '../assets/img/m-best-seller.png';


import ImgmA1 from '../assets/img/a1.png';
import ImgmA2 from '../assets/img/a2.png';
import ImgmA3 from '../assets/img/a3.png';
import ImgmA4 from '../assets/img/a4.png';

import ImgmFacebook from '../assets/img/facebook.png';
import ImgmTwitter from '../assets/img/twitter.png';
import ImgWhatup from '../assets/img/whatup.png';
import noReview from '../assets/img/no-review.png';


import { userLogin } from "../actions/index";
import { connect } from "react-redux";
import moment from 'moment';
import ProgressBar from './Component/progress-bar.component';
import Currency from './Component/Currency';

import LocalizedStrings from "react-localization";
import localization from "../localization";
let strings = new LocalizedStrings(localization);


const mapStateToProps = state => {
   return { user: state.user };
};

const mapDispatchToProps = dispatch => {
   return {
      userLogin: user => dispatch(userLogin(user))
   };
}




const options = {
   margin: 0,
   nav: true,
   dots: false,
   loop: true,
   responsive: {
      0: {
         items: 1,
         margin: 7.5
      },
      420: {
         items: 1
      },
      577: {
         items: 1
      },
      992: {
         items: 1
      }
   }
};


const newAriveOption = {
   margin: 15,
   nav: true,
   dots: false,
   loop: false,
   responsive: {
      0: {
         items: 2,
         margin: 7.5
      },
      420: {
         items: 2.5,
         margin: 7.5
      },
      577: {
         items: 3,
         margin: 7.5
      },
      767.98: {
         items: 4
      },
      992: {
         items: 5
      },
      1366: {
         items: 7
      }
   }
}


const testData = [
   { bgcolor: "#6a1b9a", completed: 60 },
   { bgcolor: "#00695c", completed: 30 },
   { bgcolor: "#ef6c00", completed: 53 },
];

const ProductComboDetail = (Props) => {

   const params = useParams()


   const [user, setUser] = useState(Object.keys(Props.user).length > 0 ? Props.user : null)


   const [comman, setComman] = useState({
      zoomWidth: 500,
      img: ImgPerfume1,
      qty: 1,
      addCart: false,
      buyNow: false
   });
   const [productDetails, setProductDetails] = useState([]);
   const [productRating, setProductRating] = useState([]);
   const [ratingData, setratingData] = useState([]);
   const [attr, setAttr] = useState([]);
   const [currentLang, setCurrentLang] = useState(
      localStorage.getItem("current_lang")
         ? localStorage.getItem("current_lang")
         : "en"
   );
   strings.setLanguage(currentLang);


   const changeImag = (e, item) => {

      setComman({ ...comman, img: item.image });
   }


   const changeImag1 = (e, item) => {

      setComman({ ...comman, img: item });
   }

   const getProductDetails = async (id) => {
      let path = apiPath.comboDetail + '/' + id;
      var data = new FormData();
      let options = {
         method: 'GET',
      }
      let result = await Http(path, options);
      if (result.status == 200) {
         if (result.data.status) {


            await setProductDetails({ data: result.data.data })


            if (result.data.data.cart_item != 0) {
               setComman({ ...comman, qty: parseInt(result.data.data.cart_item) });
            }




            setComman({ ...comman, img: result.data.data.image });
            // setAttr(result.data.data.attribute_value_id);
            // getRatingData(productDetails)
         }
         else {
            toast.error(result.data.message);
         }
      }
   }
   const productShare = (e, id, title, type) => {
      if (type == 'facebook') {
         var url = window.location.href + 'product-detail/' + id
         window.open('https://facebook.com/sharer.php?u=' + url + '?id=' + id + '&quote=' + title);
      }
      if (type == 'twitter') {

         var url = window.location.href + 'product-detail/' + id
         window.open('https://twitter.com/share?url=' + url + '?id=' + id + '&text=' + title);
      }
      if (type == 'whatup') {
         var url = window.location.href + 'product-detail/' + id
         window.open('https://web.whatsapp.com/send?text=' + url);
      }
   }

   const chnageattributeValue = async (e, i) => {
      var attribute = attr.split(",");

      var old_attribute = attribute;

      attribute.map((item, index) => {
         if (i == index) {
            attribute[index] = parseInt(e.target.value)
         }
         else {
            attribute[index] = parseInt(item)
         }

      });


      let path = apiPath.ProductAvailability + '/' + params.id;
      var data = new FormData();
      data.append('attribute_value_id', attribute.toString());
      let options = {
         method: 'POST',
         data: data
      }
      let result = await Http(path, options);
      if (result.status == 200) {
         if (result.data.status) {
            setProductDetails({ data: result.data.data })
            setComman({ ...comman, img: result.data.data.image });
            setAttr(attribute.toString());
         }
         else {
            toast.error(result.data.message);
         }
      }
   }

   const getRatingReview = async (id) => {

      let path = apiPath.productRatingList;
      var data = new FormData();
      data.append('product_id', id)
      let options = {
         method: 'POST',
         data: data
      }
      let result = await Http(path, options);
      if (result.status == 200) {
         if (result.data.status) {

            setProductRating({ data: result.data.data })
            //   alert(productRating.data.length)
            //   setComman({ ...comman, img:result.data.data.image}); 
            //   setAttr(result.data.data.attribute_value_id);
         }
         else {
            // toast.error(result.data.message);
         }
      }
   }


   const addRemove = (e, type) => {
      var qty = 0;
      if (type == 'add') {

         qty = comman.qty + 1;
      }
      else {
         if (comman.qty > 1) {
            qty = comman.qty - 1;
         }
      }
      setComman({ ...comman, qty: qty });
   }

   const addtoCart = async (type) => {

      if (comman.qty > 0) {
         let path = apiPath.addToCombo;
         var data = new FormData();
         data.append('product_id', productDetails.data.id);
         data.append('qty', comman.qty);
         if (attr) {
            data.append('product_attribute_values_id', attr);
         }

         let options = {
            method: 'POST',
            data: data
         }
         let result = await Http(path, options);
         if (result.status == 200) {
            if (result.data.status) {
               if (type == 'BuyNow') {
                  setComman({ ...comman, buyNow: true });
               }
               else {
                  setComman({ ...comman, addCart: true });
               }
               toast.success(result.data.message);
            }
            else {
               toast.error(result.data.message);
            }
         }
      }
      else {
         toast.error(strings.at_last_one_qty);
      }
   }

   const getRatingData = async (data) => {

   }


   useEffect(() => {

      getProductDetails(params.id);
      getRatingReview(params.id)
      window.scrollTo(0, 0);
   }, [params]);

   return (
      <>

         {comman.buyNow ? <Navigate to="/cart" replace={true} /> : ''}
         <Header />
         <main className='prd_detail_space'>
            {productDetails.data ?
               <>
                  <section className="space-cls product_dtl_page">
                     <div className="container">
                        <div className="row">
                           <div className="col-md-12 col-lg-4">
                              <div className="product-slide-main">
                                 <div id='lens'></div>

                                 <div className='zoom_sld_main' style={{ 'position': "relative", 'zIndex': '1' }}>
                                    <ReactImageZoom {...comman} />
                                 </div>
                                 <div id='result'></div>


                                 <OwlCarousel className="owl-carousel  owl-theme" {...newAriveOption} key={productDetails.data.product_detail.length + 1} >

                                    <div className="item" key={0} onClick={(e) => { e.preventDefault(); e.stopPropagation(); changeImag1(e, productDetails.data.image); }}>
                                       <img className='slideshow-thumbnails active' src={productDetails.data.image} alt={productDetails.data.name} />
                                    </div>



                                    {productDetails.data.product_detail.length > 0 ?
                                       productDetails.data.product_detail.map((item, index) =>


                                          <div className="item" key={index + 1}
                                             onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                changeImag(e, item);
                                             }}
                                          >
                                             <img className='slideshow-thumbnails'
                                                src={item.image} alt={item.name} />
                                          </div>
                                       ) : ''}

                                 </OwlCarousel>
                              </div>
                           </div>
                           <div className="col-md-12 col-lg-8">
                              <div className="prd-dtl-cont">
                                 <div className="prd-title">
                                    <h2>{productDetails.data ? productDetails.data.name : ''}</h2>
                                    <div className="share-option">
                                       <Dropdown>
                                          <Dropdown.Toggle id="dropdown-custom-components" className='shareBtn'>
                                             <img src={ImgShare} alt="Share" />
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu >
                                             <Dropdown.Item eventKey="1" onClick={(e) => productShare(e, productDetails.data ? productDetails.data.id : 0, productDetails.data ? productDetails.data.name : '', 'facebook')}>
                                                <img alt='Facebook' src={ImgmFacebook} /> {strings.facebook}
                                             </Dropdown.Item>
                                             <hr />
                                             <Dropdown.Item eventKey="2" onClick={(e) => productShare(e, productDetails.data ? productDetails.data.id : 0, productDetails.data ? productDetails.data.name : '', 'twitter')}>
                                                <img alt='Twitter' src={ImgmTwitter} /> {strings.twitter}
                                             </Dropdown.Item>
                                             <hr />
                                             <Dropdown.Item eventKey="3" onClick={(e) => productShare(e, productDetails.data ? productDetails.data.id : 0, productDetails.data ? productDetails.data.name : '', 'whatup')}>
                                                <img alt='Whatsapp' src={ImgWhatup} /> {strings.whatus}
                                             </Dropdown.Item>
                                          </Dropdown.Menu>
                                       </Dropdown>
                                       <a href="#">

                                       </a>
                                    </div>
                                 </div>
                                 <div className="prd-cont-sec">
                                    <div className="main-prd-left">
                                       <p dangerouslySetInnerHTML={{ __html: productDetails.data ? productDetails.data.description : '' }}></p>

                                       <div className="prd-price-wrap">
                                          <div className="arrival-price">
                                             <p><Currency data={productDetails.data.discount_price != 0 ? productDetails.data.discount_price : productDetails.data.total_price} /></p>
                                             {parseInt(productDetails.data.discount_price) == 0 ? '' : <p className="discount-price"><Currency data={productDetails.data.total_price} /> ({Math.round(((productDetails.data.total_price - productDetails.data.discount_price) * 100) / productDetails.data.total_price).toFixed(2)}% off)</p>}

                                          </div>

                                       </div>

                                       <div id="field1" className="quantity_sec">
                                          <h4>Qty</h4>
                                          <div className="q_btn_group">
                                             <button type="button" id="sub" className="sub" onClick={(e) => addRemove(e, 'sub')}>-</button>
                                             <input type="number" id="1" value={comman.qty} min="1" max="3" />
                                             <button type="button" id="add" className="add" onClick={(e) => addRemove(e, 'add')}>+</button>
                                          </div>
                                       </div>
                                       <div className="add-btn-grp d-flex mt-3">
                                          {productDetails.data.cart_item < 1 ?
                                             comman.addCart ?
                                                <Button className="btn btn_primary me-2" onClick={() => addtoCart('BuyNow')} >{strings.go_to_cart}</Button>
                                                :
                                                <Button type="button" onClick={() => addtoCart('addCart')} className="btn btn_primary me-2">{strings.add_to_bag}</Button>
                                             :
                                             <Button className="btn btn_primary me-2" onClick={() => addtoCart('BuyNow')} >{strings.go_to_cart}</Button>
                                          }


                                          <Button href="#" onClick={() => addtoCart('BuyNow')} className="btn btn_primary">{strings.buyNow}</Button>
                                       </div>
                                    </div>


                                 </div>
                                 <div className="checkout-delivery-wrap mt-3">
                                    <div className="checkout-col">
                                       <div className="checkout-box">
                                          <span className="c-icon">
                                             <img src={ImgCheckout1} alt="Authenticate" />
                                          </span>
                                          <span>100% {strings.authenticate}</span>
                                       </div>
                                    </div>

                                    <div className="checkout-col">
                                       <div className="checkout-box">
                                          <span className="c-icon">
                                             <img src={ImgCheckout3} alt="Free shipping" />
                                          </span>
                                          <span>{strings.free_shipping}</span>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="prd-inner-bg mt-3">
                                    <p>{strings.place_order_befoure}</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>
                  <section className='combo_prd_sec space-cls'>
                     <div className="combo_prd wishlist_item">
                        <h5></h5>
                        <Container>
                           <Row>
                              {productDetails.data.product_detail.map((item, index) =>
                                 <Col xs="6" sm="6" md="4" lg="3" xl="3" className='col_5'>
                                    <div className="arrival-prd">
                                       <div className="arrival-prd-img">
                                          <img src={item.image} alt={item.name} />
                                       </div>
                                       <div className="arrival-cont">
                                          <div className="ratting-wrap">
                                             <span className="ratting-icon">
                                                <img src={ImgmStar} alt='Star' />
                                             </span>
                                             <span className="rate-dtl">{item.rating}</span>
                                          </div>
                                          <div className="arrival-title">
                                             <h3>{item.name}</h3>
                                             <Link to={item.brands ? "/product/brand/" + item.brands.slug : '#'} style={{ textDecoration: 'none'}}>
                                                <p>{item.brands ? item.brands.name : ''}</p>
                                             </Link>
                                          </div>
                                          <div className="arrival-price">
                                             <p><Currency data={item.discount_price != 0 ? item.discount_price : item.original_price} /></p>
                                             {parseInt(item.discount_price) == 0 ? '' : <p className="discount-price"><Currency data={item.original_price} /> ({Math.round(((item.original_price - item.discount_price) * 100) / item.original_price).toFixed(2)}% {strings.off})</p>}
                                          </div>
                                       </div>
                                    </div>
                                    <span>+</span>
                                 </Col>
                              )}

                           </Row>
                        </Container>
                     </div>
                  </section>
               </>
               : <Skeleton height={50} count={20} />}
            <CommanList />

         </main>
         <Footer />
      </>
   )
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductComboDetail);