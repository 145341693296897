import OwlCarousel from 'react-owl-carousel3';
import { Link } from "react-router-dom";
import ImgPrd2 from '../../assets/img/prd-2.png';
const Flower = (Props) =>{
    return (
        <>
           	   
				<OwlCarousel className="owl-carousel  owl-theme" {...Props.options}  >
                    {Props.data.map((item, index) =>
                     item.banner_type=='video' ?                     
                         <video width="100%" height={487} controls>
                                <source src={item.file} type="video/mp4"/>
                                <source src={item.file} type="video/ogg"/>
                                <source src={item.file} type="video/webm"/>
                                </video> :<div className="item">
	                    <div className="flower-bg">
	                      <div className="flower-img">
	                        <Link to="#">
	                          <img src={item.image} alt={item.image}/>
	                        </Link>
	                      </div>
	                    </div>
	                  </div>
                    )}
					</OwlCarousel> 
	      		
       </>

    )
}
export default Flower;