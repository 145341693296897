export default {
    getAmazingDeals: "Get amazing deals with the perfume smell!",
    appDownload: "App Download",
    help: "Help",
    english: 'English',
    arabic: "Arabic",
    home: "Home",
    beg: "Bag",
    wishlist: "Wishlist",
    account: "Account",
    myaccount: "My Account",
    myWallet: "My Points",
    myOrder: "My Orders",
    myWishlist: "My Wishlist",
    myCards: "My Cards",
    notifications: "Notifications",
    logout: "Logout",
    brands: "Brands",
    giftCard: "Gift Card",
    downloadOurApp: "Download Our App",
    termsCondations: "Terms & Condations",
    privacyPolicy: "Privacy Policy",
    copyRight: "The Perfume Smell © 2025. All rights reserved.",
    getDiscounthere: "You can get discount here. Use ",
    codeAndGet: " code and get  ",
    offDiscount: " off.",
    genuineProducts: "Genuine Products",
    secureShopping: "Secure Shopping",
    EasytoReturn: "Easy to Return",
    by: "by",
    customers: "Customers",
    thanksForThis: "Thanks for this amazing kit. All individual products are way too expensive to try.",
    perterJohn: "Perter John",
    thePerfumeSmall: "The Perfume Smell",
    whoAreWe: "Who are we?",
    faqs: "Faq's",
    paymentMode: "Payment Mode",
    ReturnRefund: "Return & Refund Policy",
    shippingDelivery: "Shipping & Delivery",
    quickLink: "Quick Links",
    offers: "Offers",
    contactUs: "Contact Us",
    top_categories: "Top Categories",
    man: "Men's",
    woman: "Women's",
    unisex: "Unisex",
    subscribeOutNewsLetter: "Subscribe Our Newsletter",
    submit: "Submit",
    releatedProduct: "Related Products",
    mostPickedProduct: "Most Picked Products",
    brandRelateProduct: "Related Brand Product",
    invalidEmailAddress: "Please enter valid email ",
    enterTheEmail: "Enter the email",
    phoneNumber: "Phone Number",
    emailAddress: "Email Address",
    Address: "Address",
    enterTheFirstName: 'Enter the first name',
    enterTheLastName: 'Enter the last name',
    NewsTicker: "News Ticker",
    are_you_sure_logout: 'Are you sure you want to logout?',
    filter: "Filter",
    parttern_does_match: 'only contain letters and minimum length is 3',
    Invalid_email: 'Invalid Email address',
    enter_email: 'Enter the email',
    enter_message: 'Please enter the message',
    mode_of_payment: "Mode of Payment",
    total_price: "Total Price",
    cancel: "Cancel",
    rebook: "Order Again?",
    rateus: 'Rate us',
    off: "Off",
    add_to_bag: "Add to Bag",
    enter_otp: "Please enter a OTP",
    verification_code: "Verification Code",
    which_one_send: "Which One Send to ",
    number: "Number",
    enter_mobile_number: "Enter Mobile Number",
    sign_in: "Sign In",
    sign_up: "Sign Up",
    already_account: "If you already have an account?",
    new_account: "Create Your Account ?",
    forget_password: "Forget Password",
    special_offer: "Sign in to grab special offers, extra reward points & more benefits!",
    continue_with_phone: "Continue with Email",
    continue_with_google: "Continue with Google",
    are_you_sure_clear_cart: 'Are you sure you want to clear cart?',
    at_last_one_qty: "At last one qty is needed",
    clearBag: "Clear Bag",
    delivery: "Delivery",
    pickup: "Pickup",
    add_your_delivery: 'Please add your delivery address',
    pickup_strore_not_found: "Pickup Store not found",
    change: "Change",
    login_or_register_first: "Get TPS points on every purchase when you log in or register. T&C apply",
    no_item_found: 'No Item Found',
    price: "Price",
    AddOns: "AddOns",
    discount: "Discount",
    shipping_fee: "Shipping Fee",
    tax: "Tax",
    giftWrapping: "Gift Wrapping",
    grand_total: "Grand Total",
    proceed: "Proceed",
    giftWrappingAddon: "Gift Wrapping Addon",
    authenticate: "Authenticate",
    on_time_delivery: "On Time Delivery",
    payment_amount: "Payment Amount",
    cod: "Cash On Delivery",
    online: "Online",
    knet: "Knet",
    Wallet: "Wallet",
    add_wallet: "Add Wallet",
    discount_code: "Discount Code",
    remove: "Remove",
    apply: "Apply",
    available_loyalty_point: "Available TPS Point",
    point_applicable: "TPS Point Applicable from Price",
    new_available_loyalty_point: "You can redeem your 30% points from your purchase",
    loyalty_point: "TPS Point",
    checkout: "Place Order",
    top_brands: "Top Brands",
    top_tranding: "Top Trending Products",
    under: "Under",
    Promocode: "Promocode",
    flowers: "Flowers",
    new_arrival: "New Arrival",
    best_saller: "Best Seller Fragrance",
    you_can_takes: "You Can Take Maximum Discount Here",
    sale_you_can_miss: "Sale You Can't Miss",
    fragrance: "Fragrance",
    vintage_collection: "Vintage Collection",
    explore_cotalogue: "Explore Catalogue",
    catalogue_product: "Catalogue Products",
    page_not_found: "Page not found!",
    we_could_not_find: "We couldn't find the page you were looking for",
    back_to_home_page: "Back to home page",
    clear_all: "Clear All",
    mark_all_as_read: "Mark All As Read",
    all_orders: "All Orders",
    new_orders: "New Orders",
    completed_orders: "Completed Orders",
    cancel_order: "Cancelled Orders",
    review: "Review",
    orderDetail: "Order Detail",
    combo: "Combo",
    order_price_details: "Order Price Detail",
    order_status: "Order Status",
    pending: "Pending",
    being_prepared: "In Process",
    on_the_way: "On the way",
    Delivered: "Delivered",
    download_receipt: "Download Receipt",
    track_your_order: "Track Your Order",
    short_by: "Sort By",
    category: "Category",
    rating: "Rating",
    start45: "4 Stars & Above 5",
    start34: "3 Stars & Above 4",
    start23: "2 Stars & Above 3",
    start12: "1 Star & Above 2",
    start01: "0 Star & Above 1",
    perfume_accords: "Perfume Accords",
    facebook: "Facebook",
    twitter: "Twitter",
    whatus: "what's up",
    buyNow: "Buy Now",
    free_shipping: "Free Shipping",
    place_order_befoure: "Place order before 4:30pm & get express delivery same day.",
    sold: "Sold",
    left: "Left",
    out_of_stock: "Out of Stock",
    sold_out: "Sold Out",
    discontinue: "Discontinue",
    go_to_cart: "Go To Cart",
    notifyMe: "Notify Me",
    SKU: "SKU",
    barcode: "Barcode",
    brand: "Brand",
    perfume_for: "Product For",
    perfume_type: "Product Type",
    perfume_notes: "Perfume Notes",
    customers_reviews: "Customer Reviews",
    mobile_number: "Mobile Number",
    WhatsappNumber: "Whatsapp Number",
    edit_profile: "Edit Profile",
    my_address: "My Addresses",
    add_address: "Add Address",
    edit: "Edit",
    delete: "Delete",
    thankyou: "Thank You !",
    your_order: "Your Order",
    success_place_with_us: "Successfully Placed With Us",
    back_to_home: "Back to Home",
    my_wallet: "My Wallet",
    wallet_amount: "Wallet Amount",
    enter_amount: "Enter the amount",
    wallet_banefits: "Wallet Benefits",
    quick_refunds: "Quick Refunds",
    one_tab_payment: "One Tap Payment",
    special_discount: "Special Discounts",
    transaction: "Transaction",
    addtoCart: "Add to cart",
    select_Preference: "Select Preference",
    select_country: "Select Country",
    choose_lang: "Choose Language",
    continue: "Continue",
    OTP: "OTP",
    dontReceive_otp: "Didn't receive OTP code?",
    shipping_address: "Shipping Address",
    plz_enter_area: "Please enter the area",
    plz_enter_flat_number: "Please enter the House no.",
    plz_enter_landmark: "Please enter the avenue",
    plz_enter_address: "Please enter the address",
    plz_enter_city: "Please enter the city",
    plz_select_country: "Please select country",
    plz_select_state: "Please select state",
    plz_enter_state: "Please enter the state",
    area: 'Area',
    flat_number: "House No./Flat No./ Building",
    landmark: "Avenue",
    city: "City",
    state: "State",
    country: "Country",
    address: "Address",
    pincode: "Pincode",
    plz_enter_pincode: "Please enter the pincode",
    addressType: "Address Type",
    office: "Office",
    other: "other",
    edit_address: "Edit Address",
    plz_upload_file: "Please upload a file",
    max_file_size: "Max file size is 150 kb",
    edit_profile_pic: "Edit Profile Pic",
    name: "Name",
    plz_enter_name: "Please enter the name",
    plz_enter_valid_email: "Please enter a valid email",
    plz_enter_email: "Please enter the email",
    plz_select_gender: "Please select gender.",
    female: "Female",
    male: "Male",
    qty: "Qty",
    are_you_sure_clear_notification: "Are you sure you want to delete notification?",
    are_you_sure_clear_all_notification: "Are you sure you want to delete all notification?",
    are_you_sure_logout: 'Are you sure you want to logout?',

    sale: "Sale",
    some_think_went_wrong_in_online_transacton: "Online payment Failed. Please try again later.",
    failure: "Failure",
    plase_select_rating_also: 'Please give a rating form your order.',

    search_for_product: "Search for products",
    recent_search: "Recent Search",
    notes: 'Notes',
    product_information: "Product Information",
    description: "Description",
    select_pickup_address: "Select Pickup Address",
    price_detail: "Price Detail",
    offers: "Offers",
    Home: "Home",
    Office: "Office",
    Other: "Other",
    Pending: "Pending",
    Accepted: 'Accepted',
    Prepare: 'Preparing',
    Deliver: 'Deliver',
    Cancel: 'Cancel',
    Complete: 'Complete',
    are_you_sure_you_want_to_cancel_order: "Are you sure you want to cancel order",
    'On The Way': 'On The Way',
    Block: "Block",
    plz_enter_block: "Please Enter Block",
    street: "Street",
    plz_enter_street: "Please Enter Street",
    perfumeType: 'Perfume Type',
    note: "Note",
    plz_enter_note: "Please enter note",
    Visa: "Visa/Card",
    plz_enter_phone: "Please enter mobile phone",
    view_all: "View all",
    nice_collection: 'Niche Collection',
    bundle: 'Bundle Offers',
    products: 'Products',
    issuePaymentTabby: "Sorry, Tabby is unable to approve this purchase. Please use an alternative payment method for your order",
    plz_enter_order_code: 'Enter Your Order Code',
    tabby_payment: 'Pay in 4. No interest, no fees.',
    upload_rating_image: 'Upload Rating Image',
    issueTabbyCoupon: "Tabby cannot be used with coupon code",
    issueTabbyPoint: "Tabby cannot be used with points",
    issueTabbyMaxPrice: "Tabby is limited to 250 kd only per cart amount",
    attributes: "Attributes",
    orderRatings: "Order Ratings",
    ratings: "Ratings",
    verifiedBuyer: "Verified Buyers",
    customerPhotos: "Customer Photos",
    modalTitleIsReview: "Enjoyed your latest purchase?",
    modalContentIsReview: "Share your experience and leave a review to help us serve you better!",
    selectOption: "Select Options",
    moveToCart: "Move to cart",
    sample_text_instruction: "You can order a sample to try out this perfume",
    orderSample: "Order Sample",
    sample: "Sample",
    trySamples: "New Samples",
    product_not_exist: "Product not exist",
    color: "Color",
    coming_soon: "Coming soon",
    blog: "Blog",
    blogs_title: "Our Blog",
    blogs_summary: "Insights, tips, and stories from the world of technology and beyond.",
    read_more: "Read More",
    no_blogs: "No blogs available.",
    published_on: "Published on",
    back_to_blogs: "Back to Blogs",
    you_may_also_like: "You may also like",
    share_this_post: "Share this post",
    top_notes: "Top Notes",
    middle_notes: "Middle Notes",
    base_notes: "Base Notes",
    inspired_by: "Inspired By",
    home_delivery: "Home Delivery",
    click_and_collect: "Click and Collect",
    home_delivery_summary: "Free delivery on all orders above KWD 15",
    pickup_delivery_summary: "Avaiable to Collect in 2-3 days",
    delivery_method: "Delivery Method",
    pickItemSize: "PICK THE ITEM SIZE",
    pickThisSize: "PICK THIS SIZE"
};
