import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import Home from './view/Home';
import Profile from "./view/Profile";
import EditProfile from "./view/EditProfile";
import MyCart from "./view/MyCart";
import Notification from "./view/Notification";
import Offer from "./view/Offer";
import Order from "./view/Order";
import OrderDetail from "./view/OrderDetail";
import Product from "./view/Product";
import FlashSale from "./view/FlashSale";
import Sale from "./view/Sale";
import Blogs from "./view/Blogs";
import BlogDetail from "./view/BlogDetail";

import ProductDetail from "./view/ProductDetail";
import ProductComboDetail from "./view/ProductComboDetail";


import Thankyou from "./view/Thankyou";
import Failure from "./view/Failure";


import Wallet from "./view/Wallet";
import Wishlist from "./view/Wishlist";
import Cart from "./view/Cart";
import Checkout from "./view/Checkout";
import Address from "./view/Address";
import AddAddress from "./view/AddAddress";
import EditAddress from "./view/EditAddress";
import Brand from "./view/Brand";
import Layout from "./view/Layout";
import About from "./view/About";
import PaymentMode from "./view/PaymentMode";
import TermsAndCondition from "./view/TermsAndCondition";
import PrivacyPolicy from "./view/PrivacyPolicy";

import Testimonials from "./view/Testimonials";
import Contact from "./view/Contact";
import Faq from "./view/Faq";
import CancelReturn from "./view/CancelReturn";
import ShippingDeliver from "./view/ShippingDeliver";
import GiftCard from "./view/GiftCard";



import NotFound from "./view/NotFound";
import NewsTicker from "./view/NewsTicker";
import NewsTickerDetail from "./view/NewtickerDetail";
import Help from "./view/help";
import ListingPageContainer from "./view/ListingPageContainer";
import Download from "./view/Download";


import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Checkout1 from "./view/Checkout1";
import SearchPage from "./view/SearchPage";
import TrackingOrder from "./view/TrackingOrder";
import ScrollContext from './ScrollContext';
import Sample from "./view/Sample";

function App() {
  const [isScrolled, setIsScrolled] = useState(false);

  return (
    <BrowserRouter>
      <ScrollContext.Provider value={{ isScrolled, setIsScrolled }}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="checkout1" element={<Checkout1 />} />
            <Route index element={<Home />} />
            <Route path="profile" element={<Profile />} />
            <Route path="blogs" element={<Blogs />} />
            <Route path="blog/:slug" element={<BlogDetail />} />

            <Route path="edit-profile" element={<EditProfile />} />
            <Route path="my-card" element={<MyCart />} />
            <Route path="notification" element={<Notification />} />
            <Route path="offer" element={<Offer />} />
            <Route path="order" element={<Order />} />
            <Route path="order-detail/:id" element={<OrderDetail />} />
            <Route path="product/:type?/:typeId?" element={<Product />} />
            <Route path="flash-sale/" element={<FlashSale />} />
            <Route path="sale/" element={<Sale />} />
            <Route path="try-samples/" element={<Sample />} />
            <Route path="product-detail/:id?/:type?" element={<ProductDetail />} />

            <Route path="product-combo-detail/:id?" element={<ProductComboDetail />} />

            <Route path="brand" element={<Brand />} />
            <Route path="wallet" element={<Wallet />} />
            <Route path="wishlist" element={<Wishlist />} />
            <Route path="cart" element={<Cart />} />
            <Route path="checkout/:type" element={<Checkout />} />
            <Route path="address" element={<Address />} />
            <Route path="add-address" element={<AddAddress />} />
            <Route path="edit-address/:id?" element={<EditAddress />} />
            <Route path="thankyou/:orderId" element={<Thankyou />} />
            <Route path="failure/:type" element={<Failure />} />
            <Route path="about-us" element={<About />} />
            <Route path="payment-mode" element={<PaymentMode />} />
            <Route path="help" element={<Help />} />

            <Route path="terms-and-condition" element={<TermsAndCondition />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />


            <Route path="testimonials" element={<Testimonials />} />
            <Route path="contact-us" element={<Contact />} />
            <Route path="faq" element={<Faq />} />
            <Route path="cancel-return" element={<CancelReturn />} />
            <Route path="shipping-deliver" element={<ShippingDeliver />} />
            <Route path="news-ticker" element={<NewsTicker />} />
            <Route path="news-ticker-detail/:id" element={<NewsTickerDetail />} />
            <Route path="test" element={<ListingPageContainer />} />
            <Route path="download" element={<Download />} />
            <Route path="search/:text" element={<SearchPage />} />
            {/*<Route path="gift-card" element={<GiftCard />} />*/}
            {/* <Route path="my-card" element={<MyCart />} /> */}
            <Route path="tracking-order" element={<TrackingOrder />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </ScrollContext.Provider>
    </BrowserRouter>
  );
}

export default App;
