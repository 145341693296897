import React, { useState, useEffect } from 'react';
import { Link, Navigate } from "react-router-dom";
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import LeftSitebar from '../layout/LeftSitebar';
import ImgCartPrd from '../assets/img/cart_prd.png';
import ImgDeliveryTruck from '../assets/img/delivery-truck.png';
import ImgWatch from '../assets/img/watch.png';
import { useParams } from 'react-router-dom';
import apiPath from "../constants/apiPath";
import Http from "../services/Http";
import toast, { Toaster } from 'react-hot-toast';
import DayPipe from '../constants/DayPipe';
import UtCToLocal from '../constants/UtCToLocal';

import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Button, Form } from 'react-bootstrap';
import { TextInput, ValidationForm, FileInput } from "react-bootstrap4-form-validation";
import Modal from 'react-bootstrap/Modal';
import Imgcross from '../assets/img/cross.png';
import { Rating } from 'react-simple-star-rating';
import { userLogin } from "../actions/index";
import { connect } from "react-redux";
import Currency from './Component/Currency';
import NoImage from '../assets/img/noimage.png';


import LocalizedStrings from "react-localization";
import localization from "../localization";
let strings = new LocalizedStrings(localization);

const mapStateToProps = (state) => {
  return { user: state.user };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userLogin: (user) => dispatch(userLogin(user)),
  };
};

const OrderDetail = (Props) => {
  const formRef = React.createRef();
  const params = useParams()
  const [orderDetail, setOrderDetail] = useState();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [comman, setComman] = useStateWithCallbackLazy({
    type: "all",
    product_id: 0,
    reOrder: false,
    productType: "Product"
  });

  const [common, setCommon] = useState({
    image: ''
  });
  const [file, setFile] = useState();

  const handleChangeImg = (e) => {
    setCommon({ ...common, [e.target.name]: e.target.files[0] })
    setFile(URL.createObjectURL(e.target.files[0]));
  }


  const setModalIsOpenToTrue = (e, id, type) => {
    setComman({ ...comman, product_id: id, productType: type })
    setCommon({ ...common, image: null })
    setFile(null);
    // alert(id)
    setModalIsOpen(true)
  }
  const [rating, setRating] = useState(0)
  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false)
  }

  const [user, setUser] = useState(
    Object.keys(Props.user).length > 0 ? Props.user : null
  );

  const [currentLang, setCurrentLang] = useState(
    localStorage.getItem("current_lang")
        ? localStorage.getItem("current_lang")
        : "en"
  );
  strings.setLanguage(currentLang);

  const registerSubmit = async (e, formData, inputs) => {
    e.preventDefault();

    if (rating > 0) {
      let path = apiPath.rateProduct;

      var data = new FormData();
      if (common.image) {
        data.append('image', common.image);
      }
      data.append('rating', rating ? rating : 1);
      data.append('review', formData.review);
      data.append('product_id', comman ? comman.product_id : 0);
      data.append('type', comman ? comman.productType : "Product");

      let options = {
        method: 'POST',
        data: data
      }
      let result = await Http(path, options);
      if (result.status === 200) {
        if (result.data.status) {

          toast.success(result.data.message);
          setModalIsOpen(false);
          getOrderDetail(params.id)
        } else {
          toast.error(result.data.message);
        }
      } else {
        if(result.statusText != null) {
          toast.error(result.statusText);
        }
      }
    }
    else {
      toast.error(strings.plase_select_rating_also);
    }
  }
  const registerErrorSubmit = (e, formData, inputs) => {
    e.preventDefault();
    if (rating > 0.5) {

    }
  }
  const handleRating = (rate) => {
    // alert(rate)
    setRating(rate)

    // other logic
  }
  // Optinal callback functions
  const onPointerEnter = () => console.log('Enter')
  const onPointerLeave = () => console.log('Leave')
  const onPointerMove = (value, index) => console.log(value, index)
  const handleChange = (value, data, event, formattedValue) => {

  }
  const getOrderDetail = async (id) => {

    let path = apiPath.orderDetails;
    var data = new FormData();
    data.append("order_id", id)
    let options = {
      method: 'Post',
      data: data
    }
    let result = await Http(path, options);
    if (result.status == 200) {
      if (result.data.status) {
        await setOrderDetail({ data: result.data.data })
      }
      else {
        toast.error(result.data.message);
      }
    }
  }
  const reOrder = async (e, value) => {
    let path = apiPath.reorder;
    var data = new FormData();
    data.append('order_id', value);
    let options = {
      method: 'POST',
      //				headers: { 'language': this.state.language },
      data: data
    }

    let result = await Http(path, options);
    if (result.status === 200) {
      if (result.data) {
        let resultJson = result.data;
        if (resultJson.status) {
          toast.success(result.data.message);
          setComman({ ...comman, reOrder: true })
          //          getOrderDetail(params.id)
        }
      } else {


        toast.error(result.data.message);
      }
    } else {
      if(result.statusText != null) {
        toast.error(result.statusText);
      }
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    getOrderDetail(params.id)
  }, [params]);
  return (
    <>


      {comman.reOrder ? <Navigate to={'/cart'} data={comman} replace={true} /> : ''}

      {user ? (
        user.details.temp_user == 1 ? (
          <Navigate to="/" replace={true} />
        ) : (
          ""
        )
      ) : (
        <Navigate to="/" replace={true} />
      )}

      <Header />
      <main>
        <section className="space-cls my-account-page">
          <div className="container">
            <div className="my-account-inner">
              <LeftSitebar />
              <div className="myaccount_r">
                <h2 className="heading-type2">{strings.orderDetail}</h2>
                <div className="order-page-dtl">
                  <div className="order-list">
                    <div className="order_wrap">
                      <div className="order-head">
                        <div className="order_dtl">
                          <div className="order-left">
                            <div className="order_dtl_l">
                              <h5 className="order-id">{orderDetail ? orderDetail.data ? orderDetail.data.random_order_id : '' : ''}</h5>
                              <p className="date_time"><UtCToLocal date={orderDetail ? orderDetail.data ? orderDetail.data.created_at : '' : ''}/></p>
                              <p className="order_payment">{strings.mode_of_payment}: {orderDetail ? orderDetail.data ? orderDetail.data.payment_type : '' : ''}</p>
                              <p> <small>{strings.total_price}  : {orderDetail ? orderDetail.data ? orderDetail.data.currency : '' : ''} {orderDetail ? orderDetail.data ? (orderDetail.data.total_amount*orderDetail.data.currency_value).toFixed(2) : '' : ''}</small></p>
                            </div>
                          </div>
                          <div className="order_right">
                            <div className="order-status">
                              <span className="order-btn complete">{orderDetail ? orderDetail.data ? orderDetail.data.order_status : '' : ''}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {orderDetail ? orderDetail.data ? orderDetail.data.items.length > 0 ? orderDetail.data.items.map((element) =>

                        <div key={element.id} className="order-body">
                          <div className="cart-product">
                            <div className="cart-prd-img">
                              <img src={element.product_image} alt={element.product_name} />
                            </div>
                            <div className="card-prd-cont">
                              <Link to={element.type == 'Product' ? '/product-detail/' + element.products.slug : '/product-combo-detail/' + element.products.slug} target="_blank">

                                <div className="arrival-title">
                                  <h3>
                                    {element.product_name}
                                    {element.products.is_sample && <span className="sample-tag">{strings.sample}</span>}
                                  </h3>
                                  {element ? element.type == 'Product' ?
                                    <p>{element ? element.products ? element.products.brands ? element.products.brands.name : '' : '' : ''}</p> : <p>{strings.combo}</p> : ""}
                                </div>
                                <div className="prd-quantity">
                                  <span>{strings.qty} x {element ? element.qty : ''}</span>
                                </div>
                              </Link>
                            </div>
                            <div className="cart_prd_right">
                              <div className="item_total_price">
                                <h3>
                                  {orderDetail ? orderDetail.data ? orderDetail.data.currency : '' : ''} {element ? (element.amount*orderDetail.data.currency_value).toFixed(2) : 0} 
</h3>
                                {orderDetail ? orderDetail.data ? element.is_rate == 0 ?
                                  <div className="order-actions d-flex">
                                    <Button onClick={(e) => setModalIsOpenToTrue(e, element.product_id, element.type)} className="btn btn_primary ">{strings.rateus}</Button>

                                  </div> : null : null : null
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null : null : null}
                    </div>
                  </div>
                  <div className="order-address-page">
                    <div className="order-address-bg d-block">
                      <h2 className="heading-type2">{strings.order_price_details}</h2>
                      <div className="cart_price">
                        <ul>
                          <li>
                            <span className="price_left">{strings.price}</span>
                            <span className="price_right">
                            {orderDetail ? orderDetail.data ? orderDetail.data.currency : '' : ''}  {orderDetail ? orderDetail.data ? (orderDetail.data.totalAmount*orderDetail.data.currency_value).toFixed(2) : 0 : 0}
                            </span>
                          </li>

                          {orderDetail ? orderDetail.data && orderDetail.data.gift_total_price ?
                            <li>
                              <span className="price_left">{strings.AddOns}</span>
                              <span className="price_right">{orderDetail ? orderDetail.data ? orderDetail.data.currency : '' : ''} {orderDetail.data ? (orderDetail.data.gift_total_price*orderDetail.data.currency_value).toFixed(2) : 0}</span>
                            </li> : '' : ""}

                          {orderDetail ? orderDetail.data && orderDetail.data.discount_code ?
                            <li>
                              <span className="price_left">{strings.discount}</span>
                              <span className="price_right">- {orderDetail ? orderDetail.data ? orderDetail.data.currency : '' : ''} {(orderDetail.data.discount_amount*orderDetail.data.currency_value).toFixed(2)} ({orderDetail.data.discount_code})</span>
                            </li> : '' : ''}

                          {orderDetail ? orderDetail.data && orderDetail.data.shipping_charges ?
                            <li>
                              <span className="price_left">{strings.shipping_fee}</span>

                              <span className="price_right">{orderDetail ? orderDetail.data ? orderDetail.data.currency : '' : ''} {(orderDetail.data.shipping_charges*orderDetail.data.currency_value).toFixed(2)} </span>
                            </li> : '' : ''}

                          {orderDetail ? orderDetail.data && orderDetail.data.tax_amount ?
                            <li>
                              <span className="price_left">{strings.tax}</span>
                              <span className="price_right">{orderDetail ? orderDetail.data ? orderDetail.data.currency : '' : ''} {(orderDetail.data.tax_amount*orderDetail.data.currency_value).toFixed(2)}</span>
                            </li> : '' : ''}


                          {orderDetail ? orderDetail.data ? orderDetail.data.loyalty_points != 0 ?
                            <li>
                              <span className="price_left">{strings.loyalty_point}</span>

                              <span className="price_right">-{orderDetail ? orderDetail.data ? orderDetail.data.currency : '' : ''} {orderDetail.data ? (orderDetail.data.loyalty_points*orderDetail.data.currency_value).toFixed(2) : 0}</span>
                            </li> : '' : '' : ''}


                        </ul>
                        <div className="g-total">
                          <ul>
                            <li>
                              <span className="price_left">{strings.grand_total}</span>
                              <span className="price_right">{orderDetail ? orderDetail.data ? orderDetail.data.currency : '' : ''} {orderDetail ? orderDetail.data ? (orderDetail.data.total_amount*orderDetail.data.currency_value).toFixed(2)  : 0 : 0}</span>


                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="order-address-page">
                    <div className="order-address-bg">
                      <div className="address-ic">
                        <img src={ImgDeliveryTruck} alt="Delivery Truck" />
                      </div>
                      <div className="order-address-dtl">
                        <h4>{strings.delivery} {strings.address}</h4>
                        <p>{orderDetail ? orderDetail.data ? orderDetail.data.address ? orderDetail.data.address.address : "" : '' : ''}</p>
                      </div>
                    </div>
                  </div>
                  <div className="order-status">
                    <h2 className="heading-type2">{strings.order_status}</h2>
                    <div className="tracking-main-class">


                      {/* {orderDetail ? orderDetail.data ? orderDetail.data.order_status_history.length > 0 ? orderDetail.data.order_status_history.map((doc) => */}
                      {/* <div className="completed track-dtl d-flex"> */}
                      <div className={orderDetail ? orderDetail.data ? orderDetail.data.order_status_history.length >= 1 ? "completed track-dtl d-flex" : "track-dtl d-flex" : "track-dtl d-flex" : "track-dtl d-flex"} >
                        <div className="timing">
                        </div>
                        <div className="store-dtl">
                          <h4 className="font24">{strings.pending}</h4>
                          <p className='message_cls'>{orderDetail ? orderDetail.data ? orderDetail.data.order_status_history.length == 1 ? orderDetail.data.order_status_history[0].message : '' : '' : ''}</p>
                          {orderDetail ? orderDetail.data ? orderDetail.data.order_status_history.length >= 1 ?
                            <p className="font18m">
                              <span className="watch_ic">
                                <img src={ImgWatch} alt="Watch" />
                              </span>
                              <span className="time-cls"><UtCToLocal date={orderDetail.data.order_status_history[0].updated_at} /></span></p>
                            : '' : '' : ''}
                        </div>
                      </div>
                      {/* ) : null : null : null} */}
                      {/* <div className="activeted track-dtl d-flex"> */}
                      <div className={orderDetail ? orderDetail.data ? orderDetail.data.order_status_history.length >= 2 ? "completed track-dtl d-flex" : orderDetail.data.order_status_history.length >= 1 ? "activeted track-dtl d-flex" : "track-dtl d-flex" : "track-dtl d-flex" : "track-dtl d-flex"} >
                        <div className="timing">
                        </div>
                        <div className="store-dtl">
                          <h4 className="font24">{strings.being_prepared}</h4>
                          <p className='message_cls'>{orderDetail ? orderDetail.data ? orderDetail.data.order_status_history.length >= 2 ? orderDetail.data.order_status_history[1].message : '' : '' : ''}</p>
                          {orderDetail ? orderDetail.data ? orderDetail.data.order_status_history.length >= 2 ?
                            <p className="font18m"><span className="watch_ic">
                              <img src={ImgWatch} alt="Watch" />
                            </span> <span className="time-cls"><UtCToLocal date={orderDetail.data.order_status_history[1].updated_at} /></span></p>
                            : '' : '' : ''}
                        </div>
                      </div>
                      <div className={orderDetail ? orderDetail.data ? orderDetail.data.order_status_history.length >= 3 ? "completed track-dtl d-flex" : orderDetail.data.order_status_history.length >= 2 ? "activeted track-dtl d-flex" : "track-dtl d-flex" : "track-dtl d-flex" : "track-dtl d-flex"} >
                        {/* <div className="track-dtl d-flex"> */}
                        <div className="timing">
                        </div>
                        <div className="store-dtl">
                          <h4 className="font24">{strings.on_the_way}</h4>
                          <p className='message_cls'>{orderDetail ? orderDetail.data ? orderDetail.data.order_status_history.length >= 3 ? orderDetail.data.order_status_history[2].message : '' : '' : ''}</p>
                          {orderDetail ? orderDetail.data ? orderDetail.data.order_status_history.length >= 3 ?
                            <p className="font18m"><span className="watch_ic">
                              <img src={ImgWatch} alt="Watch" />
                            </span> <span className="time-cls"><UtCToLocal date={orderDetail.data.order_status_history[2].updated_at} /></span></p>
                            : '' : '' : ''}
                        </div>
                      </div>
                      <div className={orderDetail ? orderDetail.data ? orderDetail.data.order_status_history.length >= 4 ? "completed track-dtl d-flex" : orderDetail.data.order_status_history.length >= 3 ? "activeted track-dtl d-flex" : "track-dtl d-flex" : "track-dtl d-flex" : "track-dtl d-flex"} >
                        {/* <div className="track-dtl d-flex"> */}
                        <div className="timing">
                        </div>
                        <div className="store-dtl">
                          <h4 className="font24">{strings.Delivered}</h4>
                          <p className='message_cls'>{orderDetail ? orderDetail.data ? orderDetail.data.order_status_history.length >= 4 ? orderDetail.data.order_status_history[3].message : '' : '' : ''}</p>
                          {orderDetail ? orderDetail.data ? orderDetail.data.order_status_history.length >= 4 ?
                            <p className="font18m"><span className="watch_ic">
                              <img src={ImgWatch} alt="Watch" />
                            </span> <span className="time-cls"><UtCToLocal date={orderDetail.data.order_status_history[3].updated_at} /></span></p>
                            : '' : '' : ''}
                        </div>
                      </div>
                    </div>
                    <div className="order_download d-flex">
                    {orderDetail ? orderDetail.data ?
                      <a href={orderDetail ? orderDetail.data ? orderDetail.data.pdf : null : null} className="btn btn_primary">{strings.download_receipt}</a>
                      :"":''}
                      {/*<div className="locate-wrap">
                        <a href="#">
                          <span className="location-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24.75" height="24.75" viewBox="0 0 24.75 24.75">
                              <path id="Path_15416" data-name="Path 15416" d="M14.375,9.875a4.5,4.5,0,1,0,4.5,4.5A4.5,4.5,0,0,0,14.375,9.875ZM24.432,13.25A10.119,10.119,0,0,0,15.5,4.318V2H13.25V4.318A10.119,10.119,0,0,0,4.317,13.25H2V15.5H4.317a10.119,10.119,0,0,0,8.932,8.933V26.75H15.5V24.433A10.119,10.119,0,0,0,24.432,15.5H26.75V13.25Zm-10.057,9a7.875,7.875,0,1,1,7.875-7.875,7.876,7.876,0,0,1-7.875,7.875Z" transform="translate(-2 -2)" />
                            </svg>
                          </span>
                          {strings.track_your_order}

                        </a>
                          </div>*/}
                      <div className="order-actions d-flex ms-auto">
                        <a onClick={(e) => reOrder(e, params.id)} className="btn btn_primary">{strings.rebook}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
      {
        modalIsOpen ? <>
          <Modal show={true} animation={true} size="lg" backdrop="static" keyboard={false} centered >

            <Modal.Header>
              <h5 className="modal-title" id="exampleModalLabe111l">Review</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <img src={Imgcross} alt='Close' onClick={setModalIsOpenToFalse} />
              </button>
            </Modal.Header>
            <Modal.Body>
              {/* <p className="signin-cont">Sign in to grab special offers, extra reward points &amp; more benefits!</p> */}
              <div className="continue-btn review_modal">
                <ValidationForm onSubmit={registerSubmit} onErrorSubmit={registerErrorSubmit} ref={formRef} >

                  <div className="popup-cont">
                    <Form.Group className="form-group" controlId="form-group">
                      <Form.Label>Rate</Form.Label>
                      <Rating
                        transition
                        allowFraction
                        showTooltip
                        onClick={handleRating}
                        onPointerEnter={onPointerEnter}
                        onPointerLeave={onPointerLeave}
                        onPointerMove={onPointerMove}
                        tooltipDefaultText='Rate'
                      /* Available Props */
                      />
                      {/* <TextInput type="number" name="rating" id="rate" required
                        placeholder="Enter the rate"
                        errorMessage="Please enter the rate"
                        onChange={handleChange}
                      /> */}
                    </Form.Group>
                    <Form.Group className="form-group" controlId="form-group">
                      <Form.Label>Review</Form.Label>
                      <TextInput name="review" id="review"
                        placeholder="Enter the review"
                        onChange={handleChange}
                      />
                      <div className='rating_image'>
                        <div className="rating_img_upload profile_img">
                          <img src={file ? file : NoImage} alt="Rating Image" />
                        </div>
                        <div className="upload_cls">
                          <div className="file_input">
                            <FileInput name="image" id="image"
                              fileType={["jpg", "png", "gif", "webp"]}
                              maxFileSize="5120 kb"
                              errorMessage={
                                {
                                  required: strings.plz_upload_file,
                                  fileType: "Only jpg,png,gif and webp files is allowed",
                                  maxFileSize: strings.max_file_size
                                }
                              }
                              onChange={handleChangeImg}
                            />
                            <span for="image">{strings.upload_rating_image}</span>
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                    <div className="login-btn">
                      <Button type="submit" className="btn btn_primary">Submit</Button>
                    </div>
                    {/*<div className="already_cls">
                                <p>If you already have an account? <Link to="#" onClick={openLogin}>Sign In</Link></p>
                                                            </div>*/}
                  </div>
                </ValidationForm>

              </div>
            </Modal.Body>

          </Modal></> : ""
      }
    </>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);