import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import "../assets/css/blog.css";
import apiPath from "../constants/apiPath";
import Http from "../services/Http";
import LocalizedStrings from "react-localization";
import localization from "../localization";
import { Helmet } from 'react-helmet';

let strings = new LocalizedStrings(localization);

const BlogDetail = () => {
    const { slug } = useParams();
    const [blog, setBlog] = useState(null);
    const [relatedBlogs, setRelatedBlogs] = useState([]);
    const [loading, setLoading] = useState(true);

    const [currentLang, setCurrentLang] = useState(
    localStorage.getItem("current_lang")
        ? localStorage.getItem("current_lang")
        : "en"
    );
    strings.setLanguage(currentLang);

    // Fetch blog details
    const fetchBlogDetail = async () => {
        try {
            const result = await Http(`${apiPath.blogDetail}/${slug}`, { method: "GET" });
            if (result.status === 200) {
                setBlog(result.data.data);
            } else {
                console.error("Error fetching blog detail:", result.message);
            }
        } catch (error) {
            console.error("Error fetching blog detail:", error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch related blogs
    const fetchRelatedBlogs = async () => {
        try {
            const result = await Http(`${apiPath.relatedBlogs}/${slug}`, { method: "GET" });
            if (result.status === 200) {
                setRelatedBlogs(result.data.data);
            } else {
                console.error("Error fetching related blogs:", result.message);
            }
        } catch (error) {
            console.error("Error fetching related blogs:", error);
        }
    };

    // Format date with text
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.toLocaleDateString("en-US", {
            weekday: "long",
            month: "long",
            day: "numeric",
            year: "numeric",
        })}`;
    };

    // Generate shareable URLs
    const generateShareUrl = (platform, slug) => {
        const baseUrl = window.location.origin;
        const fullUrl = `${baseUrl}/blog/${slug}`;
        switch (platform) {
            case "facebook":
                return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(fullUrl)}`;
            case "twitter":
                return `https://twitter.com/intent/tweet?url=${encodeURIComponent(fullUrl)}`;
            case "linkedin":
                return `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(fullUrl)}`;
            default:
                return "#";
        }
    };

    useEffect(() => {
        fetchBlogDetail();
        fetchRelatedBlogs();
    }, [slug]);

    return (
        <>
            <Header />
            <main className="blog-detail-page">
                {loading ? (
                    <div className="loading-container">
                        <div className="loading-spinner">Loading...</div>
                    </div>
                ) : blog ? (
                    <>
                        <Helmet>
                            <title>{blog.meta_title || "The perfumesmell"}</title>
                            <meta name="description" content={blog.meta_description || "The perfumesmell"} />
                            <meta name="keywords" content={blog.meta_keywords || "The perfumesmell"} />
                        </Helmet>
                        <div className="container blog-detail-content">
                            <h1 className="blog-title text-center">{blog.title}</h1>
                            <div className="blog-meta text-center">
                                <p>{strings.published_on} {formatDate(blog.created_at)}</p>
                            </div>
                            <div className="blog-body">
                                <p dangerouslySetInnerHTML={{ __html: blog.content }}></p>
                            </div>
    
                            <hr />
    
                            <div className="blog-social-share">
                                <p>{strings.share_this_post}:</p>
                                <div className="social-icons">
                                    <a href={generateShareUrl("facebook", blog.slug)} target="_blank" rel="noopener noreferrer">
                                        <i className="fab fa-facebook"></i> Facebook
                                    </a>
                                    <a href={generateShareUrl("twitter", blog.slug)} target="_blank" rel="noopener noreferrer">
                                        <i className="fab fa-twitter"></i> Twitter
                                    </a>
                                    <a href={generateShareUrl("linkedin", blog.slug)} target="_blank" rel="noopener noreferrer">
                                        <i className="fab fa-linkedin"></i> LinkedIn
                                    </a>
                                </div>
                            </div>
    
                            <div className="back-to-blogs">
                                <Link to="/blogs" className="btn btn-secondary">
                                    {strings.back_to_blogs}
                                </Link>
                            </div>
    
                            {relatedBlogs.length > 0 && (
                                <div className="related-blogs">
                                    <h2>{strings.you_may_also_like}</h2>
                                    <div className="row">
                                        {relatedBlogs.map((related, index) => (
                                            <div key={index} className="col-md-4 blog-card">
                                                <a href={`/blog/${related.slug}`} className="blog-link">
                                                    <div className="card">
                                                        <img src={related.image} alt={related.title} className="card-img-top" />
                                                        <div className="card-body">
                                                            <h3 className="card-title blog-title">{related.title}</h3>
                                                            <p className="card-meta">{strings.published_on} {formatDate(related.created_at)}</p>
                                                            <p className="card-text text-truncate-3">{related.summary}</p>
                                                            <p className="read-more-btn">
                                                                {strings.read_more} →
                                                            </p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                ) : (
                    <div className="error-container text-center m-5">
                        <div className="error-message">
                            <h2>Oops!</h2>
                            <p>We couldn't find the blog you're looking for.</p>
                            <Link to="/blogs" className="btn btn-secondary">
                                {strings.back_to_blogs}
                            </Link>
                        </div>
                    </div>
                )}
            </main>
            <Footer />
        </>
    );
};

export default BlogDetail;
