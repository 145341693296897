import React, { useEffect, useState } from 'react';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import noOrder from '../../assets/img/order-not-found.png';
import 'react-tabs/style/react-tabs.css';
import { Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Currency from './Currency';
import UtCToLocal from '../../constants/UtCToLocal';

import DayPipe from '../../constants/DayPipe';


import LocalizedStrings from 'react-localization';
import localizations from "../../localization";
let strings = new LocalizedStrings(localizations);


const OrderListing = (Props) => {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [comman, setComman] = useStateWithCallbackLazy({
    page: 1,
    last_page: 0,
    type: "all",
    product_id: 0
  });


  const [currentLang, setCurrentLang] = useState(
    localStorage.getItem("current_lang")
      ? localStorage.getItem("current_lang")
      : "en"
  );
  strings.setLanguage(currentLang);


  /*
 const setModalIsOpenToTrue = (e, id) => {
      setComman({ ...comman, product_id: id })
      // alert(id)
      setModalIsOpen(true)
  }

  const setModalIsOpenToFalse = () => {
      setModalIsOpen(false)
  }
  */
  const [order, setOrder] = useState([]);

  useEffect(() => {
    setOrder({ data: Props.data });
  }, [Props]);

  return (
    <>
      <div className="order-list">
        {order.data ? order.data.length > 0
          ? order.data.map((element, index) =>
            <div className={"order_wrap order_wrap" + element.id} >
              <div className="order-head">
                <div className="order_dtl">
                  <div className="order-left">
                    <div className="order_dtl_l">
                      <Link to={'/order-detail/' + element.id}>
                        <h5 className="order-id"><a>{element.random_order_id}</a></h5>
                        <p className="date_time"><UtCToLocal date={element.created_at} /></p>
                        <p className="order_payment">{strings.mode_of_payment}: {element.payment_type}</p>
                        <p> <small>{strings.total_price} : {element.currency} {(element.total_amount * element.currency_value).toFixed(2)}</small></p>
                      </Link>
                    </div>
                  </div>
                  <div className="order_right">
                    <div className="order-status">
                      <span className={"order-btn " + Props.type}>{strings[element.order_status]}</span>
                      <div className='d-flex'>
                        {element.order_status == 'Pending' ?
                          <a onClick={(e) => Props.cancelOrder(e, element.id, Props.type)} className="btn btn_primary me-1">{strings.cancel}</a>
                          :
                          <a onClick={(e) => Props.reOrder(e, element.id, Props.type)} className="btn btn_primary ms-auto">{strings.rebook}</a>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {element.orderDetail ? element.orderDetail.length > 0 ? element.orderDetail.map((value) =>
                <div className="order-body">
                  <div className="cart-product">
                    <div className="cart-prd-img">
                      <img src={value.product_image} alt={value.product_name} />
                    </div>
                    <div className="card-prd-cont">
                    {value.type == 'Product' ? (
                      value.product ? (
                        <Link to={'/product-detail/' + value.product_id} target="_blank">
                          <div className="arrival-title">
                            <h3>
                              {value.product_name}
                              {value.product.is_sample && <span className="sample-tag">{strings.sample}</span>}
                            </h3>
                            <p>{value.product.brands.name}</p>
                          </div>
                        </Link>
                      ) : (
                        <div className="arrival-title">
                          <h3>{strings.product_not_exist}</h3>
                        </div>
                      )
                    ) : (
                      value.product ? (
                        <Link to={'/product-combo-detail/' + value.product.id} target="_blank">
                          <div className="arrival-title">
                            <h3>{value.product_name}</h3>
                            <p>Combo</p>
                          </div>
                        </Link>
                      ) : (
                        <div className="arrival-title">
                          <h3>{strings.product_not_exist}</h3>
                        </div>
                      )
                    )}
                      <div className="prd-quantity">
                        <span>{strings.qty} x {value.qty}</span>
                      </div>
                    </div>
                    <div className="cart_prd_right">
                      <div className="item_total_price">
                        <h3>{element.currency} {(value.amount * element.currency_value ?? 1).toFixed(2)}</h3>
                        {/* {element.order_status == "Complete" ? value.is_rate == 0 ? */}
                        {value.is_rate == 0 ?
                          <div className="order-actions">
                            <Button onClick={(e) => Props.setModalIsOpenToTrue(e, value.product_id, value.type)} className="btn btn_primary">{strings.rateus}</Button>
                          </div> : null
                        }
                        {/* <div className="order-actions">
                                                <Button className="btn btn_primary">Rate Us</Button>
                                              </div> */}
                      </div>
                    </div>
                  </div>

                </div>
              ) : null : null}
            </div>
          )
          : <section className="space-cls no_image">
            <div className="container">
              <h3>
                <center>
                  <img src={noOrder} alt="Order not found" />
                </center>
              </h3>
            </div>
          </section> : <section className="space-cls no_image">
          <div className="container">
            <h3>
              <center>
                <img src={noOrder} alt="Order not found" />
              </center>
            </h3>
          </div>
        </section>}
      </div>
    </>

  )
}
export default OrderListing;