export default {
    getAmazingDeals: "احصل على صفقات مذهلة مع رائحة العطور!",
    appDownload: "تنزيل التطبيق",
    help: "يساعد",
    english: 'إنجليزي',
    arabic: "عربي",
    home: "الرئيسية",
    beg: "إفترض جدلا",
    wishlist: "قائمة الرغبات",
    account: "حساب",
    myaccount: "حسابي",
    myWallet: "محفظتى",
    myOrder: "طلباتي",
    myWishlist: "قائمة امنياتي",
    myCards: "بطاقاتي",
    notifications: "إشعارات",
    logout: "تسجيل خروج",
    brands: "العلامات التجارية",
    giftCard: "كرت هدية ",
    downloadOurApp: "قم بتنزيل تطبيقنا",
    termsCondations: "الشروط والأحكام",
    privacyPolicy: "سياسة الخصوصية",
    copyRight: "رائحة العطور © 2025. جميع الحقوق محفوظة.",
    getDiscounthere: "يمكنك الحصول على خصم هنا. يستخدم",
    codeAndGet: " كود والحصول عليها ",
    offDiscount: " عن.",
    genuineProducts: "منتجات أصلية",
    secureShopping: "تسوق آمن",
    EasytoReturn: "من السهل العودة",
    by: "بواسطة",
    customers: "عملاء",
    thanksForThis: "شكرا على هذه المجموعة المدهشة. جميع المنتجات الفردية مكلفة للغاية لتجربتها.",
    perterJohn: "Perter John",
    thePerfumeSmall: "رائحة العطور",
    whoAreWe: "من نحن؟",
    faqs: "الأسئلة الشائعة",
    paymentMode: "طريقة الدفع",
    ReturnRefund: "سياسة العودة واسترداد الأموال",
    shippingDelivery: "الشحن و التسليم",
    quickLink: "روابط سريعة",
    offers: "عروض",
    contactUs: "اتصل بنا",
    top_categories: "الفئات العليا",
    man: "رجل",
    woman: "للنساء",
    unisex: "للجنسين",
    subscribeOutNewsLetter: "اشترك في رسائلنا الإخبارية",
    submit: "يُقدِّم",
    releatedProduct: "منتجات ذات صله",
    mostPickedProduct: "معظم المنتجات التي تم اختيارها",
    invalidEmailAddress: "عنوان البريد الإلكتروني غير صالح",
    enterTheEmail: "أدخل البريد الإلكتروني",
    phoneNumber: "رقم التليفون",
    emailAddress: "عنوان البريد الإلكتروني",
    Address: "عنوان",
    enterTheFirstName: 'أدخل الاسم الأول',
    enterTheLastName: 'أدخل الاسم الأخير',
    NewsTicker: "شريط الأخبار",
    are_you_sure_logout: 'هل أنت متأكد أنك تريد تسجيل الخروج؟',
    filter: "منقي",
    parttern_does_match: 'لا يتطابق Partern',
    Invalid_email: 'عنوان البريد الإلكتروني غير صالح',
    enter_email: 'أدخل البريد الإلكتروني',
    enter_message: 'الرجاء إدخال الرسالة',
    mode_of_payment: "طريقة الدفع",
    total_price: "السعر الكلي",
    cancel: "يلغي",
    rebook: "إعادة الكتاب",
    rateus: 'قيمنا',
    off: "عن",
    add_to_bag: "أضف الى الحقيبة",
    enter_otp: "الرجاء إدخال OTP",
    verification_code: "رمز التحقق",
    which_one_send: "أي واحد يرسل إليه  ",
    number: "رقم",
    enter_mobile_number: "أدخل رقم الهاتف المحمول",
    sign_in: "تسجيل الدخول  ",
    sign_up: "Sign Up",
    already_account: "If you already have an account?",
    new_account: "Create Your Account ?",
    forget_password: "Forget Password",
    special_offer: "تسجيل الدخول للاستيلاء على عروض خاصة نقاط مكافأة إضافية & amp ؛ المزيد من الفوائد!",
    continue_with_phone: "تابع الهاتف",
    continue_with_google: "تابع مع Google",
    are_you_sure_clear_cart: 'هل أنت متأكد أنك تريد مسح عربة؟',
    at_last_one_qty: "في النهاية ، هناك حاجة إلى كمية واحدة",
    clearBag: "حقيبة واضحة",
    delivery: "توصيل",
    pickup: "يلتقط",
    add_your_delivery: 'الرجاء إضافة عنوان التسليم الخاص بك',
    pickup_strore_not_found: "لم يتم العثور على متجر التقاط",
    change: "يتغير",
    login_or_register_first: "تسجيل الدخول أو التسجيل أولاً قبل استخدام التسوق معنا",
    no_item_found: 'لم يتم العثور على عنصر',
    price: "سعر",
    AddOns: "الإضافات",
    discount: "تخفيض",
    shipping_fee: "رسوم الشحن",
    tax: "ضريبة",
    giftWrapping: "تغليف الهدايا",
    grand_total: "المجموع الإجمالي",
    proceed: "يتابع",
    giftWrappingAddon: "هدايا تلبية الملحق",
    authenticate: "المصادقة",

    on_time_delivery: "التسليم في الوقت المحدد",
    payment_amount: "مبلغ الدفع",
    cod: "الدفع عند الاستلام",
    online: "متصل",
    Wallet: "محفظة",
    add_wallet: "أضف محفظة",
    discount_code: "الرقم التسلسلي للخصم",
    remove: "يزيل",
    apply: "يتقدم",
    available_loyalty_point: "نقطة الولاء المتاحة",
    point_applicable: "نقطة الولاء قابلة للتطبيق من السعر",
    loyalty_point: "نقطة الولاء",
    checkout: "الدفع",
    top_brands: "ارقى الماركات",
    top_tranding: "أعلى المنتجات الجريمة",
    under: "تحت",
    Promocode: "رمز ترويجي",
    flowers: "زهور",
    new_arrival: "قادم جديد",
    best_saller: "أفضل العطور بائع",
    you_can_takes: "يمكنك الحصول على أقصى خصم هنا",
    sale_you_can_miss: "بيع لا يمكنك تفويته",
    fragrance: "العطر",
    vintage_collection: "مجموعة خمر",
    explore_cotalogue: "استكشاف الكتالوج",
    catalogue_product: "منتجات الكتالوج",
    page_not_found: "الصفحة غير موجودة!",
    we_could_not_find: "لم نتمكن من العثور على الصفحة التي كنت تبحث عنها",
    back_to_home_page: "العودة إلى الصفحة الرئيسية",
    clear_all: "امسح الكل",
    mark_all_as_read: "اشر عليها بانها قرات",
    all_orders: "جميع الطلبات",
    new_orders: "طلبات جديدة",
    completed_orders: "أوامر مكتملة",
    cancel_order: "أوامر إلغاء",
    review: "مراجعة",
    orderDetail: "تفاصيل الطلب",
    combo: "التحرير والسرد",
    order_price_details: "تفاصيل سعر الطلب",
    order_status: "حالة الطلب",
    pending: "قيد الانتظار",
    being_prepared: "تحت المعالجة",
    on_the_way: "علي الطريق",
    Delivered: "تم التوصيل",
    download_receipt: "تنزيل إيصال",
    track_your_order: "أتبع طلبك",
    short_by: "ترتيب حسب",
    category: "فئة",
    rating: "تقييم",
    start45: "4 نجوم وفوق 5",
    start34: "3 نجوم وما فوق 4",
    start23: "2 نجوم وفوق 3",
    start12: "1 نجمة وفوق 2",
    start01: "0 نجمة وفوق 1",
    perfume_accords: "اتفاقات العطور",
    facebook: "فيسبوك",
    twitter: "تويتر",
    whatus: "ما أخبارك",
    buyNow: "اشتري الآن",
    free_shipping: "شحن مجاني",
    place_order_befoure: "ضع طلب قبل الساعة 4:30 مساءً واحصل على تسليم صريح في نفس اليوم.",
    sold: "مُباع",
    left: "غادر",
    out_of_stock: "إنتهى من المخزن",
    sold_out: "نفذ",
    discontinue: "توقف",
    go_to_cart: "اذهب إلى العربة",
    notifyMe: "أشعرني، أعلمني، بلغني",
    SKU: "SKU",
    barcode: "الباركود",
    brand: "ماركة",
    perfume_for: "عطر ل",
    perfume_type: "نوع العطور",
    perfume_notes: "ملاحظات العطور",
    customers_reviews: "آراء العملاء",
    mobile_number: "رقم الهاتف المحمول",
    WhatsappNumber: "رقم WhatsApp",
    edit_profile: "تعديل الملف الشخصي",
    my_address: "عناويني",
    add_address: "اضف عنوان",
    edit: "يحرر",
    delete: "يمسح",
    thankyou: "شكرًا لك !",
    your_order: "طلبك",
    success_place_with_us: "وضعت بنجاح معنا",
    back_to_home: "العودة إلى المنزل",
    my_wallet: "محفظتى",
    wallet_amount: "كمية المحفظة",
    enter_amount: "أدخل المبلغ",
    wallet_banefits: "فوائد المحفظة",
    quick_refunds: "استرداد سريع",
    one_tab_payment: "دفعة نقرة واحدة",
    special_discount: "خصومات خاصة",
    transaction: "عملية",
    addtoCart: "أضف إلى السلة",
    select_Preference: "حدد التفضيل",
    select_country: "حدد الدولة",
    choose_lang: "اختر اللغة",
    continue: "يكمل",
    OTP: "OTP",
    dontReceive_otp: "لا تتلقى رمز OTP؟",
    shipping_address: "عنوان الشحن",
    plz_enter_area: "الرجاء إدخال المنطقة",
    plz_enter_flat_number: "الرجاء إدخال الرقم المسطح",
    plz_enter_landmark: "الرجاء إدخال المعلم",
    plz_enter_address: "الرجاء إدخال العنوان",
    plz_enter_city: "الرجاء إدخال المدينة",
    plz_select_country: "الرجاء تحديد البلد",
    plz_select_state: "الرجاء تحديد الحالة",
    plz_enter_state: "الرجاء إدخال الدولة",
    area: 'منطقة',
    flat_number: "عدد مسطح  ",
    landmark: "معلم معروف",
    city: "مدينة",
    state: "ولاية",
    country: "دولة",
    address: "عنوان",
    pincode: "الرمز السري",
    plz_enter_pincode: "الرجاء إدخال pincode",
    addressType: "نوع العنوان",
    office: "مكتب",
    other: "آخر",
    edit_address: "تعديل العنوان",
    plz_upload_file: "يرجى تحميل ملف",
    max_file_size: "حجم الملف الأقصى 150 كيلو بايت",
    edit_profile_pic: "تحرير الموافقة المسبقة عن علم ملف تعريف",
    name: "اسم",
    plz_enter_name: "الرجاء إدخال الاسم",
    plz_enter_valid_email: "يرجى إدخال البريد الإلكتروني الصحيح",
    plz_enter_email: "الرجاء إدخال البريد الإلكتروني",
    plz_select_gender: "يرجى تحديد الجنس.",
    female: "أنثى",
    male: "ذكر",
    qty: "الكمية",
    are_you_sure_clear_notification: "هل أنت متأكد أنك تريد حذف الإخطار؟",
    are_you_sure_clear_all_notification: "هل أنت متأكد أنك تريد حذف كل الإشعارات؟",
    are_you_sure_logout: 'هل أنت متأكد أنك تريد تسجيل الخروج؟',
    sale: "الخصومات",
    some_think_went_wrong_in_online_transacton: "لقد أخطأ بعض التفكير في الدفع عبر الإنترنت. الرجاء معاودة المحاولة في وقت لاحق.",
    failure: "فشل",
    plase_select_rating_also: 'يرجى إعطاء تصنيف من طلبك.',
    search_for_product: "ابحث عن المنتجات ، الفئات ، العلامات التجارية ، SKU في رائحة العطور",
    recent_search: "البحث الأخير",
    notes: 'ملحوظات',
    product_information: "معلومات المنتج",
    description: "وصف",
    select_pickup_address: "حدد عنوان الاستلام",
    price_detail: "تفاصيل السعر",
    offers: "عروض",
    Home: "بيت",
    Office: "مكتب",
    Other: "آخر",
    Pending: "قيد الانتظار",
    Accepted: 'قبلت',
    Prepare: 'يحضر',
    Deliver: 'يسلم',
    Cancel: 'يلغي',
    Complete: 'مكتمل',
    are_you_sure_you_want_to_cancel_order: "Are you sure you want to cancel order",
    'On The Way': 'علي الطريق',
    perfume_type: "Perfume Type",
    Block: "Block",
    plz_enter_block: "Please Enter Block",
    street: "street",
    plz_enter_street: "Please Enter Street",
    brandRelateProduct: "Related Brand Product",
    perfumeType: 'Perfume Type',
    note: "Note",
    plz_enter_note: "Please enter note",
    Visa: "Visa/Card",
    knet: "Knet",
    new_available_loyalty_point: "يمكنك استبدال 30٪ من نقاطك من مشترياتك",
    plz_enter_phone: "Please enter mobile phone",
    view_all: "مشاهدة الكل",
    nice_collection: 'Niche Collection',
    bundle: 'Bundle Offers',
    products: 'Products',
    issuePaymentTabby: 'يؤسفنا عدم قدرتنا على الموافقة على هذه العملية. يرجى استخدام طريقة دفع أخرى.',
    plz_enter_order_code: 'Enter Your Order Code',
    tabby_payment: 'قسّمها على 4. بدون أي فوائد، أو رسوم.',
    upload_rating_image: 'Upload Rating Image',
    issueTabbyCoupon: "Tabby cannot be used with coupon code",
    issueTabbyPoint: "Tabby cannot be used with points",
    issueTabbyMaxPrice: "Tabby is limited to 250 kd only per cart amount",
    attributes: "Attributes",
    orderRatings: "Order Ratings",
    ratings: "Ratings",
    verifiedBuyer: "Verified Buyers",
    customerPhotos: "Customer Photos",
    selectOption: "Select Options",
    modalTitleIsReview: "Enjoyed your latest purchase?",
    modalContentIsReview: "Share your experience and leave a review to help us serve you better!",
    moveToCart: "Move to cart",
    sample_text_instruction: "تقدر تطلب عينة علشان تجرب هالعطر.",
    orderSample: "اطلب العينة",
    sample: "العينة",
    trySamples: "العينة",
    product_not_exist: "Product not exist",
    color: "Color",
    coming_soon: "قريباً",
    blog: "Blog",
    blogs_title: "Our Blog",
    blogs_summary: "Insights, tips, and stories from the world of technology and beyond.",
    read_more: "Read More",
    no_blogs: "No blogs available.",
    top_notes: "Top Notes",
    middle_notes: "Middle Notes",
    base_notes: "Base Notes",
    inspired_by: "Inspired By",
    home_delivery: "Home Delivery",
    click_and_collect: "Click and Collect",
    home_delivery_summary: "Free delivery on all orders above KWD 15",
    pickup_delivery_summary: "Avaiable to Collect in 2-3 days",
    delivery_method: "Delivery Method",
};
