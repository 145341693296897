import React, { useState, useEffect } from 'react';
import { Link, Navigate } from "react-router-dom";
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import ImgCartPrd from '../assets/img/cart_prd.png';
import ImgDeliveryTruck from '../assets/img/delivery-truck.png';
import ImgWatch from '../assets/img/watch.png';
import { useParams } from 'react-router-dom';
import apiPath from "../constants/apiPath";
import Http from "../services/Http";
import toast, { Toaster } from 'react-hot-toast';
import UtCToLocal from '../constants/UtCToLocal';

import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Button, Form } from 'react-bootstrap';
import { TextInput, ValidationForm } from 'react-bootstrap4-form-validation';
import Imgcross from '../assets/img/cross.png';
import { userLogin } from "../actions/index";
import { connect } from "react-redux";


import LocalizedStrings from "react-localization";
import localization from "../localization";
let strings = new LocalizedStrings(localization);

const mapStateToProps = (state) => {
  return { user: state.user };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userLogin: (user) => dispatch(userLogin(user)),
  };
};

const TrackingOrder = (Props) => {
  const formRef = React.createRef();
  const params = useParams()
  const [orderDetail, setOrderDetail] = useState();
  const [searchValue, setSearchValue] = useState(false);
  const [comman, setComman] = useStateWithCallbackLazy({
    immediate: true,
	setFocusOnError: true,
  });

  const [user, setUser] = useState(
    Object.keys(Props.user).length > 0 ? Props.user : null
  );

  const [currentLang, setCurrentLang] = useState(
    localStorage.getItem("current_lang")
        ? localStorage.getItem("current_lang")
        : "en"
  );
  strings.setLanguage(currentLang);

  const getOrderDetail = async (e, formData, inputs) => {
    e.preventDefault();
    let path = apiPath.trackingOrder;

    let options = {
      method: 'POST',
      data: formData
    }
    let result = await Http(path, options);
    // console.log(result);
    if (result.status == 200) {
      if (result.data.status == 200) {
        await setOrderDetail({ data: result.data.data });
        setSearchValue(true);
      }
      else {
        toast.error(result.data.message);
        setSearchValue(false);
      }
    }
    else {
        toast.error(result.data.message);
        setSearchValue(false);
    }
  }
  
  return (
    <>
      <Header />
      <main>
        <section className="space-cls my-account-page">
          <div className="container">
            <div className="my-account-inner">
              <div className="myaccount_r">
                <h2 className="heading-type2">{strings.track_your_order}</h2>
                <div className='order-search-block'>
                    <ValidationForm onSubmit={getOrderDetail}
                        ref={formRef}
                        immediate={comman.immediate}
                        setFocusOnError={comman.setFocusOnError}
					>
                        <Form.Group className="form-group" controlId="form-group">
                            <Form.Label>Order Code</Form.Label>
                            <TextInput 
                                className="form-control"
                                name="order_code"
                                id="orderCode"
                                required
                                placeholder={strings.plz_enter_order_code}
                                errorMessage={strings.plz_enter_order_code}
                            />
                        </Form.Group>
                        <Button type="submit" className="btn btn_primary">{strings.track_your_order}</Button>
                    </ValidationForm>
                </div>
                {
                    !searchValue ? "" :
				    <>
                    <div className="order-page-dtl">
                    <div className="order-list">
                        <div className="order_wrap">
                        <div className="order-head">
                            <div className="order_dtl">
                            <div className="order-left">
                                <div className="order_dtl_l">
                                <h5 className="order-id">{orderDetail ? orderDetail.data ? orderDetail.data.random_order_id : '' : ''}</h5>
                                <p className="date_time"><UtCToLocal date={orderDetail ? orderDetail.data ? orderDetail.data.created_at : '' : ''}/></p>
                                <p className="order_payment">{strings.mode_of_payment}: {orderDetail ? orderDetail.data ? orderDetail.data.payment_type : '' : ''}</p>
                                <p> <small>{strings.total_price}  : {orderDetail ? orderDetail.data ? orderDetail.data.currency : '' : ''} {orderDetail ? orderDetail.data ? (orderDetail.data.total_amount*orderDetail.data.currency_value).toFixed(2) : '' : ''}</small></p>
                                </div>
                            </div>
                            <div className="order_right">
                                <div className="order-status">
                                <span className="order-btn complete">{orderDetail ? orderDetail.data ? orderDetail.data.order_status : '' : ''}</span>
                                </div>
                            </div>
                            </div>
                        </div>
                        {orderDetail ? orderDetail.data ? orderDetail.data.items.length > 0 ? orderDetail.data.items.map((element) =>

                            <div className="order-body">
                            <div className="cart-product">
                                <div className="cart-prd-img">
                                <img src={element.product_image} alt={element.product_name} />
                                </div>
                                <div className="card-prd-cont">
                                <Link to={element.type == 'Product' ? '/product-detail/' + element.products.slug : '/product-combo-detail/' + element.products.slug} target="_blank">

                                    <div className="arrival-title">
                                    <h3>{element.product_name}</h3>
                                    {element ? element.type == 'Product' ?
                                        <p>{element ? element.products ? element.products.brands ? element.products.brands.name : '' : '' : ''}</p> : <p>{strings.combo}</p> : ""}
                                    </div>
                                    <div className="prd-quantity">
                                    <span>{strings.qty} x {element ? element.qty : ''}</span>
                                    </div>
                                </Link>
                                </div>
                                <div className="cart_prd_right">
                                <div className="item_total_price">
                                    <h3>
                                    {orderDetail ? orderDetail.data ? orderDetail.data.currency : '' : ''} {element ? (element.amount*orderDetail.data.currency_value).toFixed(2) : 0} 
                                    </h3>
                                </div>
                                </div>
                            </div>
                            </div>
                        ) : null : null : null}
                        </div>
                    </div>
                    <div className="order-address-page">
                        <div className="order-address-bg d-block">
                        <h2 className="heading-type2">{strings.order_price_details}</h2>
                        <div className="cart_price">
                            <ul>
                            <li>
                                <span className="price_left">{strings.price}</span>
                                <span className="price_right">
                                {orderDetail ? orderDetail.data ? orderDetail.data.currency : '' : ''}  {orderDetail ? orderDetail.data ? (orderDetail.data.totalAmount*orderDetail.data.currency_value).toFixed(2) : 0 : 0}
                                </span>
                            </li>

                            {orderDetail ? orderDetail.data && orderDetail.data.gift_total_price ?
                                <li>
                                <span className="price_left">{strings.AddOns}</span>
                                <span className="price_right">{orderDetail ? orderDetail.data ? orderDetail.data.currency : '' : ''} {orderDetail.data ? (orderDetail.data.gift_total_price*orderDetail.data.currency_value).toFixed(2) : 0}</span>
                                </li> : '' : ""}

                            {orderDetail ? orderDetail.data && orderDetail.data.discount_code ?
                                <li>
                                <span className="price_left">{strings.discount}</span>
                                <span className="price_right">- {orderDetail ? orderDetail.data ? orderDetail.data.currency : '' : ''} {(orderDetail.data.discount_amount*orderDetail.data.currency_value).toFixed(2)} ({orderDetail.data.discount_code})</span>
                                </li> : '' : ''}

                            {orderDetail ? orderDetail.data && orderDetail.data.shipping_charges ?
                                <li>
                                <span className="price_left">{strings.shipping_fee}</span>

                                <span className="price_right">{orderDetail ? orderDetail.data ? orderDetail.data.currency : '' : ''} {(orderDetail.data.shipping_charges*orderDetail.data.currency_value).toFixed(2)} </span>
                                </li> : '' : ''}

                            {orderDetail ? orderDetail.data && orderDetail.data.tax_amount ?
                                <li>
                                <span className="price_left">{strings.tax}</span>
                                <span className="price_right">{orderDetail ? orderDetail.data ? orderDetail.data.currency : '' : ''} {(orderDetail.data.tax_amount*orderDetail.data.currency_value).toFixed(2)}</span>
                                </li> : '' : ''}


                            {orderDetail ? orderDetail.data ? orderDetail.data.loyalty_points != 0 ?
                                <li>
                                <span className="price_left">{strings.loyalty_point}</span>

                                <span className="price_right">-{orderDetail ? orderDetail.data ? orderDetail.data.currency : '' : ''} {orderDetail.data ? (orderDetail.data.loyalty_points*orderDetail.data.currency_value).toFixed(2) : 0}</span>
                                </li> : '' : '' : ''}


                            </ul>
                            <div className="g-total">
                            <ul>
                                <li>
                                <span className="price_left">{strings.grand_total}</span>
                                <span className="price_right">{orderDetail ? orderDetail.data ? orderDetail.data.currency : '' : ''} {orderDetail ? orderDetail.data ? (orderDetail.data.total_amount*orderDetail.data.currency_value).toFixed(2)  : 0 : 0}</span>


                                </li>
                            </ul>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="order-address-page">
                        <div className="order-address-bg">
                        <div className="address-ic">
                            <img src={ImgDeliveryTruck} alt="Delivery Truck" />
                        </div>
                        <div className="order-address-dtl">
                            <h4>{strings.delivery} {strings.address}</h4>
                            <p>{orderDetail ? orderDetail.data ? orderDetail.data.address ? orderDetail.data.address.address : "" : '' : ''}</p>
                        </div>
                        </div>
                    </div>
                    <div className="order-status">
                        <h2 className="heading-type2">{strings.order_status}</h2>
                        <div className="tracking-main-class">


                        {/* {orderDetail ? orderDetail.data ? orderDetail.data.order_status_history.length > 0 ? orderDetail.data.order_status_history.map((doc) => */}
                        {/* <div className="completed track-dtl d-flex"> */}
                        <div className={orderDetail ? orderDetail.data ? orderDetail.data.order_status_history.length >= 1 ? "completed track-dtl d-flex" : "track-dtl d-flex" : "track-dtl d-flex" : "track-dtl d-flex"} >
                            <div className="timing">
                            </div>
                            <div className="store-dtl">
                            <h4 className="font24">{strings.pending}</h4>
                            <p className='message_cls'>{orderDetail ? orderDetail.data ? orderDetail.data.order_status_history.length == 1 ? orderDetail.data.order_status_history[0].message : '' : '' : ''}</p>
                            {orderDetail ? orderDetail.data ? orderDetail.data.order_status_history.length >= 1 ?
                                <p className="font18m">
                                <span className="watch_ic">
                                    <img src={ImgWatch} alt="Watch" />
                                </span>
                                <span className="time-cls"><UtCToLocal date={orderDetail.data.order_status_history[0].updated_at} /></span></p>
                                : '' : '' : ''}
                            </div>
                        </div>
                        {/* ) : null : null : null} */}
                        {/* <div className="activeted track-dtl d-flex"> */}
                        <div className={orderDetail ? orderDetail.data ? orderDetail.data.order_status_history.length >= 2 ? "completed track-dtl d-flex" : orderDetail.data.order_status_history.length >= 1 ? "activeted track-dtl d-flex" : "track-dtl d-flex" : "track-dtl d-flex" : "track-dtl d-flex"} >
                            <div className="timing">
                            </div>
                            <div className="store-dtl">
                            <h4 className="font24">{strings.being_prepared}</h4>
                            <p className='message_cls'>{orderDetail ? orderDetail.data ? orderDetail.data.order_status_history.length >= 2 ? orderDetail.data.order_status_history[1].message : '' : '' : ''}</p>
                            {orderDetail ? orderDetail.data ? orderDetail.data.order_status_history.length >= 2 ?
                                <p className="font18m"><span className="watch_ic">
                                <img src={ImgWatch} alt="Watch" />
                                </span> <span className="time-cls"><UtCToLocal date={orderDetail.data.order_status_history[1].updated_at} /></span></p>
                                : '' : '' : ''}
                            </div>
                        </div>
                        <div className={orderDetail ? orderDetail.data ? orderDetail.data.order_status_history.length >= 3 ? "completed track-dtl d-flex" : orderDetail.data.order_status_history.length >= 2 ? "activeted track-dtl d-flex" : "track-dtl d-flex" : "track-dtl d-flex" : "track-dtl d-flex"} >
                            {/* <div className="track-dtl d-flex"> */}
                            <div className="timing">
                            </div>
                            <div className="store-dtl">
                            <h4 className="font24">{strings.on_the_way}</h4>
                            <p className='message_cls'>{orderDetail ? orderDetail.data ? orderDetail.data.order_status_history.length >= 3 ? orderDetail.data.order_status_history[2].message : '' : '' : ''}</p>
                            {orderDetail ? orderDetail.data ? orderDetail.data.order_status_history.length >= 3 ?
                                <p className="font18m"><span className="watch_ic">
                                <img src={ImgWatch} alt="Watch" />
                                </span> <span className="time-cls"><UtCToLocal date={orderDetail.data.order_status_history[2].updated_at} /></span></p>
                                : '' : '' : ''}
                            </div>
                        </div>
                        <div className={orderDetail ? orderDetail.data ? orderDetail.data.order_status_history.length >= 4 ? "completed track-dtl d-flex" : orderDetail.data.order_status_history.length >= 3 ? "activeted track-dtl d-flex" : "track-dtl d-flex" : "track-dtl d-flex" : "track-dtl d-flex"} >
                            {/* <div className="track-dtl d-flex"> */}
                            <div className="timing">
                            </div>
                            <div className="store-dtl">
                            <h4 className="font24">{strings.Delivered}</h4>
                            <p className='message_cls'>{orderDetail ? orderDetail.data ? orderDetail.data.order_status_history.length >= 4 ? orderDetail.data.order_status_history[3].message : '' : '' : ''}</p>
                            {orderDetail ? orderDetail.data ? orderDetail.data.order_status_history.length >= 4 ?
                                <p className="font18m"><span className="watch_ic">
                                <img src={ImgWatch} alt="Watch" />
                                </span> <span className="time-cls"><UtCToLocal date={orderDetail.data.order_status_history[3].updated_at} /></span></p>
                                : '' : '' : ''}
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </>
              }
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(TrackingOrder);