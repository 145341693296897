import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import ImgAic1 from '../assets/img/a-ic1.png';
import ImgAIc2 from '../assets/img/a-ic2.png';
import ImgAIc3 from '../assets/img/a-ic3.png';
import ImgAIc4 from '../assets/img/a-ic4.png';
import ImgAIc5 from '../assets/img/a-ic5.png';
import ImgAIc6 from '../assets/img/a-ic6.png';
import ImgAIc7 from '../assets/img/a-ic7.png';
import { internalIpV6, internalIpV4 } from '../services/getIp';
import apiPath from "../constants/apiPath";
import Http from "../services/Http";

import { userLogin } from "../actions/index";
import { connect } from "react-redux";
import toast, { Toaster } from 'react-hot-toast';
import { Button } from "react-bootstrap";

import LocalizedStrings from 'react-localization';
import localizations from "../localization";
import ImgCross from '../assets/img/close.png';
import ImgFilterIc from '../assets/img/filter_ic.png';
let strings = new LocalizedStrings(localizations);




const mapStateToProps = state => {
	return { user: state.user };

};

const mapDispatchToProps = dispatch => {
	return {
		userLogin: user => dispatch(userLogin(user))
	};
};

const addClass = (e, type) => {
	document.getElementsByTagName('body')[0].setAttribute("class", 'open_account_f');
}
const removeClass = (f, type) => {
	document.getElementsByTagName('body')[0].setAttribute("class", '');

}
const LeftSitebar = (Props) => {

	const location = useLocation()

	const [currentLang, setCurrentLang] = useState(
		localStorage.getItem("current_lang")
			? localStorage.getItem("current_lang")
			: "en"
	);
	strings.setLanguage(currentLang);



	const handleLogout = async () => {

		if (window.confirm(strings.are_you_sure_logout) == true) {

			let path = apiPath.logout;
			var data = new FormData();
			data.append('country_id', '91');
			data.append('language', 'en');
			data.append('device_type', 'Website');
			data.append('device_token', await internalIpV4());
			let options = {
				method: 'POST',

				data: data
			}

			let result = await Http(path, options);
			if (result.status === 200) {
				if (result.data) {
					//alert('test');
					Props.userLogin({});
					toast.success(result.data.message);
					window.location.reload();

				} else {
					toast.error(result.data.message);
				}
			} else {
				if(result.statusText != null) {
					toast.error(result.statusText);
				}
			}
		}



	}

	return (
		<div className="myaccount_l">
			<div className="filter_main">
				<div className="filter_pop d-md-none">
					<a className="account-filter" onClick={(e) => addClass(e, "filter")}>
						<img src={ImgFilterIc} alt='Filter' /> {strings.filter}
					</a>
				</div>
			</div>
			<div className="a_sidebar">
				<button type="button" className="btn_cross d-md-none" onClick={(f) => removeClass(f, "short")}>
					<img src={ImgCross} alt="Close" />
				</button>
				<div className="sidebar-link">

					<ul>
						<li><Link to="/profile" className={location.pathname == '/profile' ? 'active' : ''}><span className="pro-icon">
							<img alt='Profile' src={ImgAic1} /></span> {strings.myaccount}</Link>
						</li>
						<li><Link to="/wallet" className={location.pathname == '/wallet' ? 'active' : ''}><span className="pro-icon">
							<img alt='Wallet' src={ImgAIc2} /></span>{strings.myWallet}</Link>
							</li>
						<li><Link to="/order" className={location.pathname == '/order' ? 'active' : ''}><span className="pro-icon"><img alt='Order' src={ImgAIc3} /></span>{strings.myOrder}</Link></li>
						<li><Link to="/wishlist" className={location.pathname == '/wishlist' ? 'active' : ''}><span className="pro-icon"><img alt='Wishlist' src={ImgAIc4} /></span>{strings.myWishlist}</Link></li>
						{/*<li><Link to="/my-card" className={location.pathname == '/my-card' ? 'active' : ''}><span className="pro-icon"><img src={ImgAIc5} /></span> My Cards</Link></li>*/}
						<li><Link to="/notification" className={location.pathname == '/notification' ? 'active' : ''}><span className="pro-icon"><img alt='Notification' src={ImgAIc6} /></span>{strings.notifications}</Link></li>
						<li><Link to="#" onClick={handleLogout}><span className="pro-icon">
							<img src={ImgAIc7} alt='Logout' /></span>{strings.logout}</Link></li>
					</ul>
				</div>
			</div>
		</div>
	)
}
export default connect(mapStateToProps, mapDispatchToProps)(LeftSitebar);
