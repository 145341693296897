import React, { useState, useEffect } from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import LeftSitebar from '../layout/LeftSitebar';
import ImgWaller1 from '../assets/img/wallet-1.png';
import ImgW1 from '../assets/img/w-1.png';
import ImgW2 from '../assets/img/w-2.png';
import ImgW3 from '../assets/img/w-3.png';
import ImgTransactionUp from '../assets/img/transaction_up.png';
import ImgTransactionDown from '../assets/img/transaction_down.png';
import apiPath from "../constants/apiPath";
import Http from "../services/Http";
import toast, { Toaster } from 'react-hot-toast';
import moment from 'moment';
import { TextInput, ValidationForm } from 'react-bootstrap4-form-validation';
import { Button, Form } from 'react-bootstrap';


import { userLogin } from "../actions/index";
import { connect } from "react-redux";
import { Link, Navigate } from 'react-router-dom';
import Currency from './Component/Currency';

import LocalizedStrings from "react-localization";
import localization from "../localization";
let strings = new LocalizedStrings(localization);

const mapStateToProps = state => {
  return { user: state.user };
};

const mapDispatchToProps = dispatch => {
  return {
    userLogin: user => dispatch(userLogin(user))
  };
}


const Wallet = (Props) => {
  const formRef = React.createRef();
  const [user, setUser] = useState(Object.keys(Props.user).length > 0 ? Props.user : null)

  const [comman, setComman] = useState({
    amount: ''

  });
  const [currentLang, setCurrentLang] = useState(
    localStorage.getItem("current_lang")
      ? localStorage.getItem("current_lang")
      : "en"
  );
  strings.setLanguage(currentLang);


  const [wallet, setWallet] = useState({
    wallet_list: [],
    available_balance: 0
  });



  const registerSubmit = async (e, formData, inputs) => {
    e.preventDefault();
    let path = apiPath.WalletByLink;
    formData.language = localStorage.getItem('current_lang') ? localStorage.getItem('current_lang') : "en"
    formData.currency = JSON.parse(localStorage.getItem('current_currency')) ? JSON.parse(localStorage.getItem('current_currency')).country_code : "KWD"
    let options = {
      method: 'POST',
      data: formData
    }
    let result = await Http(path, options);
    if (result.status === 200) {
      if (result.data.status) {

        window.location.href = result.data.data.paymentlink
        toast.success(result.data.message);

        // await getWallet()
        // resetForm();


      } else {
        toast.error(result.data.message);
      }
    } else {
      if(result.statusText != null) {
        toast.error(result.statusText);
      }
    }
  }
  const registerErrorSubmit = (e, formData, inputs) => {
    e.preventDefault();
  }
  const resetForm = () => {

    // let formRef = formRef.current;
    // formRef.resetValidationState(this.state.clearInputOnReset);
    setComman({ amount: '' })
  }
  const handleChange = async (e) => {
    // alert(e.target.value)
    await setComman({ amount: e.target.value })

  }

  const getWallet = async (id) => {

    let path = apiPath.walletList;
    var data = new FormData();
    let options = {
      method: 'GET',
    }
    let result = await Http(path, options);
    if (result.status == 200) {

      if (result.data.status) {
        // setWallet({ wallet_list: result.data.data.wallet_list });
        // setWallet({ available_balance: result.data.data.available_balance });
        await setWallet({ data: result.data.data })

      }
      else {
        await setWallet({ data: result.data.data })
        toast.error(result.data.message);
      }
    }
  }
  useEffect(() => {

    getWallet();
  }, []);
  return (
    <>
      {user ? (
        user.details.temp_user == 1 ? (
          <Navigate to="/" replace={true} />
        ) : (
          ""
        )
      ) : (
        <Navigate to="/" replace={true} />
      )}
      <Header />
      <main>
        <section className="space-cls my-account-page">
          <div className="container">
            <div className="my-account-inner">

              <LeftSitebar />
              <div className="myaccount_r">
                <h2 className="heading-type2">{strings.my_wallet}</h2>
                <div className="wallet_bg">
                  <div className="wallet_inner">
                    <div className="wallet_l">
                      <div className="wallet_cont">
                        {/* <span>{strings.wallet_amount}</span>
                        <h5><Currency data={wallet.data ? wallet.data.available_balance : 0} /></h5> */}
                        <div className="wallet_amount">
                          <div className="wallet_bal">
                            <span>{strings.loyalty_point}: <h5 className="font-bold"> <Currency data={wallet.data ? wallet.data.loyalty_points > 0 ? wallet.data.loyalty_points : 0 : 0} /></h5></span>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div className="wallet_r">
                      <div className="wallet_ic">
                        <img src={ImgWaller1} alt="Wallet" />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="continue-btn">
                  <ValidationForm onSubmit={registerSubmit} onErrorSubmit={registerErrorSubmit} ref={formRef} >

                    <div className="popup-cont">
                      <Form.Group className="form-group" controlId="form-group">
                        <Form.Label>{strings.account}</Form.Label>
                        <div className='amount_add_wallet'>
                          <TextInput type="number" value={comman.amount} name="amount" id="amount" required
                            placeholder={strings.enter_amount}
                            errorMessage={strings.enter_amount}
                            onChange={(e) => handleChange(e)}
                          />
                          <div className="login-btn">
                            <Button type="submit" className="btn btn_primary">{strings.add_wallet}</Button>
                          </div>
                        </div>
                      </Form.Group>
                    </div>
                  </ValidationForm>
                </div> */}
                {/* <div className="wallet_feat">
                  <h3 className="heading-type2">{strings.wallet_banefits}</h3>
                  <div className="app-dtl-wrap">
                    <div className="app-list">
                      <div className="app-col">
                        <div className="app-box">
                          <div className="app-icon">
                            <img src={ImgW1} alt="Quick Refunds" />
                          </div>
                          <div className="app_title">
                            <h3>{strings.quick_refunds}</h3>
                          </div>
                        </div>
                      </div>
                      <div className="app-col">
                        <div className="app-box">
                          <div className="app-icon">
                            <img src={ImgW2} alt="Secure Shopping" />
                          </div>
                          <div className="app_title">
                            <h3>{strings.one_tab_payment}</h3>
                          </div>
                        </div>
                      </div>
                      <div className="app-col">
                        <div className="app-box">
                          <div className="app-icon">
                            <img src={ImgW3} alt="Easy to Return" />
                          </div>
                          <div className="app_title">
                            <h3>{strings.special_discount}</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {wallet.data ? wallet.data.wallet_list ? wallet.data.wallet_list.length > 0 ?
                <div className="transaction_wrap">
                  <h2 className="heading-type2">{strings.transaction}</h2>
                  <div className="transaction_list">
                    {wallet.data ? wallet.data.wallet_list ? wallet.data.wallet_list.length > 0 ? wallet.data.wallet_list.map((element) =>

                      <div className="wallet_list">
                        <div className="wallet_icon">
                          <img alt='Transaction' src={element.transaction_type == "CR" ? ImgTransactionUp : ImgTransactionDown} alt="" />
                        </div>
                        <div className="wallet_cont">
                          <h5><Currency data={element.amount} /></h5>
                          <p>{element.comment}</p>
                        </div>
                        <div className="ballance_right">
                          <div className="date_wrap">
                            <p>{moment(element.created_at).format('Do MMM')}</p>
                          </div>
                        </div>
                      </div>
                    ) : null : null : null}

                  </div>
                </div>
                :null :null :null}
              </div>

            </div>
          </div>
        </section>
      </main >
      <Footer />
    </>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(Wallet);