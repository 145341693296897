import OwlCarousel from 'react-owl-carousel3';
import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import ImgmStar from '../../assets/img/star.png';
import ImgmFavorite from '../../assets/img/favorite.png';
import ImgmOutofstock from '../../assets/img/outofstock.png';
import ImgTrendingPrd from '../../assets/img/trending-prd.png';
import ImgmUnFavorite from '../../assets/img/un-fav.png';
import ImgmComingSoon from '../../assets/img/commingsoon.png';
import ImgNew from '../../assets/img/new.png';
import ImgSale from '../../assets/img/sale.png';
import apiPath from "../../constants/apiPath";
import Http from "../../services/Http";
import toast from 'react-hot-toast';
import Currency from './Currency';

import LocalizedStrings from 'react-localization';
import localizations from "../../localization";
let strings = new LocalizedStrings(localizations);

const ProductList = (Props) => {
    const useCountdown = (targetDate) => {
        const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        const countDownDate = useRef(new Date(targetDate).getTime());

        useEffect(() => {
            const updateCountdown = () => {
                const now = new Date().getTime();
                const distance = countDownDate.current - now;
                
                if (distance < 0) {
                    clearInterval(timerId);
                    setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
                } else {
                    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
                    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
                    setTimeLeft({ days, hours, minutes, seconds });
                }
            };

            const timerId = setInterval(updateCountdown, 1000);
            updateCountdown();  // Initialize immediately

            return () => clearInterval(timerId);
        }, []);

        return timeLeft;
    };

    const [comman, setComman] = useState({
        setfavorite: ImgmFavorite,
        setUnFavorite: ImgmUnFavorite,
        type: 'add'
    });

    const [currentLang, setCurrentLang] = useState(
        localStorage.getItem("current_lang")
            ? localStorage.getItem("current_lang")
            : "en"
    );
    strings.setLanguage(currentLang);

    const [products, setProducts] = useState([]);
    useEffect(() => {
        setProducts(Props.data.map(product => ({
            ...product, 
            initialAttributes: product.attributes && product.attributes.length > 0 ? product.attributes[0].attribute_values : [],
            originalProductId: product.id
        })));
    }, [Props.data]);

    const [selectedVariants, setSelectedVariants] = useState({});

    const handleSelectVariant = async (product_id, variant_id, imgIdx, productIndex, imageId, image) => {
        let path = `${apiPath.ProductAvailability}/${product_id}`;
        var data = new FormData();
        data.append('attribute_value_id', variant_id);
    
        let options = {
            method: 'POST',
            data: data
        };
    
        let result = await Http(path, options);
        if (result.status === 200) {
            if (result.data.status) {
                let updatedProducts = [...products];
                updatedProducts[productIndex] = {
                    ...result.data.data.product,
                    initialAttributes: updatedProducts[productIndex].initialAttributes,
                    originalProductId: updatedProducts[productIndex].originalProductId
                };
                setProducts(updatedProducts);
                setSelectedVariants(prevState => ({
                    ...prevState,
                    [product_id]: { variant_id, imgIdx, id: imageId, image }
                }));
            } else {
                toast.error(result.data.message);
            }
        } else {
            toast.error("Error fetching product data");
        }
    };

    const ProductItem = ({ item, index }) => {
        const countdownTarget = item.sale_time > 0 ? new Date(Date.now() + item.sale_time * 60000) : null;
        const { days, hours, minutes, seconds } = useCountdown(countdownTarget);
        const shouldDisplayCountdown = countdownTarget !== null;
        const selectedVariant = selectedVariants[item.originalProductId] || {};
        const currentMainImage = selectedVariant.image || (item.product_images && item.product_images[0] && item.product_images[0].image);

        return (
            <div className="col_2 col-4 col-sm-4 col-md-6 col-lg-4 col-xl-3 col_5">
                <div className="arrival-prd">
                    {item.is_coming_soon == 1 ?
                        <div className="out-of-stock">
                            <img src={ImgmComingSoon} alt="Comming soon" />
                        </div> : ''}

                    {item.trending_product == 1 ?
                        <div className="trending_badge">
                            <img src={ImgTrendingPrd} alt="Trending" />
                        </div> : ''}

                    {item.out_of_stock == 1 ?
                        <div className="out-of-stock">
                            <img src={ImgmOutofstock} alt={strings.out_of_stock} />
                        </div> : ''}

                    {item.sold_out == 1 ?
                        <div className="out-of-stock">
                            <img src={ImgmOutofstock} alt={strings.out_of_stock} />
                        </div> : ''}

                    {item.discontinue == 1 ?
                        <div className="out-of-stock">
                            <img src={ImgmOutofstock} alt={strings.out_of_stock} />
                        </div> : ''}
                    {item.is_new == 1 ?
                        <div className="new_badge">
                            <img src={ImgNew} alt="New" />
                        </div> : ''}
                    {item.is_sale == 1 && item.out_of_stock == 0 ?
                        <div className="sale_badge">
                            <img src={ImgSale} alt="Sale" />
                        </div> : ''}

                    {shouldDisplayCountdown && (
                        <div className="sale-time-ribbon">
                            Special sale: {days} days {hours < 10 ? `0${hours}` : hours}:{minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                        </div>
                    )}

                    <div className="arrival-prd-img">
                        <Link to={'/product-detail/' + item.slug} target="_blank">
                            {item.product_images && item.product_images.length >= 2 ? (
                                <div className="img-container">
                                    <img src={currentMainImage} alt={item.name} className="default-img" />
                                    <img src={item.product_images[1].image} alt={item.name} className="hover-img" />
                                </div>
                            ) : (
                                <div className="img-container">
                                    <img src={currentMainImage} alt={item.name} />
                                </div>
                            )}
                        </Link>
                    </div>
                    <div className="arrival-cont">

                        <div className="sold_rate">
                            {item.rating > 0 ? <>
                                <div className="ratting-wrap">
                                    <span className="rate-dtl">{item.rating}&nbsp;</span>
                                    <span className="ratting-icon">
                                        <img src={ImgmStar} alt="Star" />
                                    </span>
                                    <span style={{ color: "#ccc" }} className='slash'>&nbsp;|&nbsp;</span>
                                    <span className="rate-dtl">{item.total_rating_count}</span>
                                </div> </> : null}
                                <div className="sold_dtl">
                                    <p>
                                        {item.sold > 0 ? (
                                            <>
                                                {item.sold} {strings.sold}
                                                {item.rating > 0 ? (
                                                    <br />
                                                ) : (
                                                    " & "
                                                )}
                                                {item.left} {strings.left}
                                            </>
                                        ) : (
                                            `${item.left} ${strings.left}`
                                        )}
                                    </p>
                                </div>
                        </div>
                        <div className="arrival-title">
                            <h3>{item.name}</h3>
                            <Link to={item.brands ? "/product/brand/" + item.brands.slug : '#'} style={{ textDecoration: 'none' }}>
                                <p>{item.brands ? item.brands.name : ''}</p>
                            </Link>
                        </div>

                        <div className="arrival-price">
                            <p>{Props.sale ? <Currency data={item.sale.discount_price != 0 ? item.sale.discount_price : item.sale.original_price} /> : <Currency data={item.discount_price != 0 ? item.discount_price : item.original_price} />}

                            </p>
                            <p>{Props.sale ? parseInt(item.sale.discount_price) == 0 ? '' : <p className="discount-price"><span><Currency data={item.sale.original_price} /></span> ({Math.round((((item.sale.original_price - item.discount_price) * 100) / item.sale.original_price).toFixed(2))}% {strings.off})</p> : parseInt(item.discount_price) == 0 ? '' : <p className="discount-price"><span><Currency data={item.original_price} /> </span>({Math.round((((item.original_price - item.discount_price) * 100) / item.original_price).toFixed(2))}% {strings.off})</p>}</p>

                        </div>
                    </div>
                    {item.initialAttributes && item.initialAttributes.some(variant => variant.product_images && variant.product_images.length > 0) && (
                        <OwlCarousel
                            className='owl-theme variant-carousel'
                            loop={false}
                            margin={10}
                            nav
                            items={5}
                        >
                            {item.initialAttributes && item.initialAttributes.map((variant, idx) => (
                                variant.product_images && variant.product_images.length > 0 && (
                                    variant.product_images.map((image, imgIdx) => (
                                        <div 
                                            key={imgIdx} 
                                            className={`rounded-image ${selectedVariant.imgIdx === imgIdx ? 'selected-image' : ''}`}
                                            onClick={() => {
                                                handleSelectVariant(item.originalProductId, variant.id, imgIdx, index, image.id, image.image);
                                            }}
                                        >
                                            <img key={imgIdx} src={image.image} alt={image.id} />
                                        </div>
                                    ))
                                )
                            ))}
                        </OwlCarousel>
                    )}
                    <div className="prd-add-option">
                        <div className="fav_wrap">
                            {item.is_wishlist == 1 ? <Link className="btn_primary"
                                onClick={(e) => Props.handleFavourite(e, item.id, 'remove', index)}>
                                <img src={ImgmUnFavorite} alt="Un favorite" />
                            </Link> :
                                <Link className="btn_primary"
                                    onClick={(e) => Props.handleFavourite(e, item.id, 'add', index)}>
                                    <img src={ImgmFavorite} alt="Favorite" />
                                </Link>}
                        </div>
                        <div className="cart_wrap">
                            <Link 
                                onClick={(e) => {
                                    if (item.is_coming_soon == 1) {
                                        e.preventDefault();
                                    } else if ((item.out_of_stock == 0) || (item.left > 0)) {
                                        Props.showAddtoCart(e, item, item.attributes, selectedVariants[item.originalProductId]?.id);
                                    } else {
                                        window.location.href = "/product-detail/" + item.slug;
                                    }
                                }}
                                className={
                                    item.is_coming_soon == 1 
                                        ? "btn_primary notify_me_new coming_soon" 
                                        : (item.out_of_stock == 1 || item.left <= 0) 
                                            ? "btn_primary notify_me_new" 
                                            : "btn_primary"
                                }
                                target="_blank"
                            >
                                {(item.out_of_stock == 1 || item.left <= 0 || item.is_coming_soon == 1) 
                                        ? strings.notifyMe 
                                        : strings.add_to_bag}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            {products.map((item, index) => (
                <ProductItem key={index} item={item} index={index} />
            ))}
        </>
    )
}
export default ProductList;