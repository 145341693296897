import React, { useState, useEffect } from 'react';
import { Link, Navigate } from "react-router-dom";

import { useParams } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel3';
import ReactImageZoom from 'react-image-zoom';
import { Tab, Tabs, Dropdown, Button, Row, Col } from 'react-bootstrap';

import Header from '../layout/Header';
import Footer from '../layout/Footer';
import CommanList from '../view/Component/CommanList';

import apiPath from "../constants/apiPath";
import Http from "../services/Http";

import toast, { Toaster } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


import ImgPerfume1 from '../assets/img/perfume-1.jpg';
import ImgPerfume2 from '../assets/img/perfume-2.jpg';
import ImgPerfume3 from '../assets/img/perfume-3.jpg';
import ImgShare from '../assets/img/share.png';
import ImgmStar from '../assets/img/star.png';
import ImgmStar1 from '../assets/img/star-1.png';

import ImgProductTranding from '../assets/img/product-trending.png';
import ImgmFavorite from '../assets/img/favorite.png';
import ImgmOutofstock from '../assets/img/outofstock.png';
import ImgTrendingPrd from '../assets/img/trending-prd.png';

import ImgGucciLogo from '../assets/img/gucci_logo.png';
import ImgCheckout1 from '../assets/img/checkout-1.png';
import ImgCheckout2 from '../assets/img/checkout-2.png';
import ImgCheckout3 from '../assets/img/checkout-3.png';
import ImgPn1 from '../assets/img/pn-1.png';
import ImgPn3 from '../assets/img/pn-3.png';

import ImgBestSeller from '../assets/img/best-seller.png';
import ImgmBestSeller from '../assets/img/m-best-seller.png';


import ImgmA1 from '../assets/img/a1.png';
import ImgmA2 from '../assets/img/a2.png';
import ImgmA3 from '../assets/img/a3.png';
import ImgmA4 from '../assets/img/a4.png';

import ImgmFacebook from '../assets/img/facebook.png';
import ImgmTwitter from '../assets/img/twitter.png';
import ImgWhatup from '../assets/img/whatup.png';
import noReview from '../assets/img/no-review.png';


import { userLogin } from "../actions/index";
import { connect } from "react-redux";
import moment from 'moment';
import ProgressBar from './Component/progress-bar.component';
import Product from './Component/Product';
import Currency from './Component/Currency';

import LocalizedStrings from "react-localization";
import localization from "../localization";
import useScript from '../constants/useScript';

import Modal from 'react-bootstrap/Modal';
import Imgcross from '../assets/img/cross.png';
import { Form } from 'react-bootstrap';
import { TextInput, SelectGroup, ValidationForm } from "react-bootstrap4-form-validation";
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Rating } from 'react-simple-star-rating';
import { Helmet } from 'react-helmet';

let strings = new LocalizedStrings(localization);


const mapStateToProps = state => {
   return { user: state.user };
};

const mapDispatchToProps = dispatch => {
   return {
      userLogin: user => dispatch(userLogin(user))
   };
}

const options = {
   margin: 0,
   nav: true,
   dots: false,
   loop: true,
   responsive: {
      0: {
         items: 1,
         margin: 7.5
      },
      420: {
         items: 1
      },
      577: {
         items: 1
      },
      992: {
         items: 1
      }
   }
};


const newAriveOption = {
   margin: 15,
   nav: true,
   dots: false,
   loop: false,
   responsive: {
      0: {
         items: 2,
         margin: 7.5
      },
      420: {
         items: 2,
         margin: 7.5
      },
      577: {
         items: 3,
         margin: 7.5
      },
      767.98: {
         items: 4
      },
      992: {
         items: 5
      },
      1366: {
         items: 6
      },
      1480: {
         items: 6
      },
      1580: {
         items: 7
      }
   }
}


const testData = [
   { bgcolor: "#6a1b9a", completed: 60 },
   { bgcolor: "#00695c", completed: 30 },
   { bgcolor: "#ef6c00", completed: 53 },
];

const ProductDetail = (Props) => {
   const [isScriptLoaded, setScriptLoaded] = useState(false);

   const [nameReview, setNameReview] = useState('');
   const [phoneReview, setPhoneReview] = useState('');

   const formRef = React.createRef();
   const formRef2 = React.createRef();
   const [modalIsOpen, setModalIsOpen] = useState(false);
   const [modalNotifyMeIsOpen, setModalNotifyMeIsOpen] = useState(false);

   const setModalIsOpenToTrue = (item, attributes) => {
      setCommanList({ ...commanList, product_id: item.id, attributes: attributes, product: item });
      // alert(id)
      setModalIsOpen(true)
   }

   const setModalIsOpenToFalse = () => {
      setModalIsOpen(false)
   }

   const showModalNotifyMe = (e) => {
      e.preventDefault();
      setModalNotifyMeIsOpen(true);
   }

   const setModalNotifyMeIsOpenToFalse = () => {
      setModalNotifyMeIsOpen(false);
      // console.log(modalNotifyMeIsOpen);
   }

   const showAddtoCart = async (e, item, attributes) => {
      e.preventDefault();
      if (attributes.length > 0) {
         setModalIsOpenToTrue(item, attributes);
      }
      else {
         setCommanList({ ...commanList, product_id: item.id, attribute_value_id: null, product: item });
         addtoCartList(item.id, null);
      }
   };

   const handleAttributeChange = async (productId, attributeValueId) => {
		try {
			let path = apiPath.ProductAvailability + '/' + productId;
			var data = new FormData();
			data.append('attribute_value_id', attributeValueId);
			let options = {
				method: 'POST',
				data: data
			}
			let result = await Http(path, options);
			if (result.status == 200) {
				if (result.data.status) {
					setCommanList({
						...commanList,
						product: {
						  ...commanList.product,
						  discount_price: result.data.data.product.discount_price,
						  original_price: result.data.data.product.original_price,
						},
					  });
				}
				else {
					toast.error(result.data.message);
				}
			}
		} catch (error) {
		  console.error('Error updating price:', error);
		  toast.error('An error occurred while updating price');
		}
	};

   const addtoCartList = async (id, type) => {
      let path = apiPath.AddtoCart;
      var data = new FormData();
      data.append('product_id', id);
      data.append('qty', 1);
      if (type == null && commanList.attribute_value_id != null) {
         data.append('product_attribute_values_id', commanList.attribute_value_id);
      }
      else if (type == 'form') {
         const attributeValueId = document.querySelector('input[name="attribute_value_id"]:checked');
         if (attributeValueId != null && attributeValueId != "") {
               data.append('product_attribute_values_id', attributeValueId.value);
         }
      }

      if (selectedVariant && selectedVariant.id) {
         data.append('variant_image_id', selectedVariant.id);
      }

      let options = {
         method: 'POST',
         data: data
      }

      let result = await Http(path, options);
      if (result.status == 200) {
         if (result.data.status) {
            setComman({ ...comman, addCart: true });
            toast.success(result.data.message);
         }
         else {
            toast.error(result.data.message);
         }
         setModalIsOpenToFalse();
      }
   }

   const params = useParams()


   const [user, setUser] = useState(Object.keys(Props.user).length > 0 ? Props.user : null)

   const [commanList, setCommanList] = useStateWithCallbackLazy({
      product_id: 0,
      attributes: [],
      attribute_value_id: null,
      product: null
   });

   const [comman, setComman] = useState({
      zoomWidth: 500,
      img: ImgPerfume1,
      qty: 1,
      addCart: false,
      buyNow: false,

   });
   const [productDetails, setProductDetails] = useState([]);
   const [productRating, setProductRating] = useState([]);
   const [ratingData, setratingData] = useState([]);
   const [attr, setAttr] = useState([]);

   const [currentLang, setCurrentLang] = useState(
      localStorage.getItem("current_lang")
         ? localStorage.getItem("current_lang")
         : "en"
   );
   strings.setLanguage(currentLang);

   function isNumber(n) {
      return !isNaN(parseFloat(n)) && !isNaN(n - 0);
   }

   const changeImag = (e, item) => {
      setComman({ ...comman, img: item.image });
   }

   const current_currency = localStorage.getItem('current_currency') ? JSON.parse(localStorage.getItem('current_currency')) : { currency: 1, country_code: 'KWD' };

   const getProductDetails = async (id, type) => {
      var path;
      if (isNumber(id)) {
         path = apiPath.productDetails + '/' + id + type;
      }
      else {
         path = apiPath.productDetailsWeb + '/' + id + type;
      }

      var data = new FormData();
      let options = {
         method: 'GET',
      }
      let result = await Http(path, options);
      if (result.status == 200) {
         if (result.data.status) {
            await setProductDetails({ data: result.data.data })

            if (result.data.data.product.attribute_value_id != null) {
               setAttr(result.data.data.product.attribute_value_id.toString());
            }
            else {
               setAttr("");
            }

            if (result.data.data.cart_item != 0) {
               setComman({ ...comman, qty: parseInt(result.data.data.cart_item), img: result.data.data.product.image });
            }
            else {
               setComman({ ...comman, img: result.data.data.product.image });
            }

            // getRatingData(productDetails)
            let price = result.data.data.product_sale ? (result.data.data.product_sale.discount_price != 0 ? result.data.data.product_sale.discount_price : result.data.data.product_sale.original_price) : (result.data.data.product.discount_price != 0 ? result.data.data.product.discount_price : result.data.data.product.original_price);

            if (isScriptLoaded) {
               new window.TabbyPromo({
                  selector: '#tabbyCard',
                  lang: localStorage.getItem("current_lang") ? localStorage.getItem("current_lang") : "en",
                  currency: current_currency.country_code,
                  price: ((parseFloat(current_currency.currency) * price).toFixed(2)),
                  productType: 'installments',
               });
            }
         }
         else {
            toast.error(result.data.message);
         }
      }
   }

   const orderSample = async (id) => {
      let path = apiPath.AddtoCart;
      var data = new FormData();
      data.append('product_id', id);
      data.append('qty', 1);
      data.append('is_sample', true);

      let options = {
         method: 'POST',
         data: data
      }

      let result = await Http(path, options);
      if (result.status == 200) {
         if (result.data.status) {
            setComman({ ...comman, addCart: true });
            toast.success(result.data.message);
         } else {
            toast.error(result.data.message);
         }
         setModalIsOpenToFalse();
         setSampleOrdered(true);
      }
   }

   const [sampleOrdered, setSampleOrdered] = useState(false);

   const productShare = (e, id, title, type) => {
      if (type == 'facebook') {
         var url = window.location.href + 'product-detail/' + id
         window.open('https://facebook.com/sharer.php?u=' + url + '?id=' + id + '&quote=' + title);
      }
      if (type == 'twitter') {

         var url = window.location.href + 'product-detail/' + id
         window.open('https://twitter.com/share?url=' + url + '?id=' + id + '&text=' + title);
      }
      if (type == 'whatup') {
         var url = window.location.href + 'product-detail/' + id
         window.open('https://web.whatsapp.com/send?text=' + url);
      }
   }

   const chnageattributeValue = async (e, i) => {
      var attribute = attr.split(",");

      var old_attribute = attribute;

      attribute.map((item, index) => {
         if (i == index) {
            attribute[index] = parseInt(e.target.value)
         }
         else {
            attribute[index] = parseInt(item)
         }

      });


      let path = apiPath.ProductAvailability + '/' + params.id;
      var data = new FormData();
      data.append('attribute_value_id', attribute.toString());
      let options = {
         method: 'POST',
         data: data
      }
      let result = await Http(path, options);
      if (result.status == 200) {
         if (result.data.status) {
            setProductDetails({ data: result.data.data })
            setComman({ ...comman, img: result.data.data.product.image });
            setAttr(attribute.toString());
         }
         else {
            toast.error(result.data.message);
         }
      }
   }

   const getRatingReview = async (id) => {

      let path = apiPath.productRatingList;
      var data = new FormData();
      data.append('product_id', id)
      let options = {
         method: 'POST',
         data: data
      }
      let result = await Http(path, options);
      if (result.status == 200) {
         if (result.data.status) {

            setProductRating({ data: result.data.data })
            //   alert(productRating.data.length)
            //   setComman({ ...comman, img:result.data.data.product.image}); 
            //   setAttr(result.data.data.product.attribute_value_id);
            if (window.location.hash === '#reviews') {
               handleScrollToRatingBlock();
            }
         }
         else {
            // toast.error(result.data.message);
         }
      }
   }

   const likeReview = async (review_id, type) => {

      let path = apiPath.likeReview;
      var data = new FormData();
      data.append('review_id', review_id);
      data.append('type', type);
      let options = {
         method: 'POST',
         data: data
      }

      let result = await Http(path, options);
      if (result.status == 200) {
         if (result.data.status) {
            getRatingReview(params.id)
         }
         else {
            toast.error(result.data.message);
         }
      }
   }


   const addRemove = (e, type) => {
      var qty = 0;
      if (type == 'add') {
         if (productDetails.data.product.left > comman.qty) {
            qty = comman.qty + 1;
         }
         else {
            qty = comman.qty;
         }

      }
      else {
         if (comman.qty > 1) {
            qty = comman.qty - 1;
         }
      }
      let price = productDetails.data.product_sale ? (productDetails.data.product_sale.discount_price != 0 ? productDetails.data.product_sale.discount_price : productDetails.data.product_sale.original_price) : (productDetails.data.product.discount_price != 0 ? productDetails.data.product.discount_price : productDetails.data.product.original_price);

      if (isScriptLoaded) {
         new window.TabbyPromo({
            selector: '#tabbyCard',
            lang: localStorage.getItem("current_lang") ? localStorage.getItem("current_lang") : "en",
            currency: current_currency.country_code,
            price: ((parseFloat(current_currency.currency) * price * qty).toFixed(2)),
            productType: 'installments',
         });
      }
      setComman({ ...comman, qty: qty });
   }

   const addtoCart = async (type) => {

      if (comman.qty > 0) {
         let path = apiPath.AddtoCart;
         var data = new FormData();
         data.append('product_id', productDetails.data.product.id);
         if (params.type != undefined) {
            data.append('is_sale', params.type);
         }
         data.append('qty', comman.qty);
         if (attr) {
            data.append('product_attribute_values_id', attr);
         }

         if (selectedVariant && selectedVariant.id) {
            data.append('variant_image_id', selectedVariant.id);
         }

         let options = {
            method: 'POST',
            data: data
         }
         let result = await Http(path, options);
         if (result.status == 200) {
            if (result.data.status) {

               if (type == 'BuyNow') {
                  setComman({ ...comman, buyNow: true });
               }
               else {
                  setComman({ ...comman, addCart: true });
               }
               // getProductDetails(params.id);
               toast.success(result.data.message);
            }
            else {
               toast.error(result.data.message);
            }
         }
      }
      else {
         toast.error(strings.at_last_one_qty);
      }
   }

   const getRatingData = async (data) => {

   }

   const notifyMe = async () => {
      let path = apiPath.notifyMe + '/' + params.id;
      var data = new FormData();
      console.log(nameReview, phoneReview);
      data.append('name', nameReview);
      data.append('phone', phoneReview);
      let options = {
         method: 'POST',
         data: data
      }
      let result = await Http(path, options);
      if (result.status == 200) {
         if (result.data.status) {
            toast.success(result.data.message);
         }
         else {
            toast.error(result.data.message);
         }
      }
      setModalNotifyMeIsOpen(false);
   }

   const handleScrollToRatingBlock = () => {
      const targetElement = document.getElementById('perfumeBlockFirst');
      if (targetElement) {
         window.scrollTo({
            top: targetElement.offsetTop - 150,
            behavior: 'smooth',
         });

         window.history.pushState({}, '', '#reviews');
      }
   };

   useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://checkout.tabby.ai/tabby-promo.js';
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
         setScriptLoaded(true);
      };

      return () => {
         document.body.removeChild(script);
      };
   }, []);

   useEffect(() => {
      if (isScriptLoaded) {
         if (window.location.hash !== '#reviews') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
         }

         getProductDetails(params.id, params.type ? '/' + params.type : '');
         getRatingReview(params.id)
      }
   }, [params, isScriptLoaded]);

   const selectedAttrValueId = productDetails?.data?.product?.attribute_value_id;

   const attributes = productDetails?.data?.product?.attributes ? productDetails.data.product.attributes[0] : null;

   const selectedAttributeValue = attributes ? attributes.attribute_values.find(attr_val => attr_val.id == selectedAttrValueId) : null;

   const selectedProductImages = (selectedAttributeValue && Array.isArray(selectedAttributeValue?.product_images)) ? selectedAttributeValue.product_images : [];

   const [selectedVariant, setSelectedVariant] = useState({});

   const productImages = Array.isArray(productDetails?.data?.product?.product_images) ? productDetails.data.product.product_images : [];

   const combinedImages = [...productImages, ...selectedProductImages];

   const [activeSlideshowImage, setActiveSlideshowImage] = useState(combinedImages[0]?.image || '');

   const handleSelectVariantImage = async (variant_id, imgIdx, imageId, image) => {
      // Update the selected variant
      setSelectedVariant({
         variant_id,
         imgIdx,
         id: imageId,
         image
      });

      // Set the active slideshow image
      setActiveSlideshowImage(image);

      setComman({ ...comman, img: image });
   };

   return (
      <>
         {comman.buyNow ? <Navigate to="/cart" replace={true} /> : ''}
         <Helmet>
            <title>{productDetails.data && productDetails.data.meta_title != "" ? productDetails.data.meta_title : "The perfumesmell"}</title>
            <meta name="description" content={productDetails.data && productDetails.data.meta_description != "" ? productDetails.data.meta_description : "The perfumesmell"} />
            <meta name="keywords" content={productDetails.data && productDetails.data.meta_keywords != "" ? productDetails.data.meta_keywords : "The perfumesmell"} />
         </Helmet>
         <Header data={comman.addCart} />
         <main className='prd_detail_space'>
            {productDetails.data ?
               <>
                  <section className="space-cls product_dtl_page">
                     <div className="container">
                        <div className="row">
                           <div className="col-md-12 col-lg-4">
                              <div className="product-slide-main">
                                 <div id='lens'></div>

                                 <div className='zoom_sld_main' style={{ 'position': "relative", 'zIndex': '1' }}>
                                    <ReactImageZoom {...comman} />
                                 </div>
                                 <div id='result'></div>


                                 <OwlCarousel className="owl-carousel owl-theme" {...newAriveOption} key={combinedImages.length}>
                                    {combinedImages.length > 0 ? 
                                       combinedImages.map((item, index) => 
                                          <div className="item" key={index}
                                             onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                changeImag(e, item);
                                             }}
                                          >
                                             <img 
                                                className={`slideshow-thumbnails ${activeSlideshowImage === item.image ? 'active' : ''}`}
                                                src={item.image}
                                                alt={item.name} 
                                             />
                                          </div>
                                       ) : ''}
                                 </OwlCarousel>
                              </div>
                           </div>
                           <div className="col-md-12 col-lg-8">
                              <div className="prd-dtl-cont">
                              <div className="header-prd-container">
                                 <div
                                    className={`header-prd-left ${
                                       productDetails.data.product.inspired_by ? "half-width" : "full-width"
                                    }`}
                                 >
                                    <div className="prd-brand">
                                       <div className="brand-img">
                                       <Link
                                          to={
                                             "/product/brand/" +
                                             (productDetails.data.product.brands
                                             ? productDetails.data.product.brands.slug
                                             : "")
                                          }
                                       >
                                          <span>
                                             <img
                                             src={
                                                productDetails.data.product.brands
                                                   ? productDetails.data.product.brands.image
                                                   : ImgGucciLogo
                                             }
                                             alt={
                                                productDetails.data.product.brands
                                                   ? productDetails.data.product.brands.name
                                                   : "Brand"
                                             }
                                             />
                                          </span>
                                       </Link>
                                       </div>
                                       <h6>
                                       {productDetails.data.product.brands
                                          ? productDetails.data.product.brands.name
                                          : ""}
                                       </h6>
                                    </div>
                                    <div className="prd-title">
                                       <h2>
                                       <span>
                                          {productDetails.data.product ? productDetails.data.product.name : ""}
                                       </span>
                                       </h2>
                                       <div className="share-option">
                                       <Dropdown>
                                          <Dropdown.Toggle
                                             id="dropdown-custom-components"
                                             className="shareBtn"
                                          >
                                             <img src={ImgShare} alt="Share" />
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu>
                                             <Dropdown.Item
                                             eventKey="1"
                                             onClick={(e) =>
                                                productShare(
                                                   e,
                                                   productDetails.data.product.id,
                                                   productDetails.data.product.name,
                                                   "facebook"
                                                )
                                             }
                                             >
                                             <img src={ImgmFacebook} alt="Facebook" /> {strings.facebook}
                                             </Dropdown.Item>
                                             <hr />
                                             <Dropdown.Item
                                             eventKey="2"
                                             onClick={(e) =>
                                                productShare(
                                                   e,
                                                   productDetails.data.product.id,
                                                   productDetails.data.product.name,
                                                   "twitter"
                                                )
                                             }
                                             >
                                             <img src={ImgmTwitter} alt="Twitter" /> {strings.twitter}
                                             </Dropdown.Item>
                                             <hr />
                                             <Dropdown.Item
                                             eventKey="3"
                                             onClick={(e) =>
                                                productShare(
                                                   e,
                                                   productDetails.data.product.id,
                                                   productDetails.data.product.name,
                                                   "whatup"
                                                )
                                             }
                                             >
                                             <img src={ImgWhatup} alt="WhatsApp" /> {strings.whatus}
                                             </Dropdown.Item>
                                          </Dropdown.Menu>
                                       </Dropdown>
                                       </div>
                                    </div>
                                 </div>
                                 {productDetails.data.product.inspired_by && (
                                    <div className="header-prd-right">
                                       <Link
                                       to={"/product-detail/" + productDetails.data.product.inspired_by.slug}
                                       className="product-card-custom"
                                       >
                                       <div className="product-card-container-custom">
                                          <h5 className=''>{strings.inspired_by}</h5>
                                          <div className='inspired-by-container'>
                                             {/* Product Image */}
                                             <div className="product-image-custom">
                                                <img
                                                src={productDetails.data.product.inspired_by.image}
                                                alt={productDetails.data.product.inspired_by.name}
                                                />
                                             </div>
                                             {/* Brand Logo */}
                                             <div className="brand-logo-custom">
                                                <img
                                                   src={productDetails.data.product.inspired_by.brands.logo}
                                                   alt={productDetails.data.product.inspired_by.brands.name}
                                                />
                                             </div>
                                          </div>
                                          {/* Product Details */}
                                          <div className="product-details-custom">
                                             {/* Product Title */}
                                             <h3 className="product-title-custom">
                                             {productDetails.data.product.inspired_by.name}
                                             </h3>
                                             {/* Rating and Reviews */}
                                             <div className="rating-custom">
                                             {productDetails.data.product.inspired_by.rating > 0 && (
                                                   <div className="prd-review">
                                                      <Rating
                                                      allowHover={false}
                                                      allowFraction={true}
                                                      initialValue={productDetails.data.product.inspired_by.rating}
                                                      readonly={true}
                                                      />
                                                      <span className="slash">|</span>
                                                      <span>
                                                      {productDetails.data.product.inspired_by.total_rating_count} {strings.ratings}
                                                      </span>
                                                   </div>
                                                )}
                                             </div>
                                             {/* Pricing */}
                                             {productDetails.data.product.inspired_by.discount_price > 0 ? (
                                             <div className="price-custom">
                                                <span className="current-price-custom">
                                                   {productDetails.data.product.inspired_by.currency}{" "}
                                                   {productDetails.data.product.inspired_by.discount_price}
                                                </span>
                                                <span className="original-price-custom">
                                                   {productDetails.data.product.inspired_by.currency}{" "}
                                                   {productDetails.data.product.inspired_by.original_price}
                                                </span>
                                             </div>
                                             ) : (
                                                <div className="price-custom">
                                                   <span className="current-price-custom">
                                                      {productDetails.data.product.inspired_by.currency}{" "}
                                                      {productDetails.data.product.inspired_by.original_price}
                                                   </span>
                                                </div>
                                             )}
                                          </div>
                                       </div>
                                       </Link>
                                    </div>
                                 )}
                                 </div>
                                 <div className="prd-cont-sec">
                                    <div className="main-prd-left">
                                       <div className="product-inner-dtl">
                                          <ul style={{ marginBottom: "0" }}>
                                             {productDetails.data && productDetails.data.product.trending_product ?
                                                <li>
                                                   <span><img src={ImgProductTranding} /></span>
                                                </li>
                                                : ''}
                                             <li>
                                                <span>{productDetails.data ? productDetails.data.product.sold > 0 ? productDetails.data.product.sold + " " + strings.sold + " &" : null : null}  {productDetails.data ? productDetails.data.product.left : 0} {strings.left}</span>
                                             </li>
                                          </ul>
                                       </div>
                                       <div className="prd-price-wrap" style={{justifyContent: "space-between", margin: "0"}}>
                                          <div className="arrival-price">
                                             <p>
                                                {productDetails.data.product_sale ? <Currency data={productDetails.data.product_sale.discount_price != 0 ? productDetails.data.product_sale.discount_price : productDetails.data.product_sale.original_price} /> : <Currency data={productDetails.data.product.discount_price != 0 ? productDetails.data.product.discount_price : productDetails.data.product.original_price} />}
                                             </p>
                                             {productDetails.data.product_sale ? parseInt(productDetails.data.product_sale.discount_price) == 0 ? '' : <p className="discount-price"><span><Currency data={productDetails.data.product_sale.original_price} /></span> ({Math.round(((productDetails.data.product_sale.original_price - productDetails.data.product_sale.discount_price) * 100) / productDetails.data.product_sale.original_price).toFixed(2)}% off)</p> : parseInt(productDetails.data.product.discount_price) == 0 ? '' : <p className="discount-price"><span><Currency data={productDetails.data.product.original_price} /> </span>({Math.round(((productDetails.data.product.original_price - productDetails.data.product.discount_price) * 100) / productDetails.data.product.original_price).toFixed(2)}% off)</p>}

                                          </div>
                                          {productDetails.data.product.rating > 0 && (
                                             <div onClick={handleScrollToRatingBlock} className="prd-review">
                                                <Rating
                                                allowHover={false}
                                                allowFraction={true}
                                                initialValue={productDetails.data.product.rating}
                                                readonly={true}
                                                />
                                                <span className="slash">|</span>
                                                <span>
                                                {productRating.data ? productRating.data.length : 0}{" "}
                                                {strings.ratings}
                                                </span>
                                             </div>
                                          )}
                                       </div>

                                       {productDetails.data && productDetails.data.product.sample ?
                                          <div className="sample-product">
                                             <p>{strings.sample_text_instruction}</p>
                                             <div className="sample-order-info">
                                                <div className="sample-info-left">
                                                <h4>
                                                   {productDetails.data.product.sample.size} ML
                                                   <span>&nbsp;({current_currency.country_code} {(parseFloat(current_currency.currency) * productDetails.data.product.sample.price).toFixed(2)})</span>
                                                </h4>
                                                </div>
                                                <div className="sample-info-right">
                                                {!sampleOrdered && <button onClick={() => orderSample(productDetails.data.product.sample.id)}>{strings.orderSample}</button>}
                                                </div>
                                             </div>
                                          </div> : ''}

                                       {productDetails.data ?
                                          productDetails.data.attributes.length > 0 ?
                                             productDetails.data.attributes.map((attribute, i) =>
                                                <div className="prd-size" key={i}>
                                                   <h4>{attribute.name}</h4>
                                                   <div className="prd_size_label">
                                                      {attribute.attribute_values.length > 0 ?
                                                         attribute.attribute_values.map((attr_val, i1) =>
                                                            <label className="custom_radio_b" key={i1}>
                                                               <input
                                                                  type="radio"
                                                                  name={attribute.id}
                                                                  value={attr_val.id}
                                                                  checked={attr ? attr.split(",").includes(attr_val.id.toString()) : ''}
                                                                  onClick={(e) => chnageattributeValue(e, i)}
                                                               />
                                                               {productDetails.data ?
                                                                  (attr_val.left === 0) ? (
                                                                     <del><span className="checkmark">{attr_val.value}</span></del>
                                                                  ) : (
                                                                     (productDetails.data.product.attribute_value_id === attr_val.id) && (productDetails.data.product.status === 0) ? (
                                                                        <del><span className="checkmark">{attr_val.value}</span></del>
                                                                     ) : (
                                                                        <span className="checkmark">{attr_val.value}</span>
                                                                     )
                                                                  )
                                                                  : null}
                                                            </label>
                                                         ) : ''}
                                                   </div>
                                                </div>
                                             ) : '' : ""}

                                       {selectedProductImages.length > 0 && (
                                       <div className="prd-size mb-2">
                                          <h4>{strings.color}</h4>
                                             <OwlCarousel
                                             className='owl-theme variant-carousel'
                                             loop={false}
                                             margin={10}
                                             nav
                                             items={5}
                                             >
                                             {selectedProductImages.map((image, imgIdx) => (
                                                <div 
                                                   key={imgIdx} 
                                                   className={`rounded-image ${selectedVariant.imgIdx === imgIdx ? 'selected-image' : ''}`}
                                                   onClick={() => {
                                                   handleSelectVariantImage(selectedAttrValueId, imgIdx, image.id, image.image);
                                                   }}
                                                >
                                                   <img key={imgIdx} src={image.image} alt={image.id} />
                                                </div>
                                             ))}
                                             </OwlCarousel>
                                       </div>
                                       )}

                                       <div id="field1" className="quantity_sec">
                                          <h4>{strings.qty}</h4>
                                          <div className="q_btn_group">
                                             <button type="button" id="sub" className="sub" onClick={(e) => addRemove(e, 'sub')}>-</button>
                                             <input type="number" id="1" value={comman.qty} min="1" max="3" />
                                             <button type="button" id="add" className="add" onClick={(e) => addRemove(e, 'add')}>+</button>
                                          </div>
                                       </div>
                                       
                                       <div className="add-btn-grp d-flex mt-3">

                                          {productDetails.data ? productDetails.data.cart_item == 0 ?

                                             productDetails.data.product.out_of_stock == 1 || productDetails.data.product.status == 0 ?
                                                <Button type="button" className="btn btn_primary me-2" disabled>{strings.out_of_stock}</Button>
                                                :
                                                productDetails.data.product.sold_out == 1 ?
                                                   <Button type="button" className="btn btn_primary me-2" disabled>{strings.sold_out}</Button>
                                                   :
                                                   productDetails.data.product.discontinue == 1 ?
                                                      <Button type="button" className="btn btn_primary me-2" disabled>{strings.discontinue}</Button> :
                                                      productDetails.data.product.left <= 0 ?
                                                         <Button type="button" className="btn btn_primary me-2" disabled>{strings.out_of_stock}</Button> :
                                                         comman.addCart ?
                                                            <Link to="/cart" className="btn btn_primary me-2">{strings.go_to_cart} </Link>
                                                            :
                                                            <Button type="button" onClick={() => addtoCart('addCart')} className="btn btn_primary me-2">{strings.add_to_bag}</Button>



                                             :
                                             productDetails.data.product.out_of_stock == 1 || productDetails.data.product.status == 0 ?
                                                <Button type="button" className="btn btn_primary me-2" disabled>{strings.out_of_stock}</Button>
                                                :
                                                productDetails.data.product.sold_out == 1 ?
                                                   <Button type="button" className="btn btn_primary me-2" disabled>{strings.sold_out}</Button>
                                                   :
                                                   productDetails.data.product.discontinue == 1 ?
                                                      <Button type="button" className="btn btn_primary me-2" disabled>{strings.discontinue}</Button>
                                                      :
                                                      productDetails.data.product.left <= 0 ?
                                                         <Button type="button" className="btn btn_primary me-2" disabled>{strings.out_of_stock}</Button> :
                                                         <Button className="btn btn_primary me-2" onClick={() => addtoCart('BuyNow')} >{strings.go_to_cart}</Button> : null}
                                          {/* <Link to="/cart" className="btn btn_primary me-2">Go To Cart </Link> : null} */}


                                          {productDetails.data && productDetails.data.product.out_of_stock == 1 || productDetails.data.product.status == 0 ?
                                             <Button type="button" className="btn btn_primary notify_btn me-2" onClick={(e) => showModalNotifyMe(e)}>{strings.notifyMe}</Button>
                                             :
                                             productDetails.data.product.sold_out == 1 ?
                                                <Button type="button" className="btn btn_primary notify_btn me-2" onClick={(e) => showModalNotifyMe(e)} >{strings.notifyMe}</Button>
                                                :
                                                productDetails.data.product.discontinue == 1 ?
                                                   <Button type="button" className="btn btn_primary notify_btn me-2" onClick={(e) => showModalNotifyMe(e)}>{strings.notifyMe}</Button>
                                                   :
                                                   productDetails.data.product.left <= 0 ?
                                                      <Button type="button" className="btn btn_primary notify_btn me-2" onClick={(e) => showModalNotifyMe(e)}>{strings.notifyMe}</Button> :
                                                      <Button href="#" onClick={() => addtoCart('BuyNow')} className="btn btn_primary">{strings.buyNow}</Button>}
                                       </div>
                                       <div></div>
                                       <div id="tabbyCard" style={{ marginTop: "10px" }}></div>
                                    </div>
                                    {(productDetails.data.notes_color.length > 0 || 
                                       productDetails.data.base_accord.length > 0 || 
                                       productDetails.data.middle_accord.length > 0 || 
                                       productDetails.data.top_accord.length > 0) && (
                                          <div className="main-prd-right" style={{ display: 'flex', gap: '20px' }}>
                                             {/* Cột 1: Perfume Accords */}
                                             {productDetails.data.notes_color.length > 0 && (
                                                <div className="column column-left" style={{ flex: 1 }}>
                                                   <div className="perfume-acords">
                                                      <h4>{strings.perfume_accords}</h4>
                                                   </div>
                                                   <div className="progress-wrap">
                                                      {productDetails.data.notes_color.map((item, index) => (
                                                         <div 
                                                            className="woody-bg" 
                                                            key={index} 
                                                            style={{ background: item.color, width: 100 - (8 * index) + '%' }}
                                                         >
                                                            <span style={{ color: '#000' }}>{item.name}</span>
                                                         </div>
                                                      ))}
                                                   </div>
                                                </div>
                                             )}

                                             {/* Cột 2: Notes Accord */}
                                             {(productDetails.data.base_accord.length > 0 || 
                                             productDetails.data.middle_accord.length > 0 || 
                                             productDetails.data.top_accord.length > 0) && (
                                                <div className="column column-right" style={{ flex: 1 }}>
                                                   {productDetails.data.top_accord.length > 0 && (
                                                      <div className="notes-section">
                                                         <h4 className="notes-title">{strings.top_notes}</h4>
                                                         <div className="notes-images">
                                                            {productDetails.data.top_accord.map((note, index) => (
                                                               <div className="note-item" key={`top-${index}`} style={{ textAlign: 'center' }}>
                                                                  <img 
                                                                     src={note.image} 
                                                                     alt={note.name}
                                                                  />
                                                                  <p>{note.name}</p>
                                                               </div>
                                                            ))}
                                                         </div>
                                                      </div>
                                                   )}
                                                   {productDetails.data.middle_accord.length > 0 && (
                                                      <div className="notes-section">
                                                         <h4 className="notes-title">{strings.middle_notes}</h4>
                                                         <div className="notes-images">
                                                            {productDetails.data.middle_accord.map((note, index) => (
                                                               <div className="note-item" key={`middle-${index}`} style={{ textAlign: 'center' }}>
                                                                  <img 
                                                                     src={note.image} 
                                                                     alt={note.name}
                                                                  />
                                                                  <p>{note.name}</p>
                                                               </div>
                                                            ))}
                                                         </div>
                                                      </div>
                                                   )}
                                                   {productDetails.data.base_accord.length > 0 && (
                                                      <div className="notes-section">
                                                         <h4 className="notes-title">{strings.base_notes}</h4>
                                                         <div className="notes-images">
                                                            {productDetails.data.base_accord.map((note, index) => (
                                                               <div className="note-item" key={`base-${index}`} style={{ textAlign: 'center' }}>
                                                                  <img 
                                                                     src={note.image} 
                                                                     alt={note.name}
                                                                  />
                                                                  <p>{note.name}</p>
                                                               </div>
                                                            ))}
                                                         </div>
                                                      </div>
                                                   )}
                                                </div>
                                             )}
                                          </div>
                                       )}
                                 </div>
                                 <div className='prd-dtl-bottom-cont'>
                                    <div className="checkout-delivery-wrap">
                                       <div className="checkout-col">
                                          <div className="checkout-box">
                                             <span className="c-icon">
                                                <img src={ImgCheckout1} alt="Authenticate" />
                                             </span>
                                             <span>100% {strings.authenticate}</span>
                                          </div>
                                       </div>
                                       <div className="checkout-col">
                                          <div className="checkout-box">
                                             <span className="c-icon">
                                                <img src={ImgCheckout3} alt="Free Shipping" />
                                             </span>
                                             <span>{strings.free_shipping}</span>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="prd-inner-bg">
                                       <p>{strings.place_order_befoure}</p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>
                  <section className="accordion-sec space-cls">
                     <div className="container">
                        <div className="accordion-description">
                           <Row>
                              <Col md="6">
                                 <div className='prd-info'>
                                    <div className='prd-info-cont'>
                                       <h5>{strings.product_information}</h5>
                                       <ul>
                                          <li>
                                             <span>{strings.SKU} :</span>
                                             <span className="right-cont">{productDetails.data.product.sku}</span>
                                          </li>
                                          {productDetails.data.product.barcode != 0 ?
                                             <li>
                                                <span>{strings.barcode} :</span>
                                                <span className="right-cont">{productDetails.data.product.barcode}</span>
                                             </li> : null}
                                          <li>
                                             <span>{strings.brand} :</span>
                                             <span className="right-cont">{productDetails.data.product.brands != null ? productDetails.data.product.brands.name : ''}</span>
                                          </li>
                                          <li>
                                             <span>{strings.perfume_for} : </span>
                                             <span className="right-cont">{productDetails.data.product.categories.name}</span>
                                          </li>
                                          <li>
                                             <span>{strings.perfume_type} :</span>
                                             <span className="right-cont">{productDetails.data.product.perfume_type ? productDetails.data.product.perfume_type.name : ''}</span>
                                          </li>

                                       </ul>
                                    </div>
                                 </div>
                              </Col>
                              <Col md="6">
                                 <div className='prd-info'>
                                    <div className='prd-info-cont'>
                                       <h5>{strings.description}</h5>
                                       <p>{productDetails.data.product.description}</p>
                                    </div>
                                 </div>
                              </Col>
                           </Row>

                        </div>
                     </div>
                  </section>
                  {productDetails.data ? productDetails.data.notes_image.length > 0 ?
                     <section className="space-cls prd-perfume-sec">
                        <div className="container">
                           <div className="perfume_inner_title">
                              <h3>{strings.perfume_notes}</h3>
                              <div className="show-votes">
                                 <div className="address-toggle">
                                    {/*<span>Show Notes</span>*/}
                                    {/* <Toggle
                           label="Toggle me"
                           toggled={true}
                           onClick={logState}
                        /> */}

                                 </div>
                              </div>
                           </div>
                           <div className="row">
                              {
                                 productDetails.data.notes_image.map((item, index) =>
                                    <div className="col-md-2">
                                       <div className="prd-perfume-box">
                                          <div className="prd-perfume-img">
                                             <img src={item.image} alt={item.name} />
                                          </div>
                                          <h4>{item.name}</h4>
                                       </div>
                                    </div>
                                 )}
                           </div>
                        </div>
                     </section> : null : null}
                  {productDetails.data ? productDetails.data.product.rating > 0 ?
                     <section className="space-cls rating-main-sec">
                        <div className="container">
                           <div id="perfumeBlockFirst" className="perfume_inner_title">
                              <h4>{strings.ratings}</h4>
                           </div>
                           <div className="rating-sec">
                              <div className="rating-left">
                                 <div>
                                    <span className="rating_title">{productDetails.data ? productDetails.data.product ? productDetails.data.product.rating : 0 : 0}</span>
                                    <span className="rating-ic">
                                       <img src={ImgmStar1} alt='Star' />
                                    </span>
                                 </div>
                                 <h6>{productDetails.data.ratings.total_rating_count} {strings.verifiedBuyer}</h6>
                              </div>
                              {productDetails.data ? productDetails.data.ratings ? productDetails.data.ratings.total_rating_count > 0 ?
                                 <div className='rating-right'>
                                    <div className="App">
                                       <div className='rating-percent'>
                                          <span className="rating-no">5 <img alt='Star' src={ImgmStar1} /></span>
                                          <div className='rating-progress'>
                                             <ProgressBar bgcolor={"6a1b9a"} completed={productDetails.data ? productDetails.data.ratings ? ((productDetails.data.ratings.five * 100) / productDetails.data.ratings.total_rating_count) : 0 : 0} />
                                          </div>
                                          <div className='rating-number'>{productDetails.data.ratings.five}</div>
                                       </div>
                                       <div className='rating-percent'>
                                          <span className="rating-no">4 <img alt='Star' src={ImgmStar1} /></span>
                                          <div className='rating-progress'>
                                             <ProgressBar completed={productDetails.data ? productDetails.data.ratings ? ((productDetails.data.ratings.four * 100) / productDetails.data.ratings.total_rating_count) : 0 : 0} />
                                          </div>
                                          <div className='rating-number'>{productDetails.data.ratings.four}</div>
                                       </div>
                                       <div className='rating-percent'>
                                          <span className="rating-no">3 <img alt='Star' src={ImgmStar1} /></span>
                                          <div className='rating-progress'>
                                             <ProgressBar completed={productDetails.data ? productDetails.data.ratings ? ((productDetails.data.ratings.three * 100) / productDetails.data.ratings.total_rating_count) : 0 : 0} />
                                          </div>
                                          <div className='rating-number'>{productDetails.data.ratings.three}</div>
                                       </div>
                                       <div className='rating-percent'>
                                          <span className="rating-no">2 <img alt='Star' src={ImgmStar1} /></span>
                                          <div className='rating-progress'>
                                             <ProgressBar completed={productDetails.data ? productDetails.data.ratings ? ((productDetails.data.ratings.two * 100) / productDetails.data.ratings.total_rating_count) : 0 : 0} />
                                          </div>
                                          <div className='rating-number'>{productDetails.data.ratings.two}</div>
                                       </div>
                                       <div className='rating-percent'>
                                          <span className="rating-no">1 <img alt='Star' src={ImgmStar1} /></span>
                                          <div className='rating-progress'>
                                             <ProgressBar completed={productDetails.data ? productDetails.data.ratings ? ((productDetails.data.ratings.one * 100) / productDetails.data.ratings.total_rating_count) : 0 : 0} />
                                          </div>
                                          <div className='rating-number'>{productDetails.data.ratings.one}</div>
                                       </div>
                                    </div>
                                 </div>
                                 : null : 0 : 0}
                              {/* <div className="rating-right">
                              <div className="rating-percents">
                                 <div className="rating-percent">
                                    <span className="rating-no">5 <img src={ImgmStar} /></span>
                                    <div className="rating-progress"></div>
                                 </div>
                                 <div className="rating-percent">
                                    <span className="rating-no">4 <img src={ImgmStar} /></span>
                                    <div className="rating-progress"></div>

                                 </div>
                                 <div className="rating-percent">
                                    <span className="rating-no">3 <img src={ImgmStar} /></span>
                                    <div className="rating-progress"></div>

                                 </div>
                                 <div className="rating-percent">
                                    <span className="rating-no">2 <img src={ImgmStar} /></span>
                                    <div className="rating-progress"></div>
                                 </div>
                                 <div className="rating-percent">
                                    <span className="rating-no">1 <img src={ImgmStar} /></span>
                                    <div className="rating-progress"></div>
                                 </div>
                              </div>
                           </div> */}
                           </div>
                        </div>
                     </section>
                     : null : null}
                  {productRating.data ? productRating.data.length > 0 ?
                     <section className="space-cls customer-review_sec">
                        <div className="container">
                           <div className="perfume_inner_title">
                              <h4>{strings.customerPhotos} ({productRating ? productRating.data ? productRating.data.length > 0 ? productRating.data.filter(item => !item.image.includes('images/noimage.png')).length : 0 : 0 : 0})</h4>
                           </div>
                           <div className="reivew-inner-list review-image-list">
                              {productRating.data.map((item, index) =>
                                 <div className="review-image">
                                    {!item.image.includes('images/noimage.png') ? <img alt={item.name} src={item.image} className="img-fluid rating_image_detail" alt="..."></img> : ''}
                                 </div>
                              )
                              }
                           </div>
                        </div>
                     </section>
                     : null : null}
                  {productRating.data ? productRating.data.length > 0 ?
                     <section className="space-cls customer-review_sec">
                        <div className="container">
                           <div className="perfume_inner_title">
                              <h4>{strings.customers_reviews} ({productRating ? productRating.data ? productRating.data.length > 0 ? productRating.data.length : 0 : 0 : 0})</h4>
                           </div>
                           <div className="reivew-inner-list">
                              {productRating.data ?
                                 productRating.data.map((item, index) =>
                                    <div className="review-inner">
                                       <div className="reviiew-dtl">
                                          <div className="review-left">
                                             <span className="review-dtl-bg">{item.rating} <span><img src={ImgmStar} alt='Star' /></span></span>
                                          </div>
                                          <div className="review-right">
                                             <p>{item.review}.</p>
                                             {!item.image.includes('images/noimage.png') ? <img src={item.image} className="img-fluid rating_image_detail" alt={item.name}></img> : ''}
                                             <span>{item ? item.users ? item.users.name : '' : ''} | {item ? moment(item.updated_at).format('DD-MMMM-YYYY') : ''}  </span>
                                          </div>
                                       </div>
                                       <div className="likeBlock">
                                          <div className='like' onClick={(e) => likeReview(item.id, 'like')}>
                                             <i className="far fa-thumbs-up"></i>
                                             <span>{item.likes.length}</span>
                                          </div>
                                          <div className='dislike' onClick={(e) => likeReview(item.id, 'dislike')}>
                                             <i className="far fa-thumbs-down"></i>
                                             <span>{item.dislikes.length}</span>
                                          </div>
                                       </div>
                                    </div>) : <section className="space-cls no_image review_noImg">
                                    <div className="container">
                                       <div className="cart_row">
                                          <div className='col-md-12'>
                                             <h3>
                                                <center>
                                                   <img src={noReview} alt="Out of Stock" />
                                                </center>
                                             </h3>
                                          </div>
                                       </div>
                                    </div>
                                 </section>}

                           </div>
                        </div>
                     </section>
                     : null : null}
               </>
               : <Skeleton height={50} count={20} />}

            {productDetails.data ? productDetails.data.related_brand_product ? productDetails.data.related_brand_product.length > 0 ?
               <section className="space-cls home-fragrance-prd-sec">
                  <div className="container">
                     <div className="title-main">
                        <h2 className="heading-type1">{strings.releatedProduct}</h2>
                        {/* {commanData.data && commanData.data.most_sold != '' ? <h2 className="heading-type1">{strings.mostPickedProduct}</h2> : ''} */}
                     </div>
                     {productDetails.data ? <Product showAddtoCart={showAddtoCart} data={productDetails.data.related_brand_product} options={newAriveOption} /> : <Skeleton height={50} count={20} />}

                  </div>
               </section>
               : null : null : null}

            <CommanList />
         </main>
         <Footer />
         {modalIsOpen ? <>
				<Modal id="selectionModal" show={true} animation={true} size="lg" backdrop="static" keyboard={false} centered>
					<Modal.Header className="modal-header-custom">
						{/* <h5 className="modal-title">{strings.pickItemSize}</h5> */}
						<button
						type="button"
						className="btn-close"
						aria-label="Close"
						onClick={setModalIsOpenToFalse}
						>
						<img src={Imgcross} alt="Close" />
						</button>
					</Modal.Header>
					<Modal.Body className="modal-body-custom">
						<ValidationForm ref={formRef}>
						<div className="product-details">
							<img src={commanList.product.image} alt={commanList.product.name} className="product-image" />
							<div className="product-info">
								<h6 className="product-name">{commanList.product.name}</h6>
								{/* Size Options */}
								<Form.Group className="form-group prd-size-group" controlId="form-group">
									<div className="prd_size_label">
									{commanList.attributes[0]?.attribute_values &&
										commanList.attributes[0].attribute_values.map((option, i) => (
										<label className="custom_radio_b" key={option.id}>
											<input
											type="radio"
											name="attribute_value_id"
											value={option.id}
											defaultChecked={i === 0}
                                 onChange={(e) => handleAttributeChange(commanList.product.id, option.id)}
											/>
											<span className="checkmark">{option.value}</span>
										</label>
										))}
									</div>
								</Form.Group>
							</div>
							<div className="product-price">
								<Currency data={commanList.product.discount_price != 0 ? commanList.product.discount_price : commanList.product.original_price} />
							</div>
						</div>

						{/* Submit Button */}
						<div className="login-btn">
							<Button
							onClick={(e) => addtoCart(commanList.product_id, 'form')}
							type="button"
							className="btn btn-primary w-100"
							>
							{strings.pickThisSize}
							</Button>
						</div>
						</ValidationForm>
					</Modal.Body>
				</Modal></> : ""}
         {modalNotifyMeIsOpen ? <>
            <Modal show={true} animation={true} size="lg" backdrop="static" keyboard={false} centered >
               <Modal.Header>
                  <h5 className="modal-title" id="exampleModalLabe112l">{strings.notifyMe}</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                     <img src={Imgcross} alt='Close' onClick={setModalNotifyMeIsOpenToFalse()} />
                  </button>
               </Modal.Header>
               <Modal.Body className='modal-body'>
                  <div className="continue-btn review_modal notify_me_modal">
                     <ValidationForm ref={formRef2} >
                        <div className="popup-cont">
                           <Form.Group className="form-group" controlId="form-group">
                              <Form.Label>{strings.name}</Form.Label>
                              <TextInput name="name" id="name" placeholder={strings.plz_enter_name} required onChange={(e) => setNameReview(e.target.value)} />
                           </Form.Group>
                           <Form.Group className="form-group" controlId="form-group">
                              <Form.Label>{strings.phoneNumber}</Form.Label>
                              <TextInput name="phone" id="phone" placeholder={strings.plz_enter_phone} required onChange={(e) => setPhoneReview(e.target.value)} />
                           </Form.Group>
                           <div className="login-btn">
                              <Button onClick={(e) => notifyMe()} type="button" className="btn btn_primary">{strings.notifyMe}</Button>
                           </div>
                        </div>
                     </ValidationForm>
                  </div>
               </Modal.Body>
            </Modal></> : ""}
      </>
   )
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);